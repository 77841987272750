import React from 'react';
import { Button, Form, Input, Modal, Select, message, Switch } from 'antd';
import firebase from '../constants/firebase';
import i18n from 'i18n-js';
const { Option } = Select;
const { TextArea } = Input;

class ProjectModal extends React.Component {
  state = {
    projects: [],
    archive: [],
    loading: true,
    clients: [],
    modalVisible: true,
    name: undefined,
    info: undefined,
    selectedDropdown: undefined,
    containsReport: true,
    containsMaterial: true,
    date: new Date(),
    workers: []
  }

  componentDidMount = async () => {
    this.setState({tenant: ''})
    this.loadClients()
    this.loadWorkers()
  }

  loadWorkers = () => {
    const db = firebase.firestore()
    const tenant = firebase.auth().currentUser.displayName;

    return db.collection(`tenants/${tenant}/users`)
      .where('isActive', '==', true)
      .orderBy('lastname', 'desc')
      .onSnapshot((querySnapshot) => {
        if (!querySnapshot.exists) {
          this.setState({loading: false})
        }
        let workers = []
        querySnapshot.forEach((doc) => {
          let worker = {
            id: doc.id,
            firstname: doc.data().firstname,
            lastname: doc.data().lastname,
          }
          workers.push(worker)
          this.setState({
            workers: workers,
            loading: false
          })
        })
      })
  }

  loadClients = () => {
    const db = firebase.firestore()
    const tenant = firebase.auth().currentUser.displayName;

    return db.collection(`tenants/${tenant}/clients`)
      .orderBy('lastname', 'desc')
      .onSnapshot((querySnapshot) => {
        if (!querySnapshot.exists) {
          this.setState({loading: false})
        }
        let clients = []
        querySnapshot.forEach((doc) => {
          let client = {
            id: doc.id,
            firstname: doc.data().firstname,
            lastname: doc.data().lastname,
            city: doc.data().city,
            active: (doc.data().active !== undefined) ? doc.data().active : true
          }
          clients.push(client)
          this.setState({
            clients: clients,
            loading: false
          })
        })
      })
  }

  updateProject = (values) => {
    this.setState({saving: true})
    const db = firebase.firestore()
    const { date, containsMaterial, containsReport } = this.state
    const tenant = firebase.auth().currentUser.displayName;
    const ref = db.collection(`tenants/${tenant}/projects`).doc(this.props.item.id)

    let element = {
      user: db.doc(`tenants/${tenant}/users/${firebase.auth().currentUser.uid}`),
      createdAt: new Date(date),
      containsMaterial: containsMaterial,
      containsReport: containsReport
    }

    if (values.client) {
      element.client = db.doc(`tenants/${tenant}/clients/${values.client}`)
    }
    if (values.name) {
      element.name = values.name
    }
    if (values.info) {
      element.info = values.info
    }
    if (values.planedTime) {
      element.planedTime = values.planedTime
    }
    if (values.projectWorker) {
      element.projectWorker = values.projectWorker
    }
    element.active = !values.active

    return ref.update(element)
    .then(() => {
      message.info(i18n.t('companyDetailsUpdated'));
      this.setState({saving: false})
      this.props.toogleModal(false)
    })
    .catch((error) => {
      message.error(i18n.t('oops'));
      console.error("Error updating document: ", error)
    })
  }

  addProject = (values) => {
    this.setState({saving: true})
    const db = firebase.firestore()
    const { date, containsMaterial, containsReport } = this.state
    const tenant = firebase.auth().currentUser.displayName;
    const element = {
      user: db.doc(`tenants/${tenant}/users/${firebase.auth().currentUser.uid}`),
      name: values.name,
      info: values.info || '',
      createdAt: new Date(date),
      client: db.doc(`tenants/${tenant}/clients/${values.client}`),
      active: !values.active,
      containsMaterial: containsMaterial,
      containsReport: containsReport,
      planedTime: values.planedTime || '',
      projectWorker: values.projectWorker || []
    }
    return db.collection(`tenants/${tenant}/projects`).add(element)
    .then(() => {
      this.setState({saving: false})
      this.props.toogleModal(false)
    })
    .catch((error) => {
      message.error(i18n.t('oops'));
      console.error("Error updating document: ", error)
    })
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // console.log('Received values of form: ', values);
        if (this.props.edit) {
          this.updateProject(values)
        } else {
          this.addProject(values)
        }
      }
    });
  }

  render = () => {
    const { clients, saving, workers } = this.state
    const { item, edit } = this.props
    const { getFieldDecorator } = this.props.form;

    const activeClients = clients.filter(item => item.active === true)

    return (
      <Modal
        title={edit ? i18n.t('editProjectScreenHL') : i18n.t('addProjectScreenHL')}
        visible={this.props.modalVisible}
        onOk={this.handleOk}
        onCancel={() => this.props.toogleModal(false)}
        footer={null}
      >
        <Form onSubmit={this.handleSubmit} className="project-form">
          <Form.Item label={i18n.t('selectClientPlaceholder')}>
            {getFieldDecorator('client', {
              initialValue: (item) ? item.clientId : '',
              rules: [{ required: true }],
            })(
              <Select 
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => 
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                placeholder={i18n.t('selectClientPlaceholder')}
              >
                {activeClients.map((item) => (
                  <Option key={item.id} value={item.id}>{`${item.firstname} ${item.lastname}`}</Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item label={i18n.t('projectName')}>
            {getFieldDecorator('name', {
              initialValue: (item) ? item.name : '',
              rules: [{ required: true }],
            })(
              <Input
                placeholder={i18n.t('projectName')}
              />,
            )}
          </Form.Item>
          <Form.Item label={i18n.t('planedTime')}>
            {getFieldDecorator('planedTime', {
              initialValue: (item) ? item.planedTime : '',
            })(
              <Input
              type="number"
                placeholder={i18n.t('planedTime')}
              />,
            )}
          </Form.Item>

          <Form.Item label={i18n.t('projectWorker')}>
            {getFieldDecorator('projectWorker', {
              initialValue: (item) ? item.projectWorker : undefined,
            })(
              <Select mode="multiple" placeholder={i18n.t('all')}>
                {workers.map((item) => (
                  <Option key={item.id} value={item.id}>{`${item.firstname} ${item.lastname}`}</Option>
                ))}
              </Select>
            )}
          </Form.Item>

          <Form.Item label={i18n.t('projectDesc')}>
            {getFieldDecorator('info', {
              initialValue: (item) ? item.info : '',
            })(
              <TextArea 
                rows={4} 
                placeholder={i18n.t('projectDesc')}
              />,
            )}
          </Form.Item>

          <Form.Item label={i18n.t('containsMaterial')}>
            {getFieldDecorator('containsMaterial', {
              valuePropName: 'checked',
              initialValue: (item) ? item.containsMaterial : true,
            })(
              <Switch onChange={(checked) => this.setState({containsMaterial: checked})} />,
            )}
          </Form.Item>

          <Form.Item label={i18n.t('containsReport')}>
            {getFieldDecorator('containsReport', {
              valuePropName: 'checked',
              initialValue: (item) ? item.containsReport : true,
            })(
              <Switch onChange={(checked) => this.setState({containsReport: checked})} />,
            )}
          </Form.Item>

          <Form.Item label={i18n.t('archiveProject')}>
            {getFieldDecorator('active', {
              valuePropName: 'checked',
              initialValue: (item) ? !item.active : false,
            })(
              <Switch />,
            )}
          </Form.Item>

          <Form.Item>
            <Button type="primary" loading={saving} htmlType="submit" className="project-form-button">
              {i18n.t('save')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}

export default Form.create({ name: 'project_modal' })(ProjectModal);