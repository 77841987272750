import React from 'react';
import { PageHeader, Spin, Avatar, Form, Button, Input, Upload, Icon, message } from 'antd';
import firebase from '../constants/firebase';
import i18n from 'i18n-js';
import helper from '../utils/helper'
import mime from 'mime-types';
import UserAvatar from '../components/UserAvatar';

class Profile extends React.Component {
  state = {
    userData: {},
    uploading: false,
    firstname: '',
    lastname: '',
    phone: '',
    allowNotifications: false,
    avatar: ''
  }

  componentDidMount = async () => {
    this.loadUserData()
  }

  loadUserData = () => {
    const db = firebase.firestore()
    const tenant = firebase.auth().currentUser.displayName;
    const currentUser = firebase.auth().currentUser.uid
    let userData = {}
    db.collection(`tenants/${tenant}/users`)
      .doc(currentUser)
      .onSnapshot((doc) => {
        userData = {
          firstname: doc.data().firstname || "",
          lastname: doc.data().lastname || "",
          avatar: doc.data().avatar || "",
          allowNotifications: doc.data().allowNotifications || false,
          email: doc.data().email || "",
          isAdmin: doc.data().isAdmin || false,
          phone: doc.data().phone
        }
        this.setState({
          userData: userData,
          firstname: userData.firstname,
          lastname: userData.lastname,
          phone: userData.phone,
          avatar: userData.avatar,
          allowNotifications: userData.allowNotifications
        })
      })
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // console.log('Received values of form: ', values);
        this.updateData(values)
      }
    });
  };

  updateData = (values) => {
    const { userData, avatar } = this.state
    const tenant = firebase.auth().currentUser.displayName;
    const db = firebase.firestore()
    let newUserData = {
      firstname: values && values.firstname ? values.firstname : userData.firstname,
      lastname: values && values.lastname ? values.lastname : userData.lastname,
      phone: values && values.phone ? values.phone : userData.phone,
      avatar: avatar ? avatar : userData.avatar,
    }
    const currentUser = firebase.auth().currentUser.uid
    return db.collection(`tenants/${tenant}/users`).doc(currentUser).set(newUserData, {merge: true})
      .then(() => {
        message.info(i18n.t('companyDetailsUpdated'));
      })
      .catch((error) => {
        message.error(i18n.t('oops'));
        console.error("Error updating document: ", error)
      })
  }

  uploadImage = async(uri) => {
    const currentUser = firebase.auth().currentUser.uid
    const tenant = firebase.auth().currentUser.displayName;
    const fileType = uri.originFileObj.name.substr(uri.originFileObj.name.lastIndexOf('.') + 1);
    const mimeType = mime.lookup(fileType)
    this.setState({uploading: true})
    const metadata = {
      contentType: mimeType,
    }
    const name = uri.originFileObj.name.replace(/\s/g,'-')
    const ref = firebase.storage().ref().child(`tenants/${tenant}/users/${currentUser}/${name}.png`)
    return ref.put(uri.originFileObj, metadata).then((snapshot) => {
      snapshot.ref.getDownloadURL().then((downloadURL) => {
        this.setState({uploading: false})
        return this.setState({ avatar: downloadURL }, () => {
          this.updateData()
        })
      })
    })
  }

  renderAvatar = () => {
    const { userData, uploading } = this.state;
    
    const upload = (file) => {
      this.uploadImage(file)
    }

    const props = {
      name: 'file',
      action: 'upload',
      headers: {
        authorization: 'authorization-text',
      },
      onChange(info) {
        if (info.file.status !== 'uploading') {
          // console.log(info.file, info.fileList);
          upload(info.file)
        }
        if (info.file.status === 'done') {
          // console.log(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === 'error') {
          // console.log(`${info.file.name} file upload failed.`);
        }
      },
    };
    
    return (
      <div>
        {uploading &&
          <Spin />
        }

        {!helper.isEmpty(userData.avatar) &&
          <Avatar src={userData.avatar} size={100} />
        }
        {helper.isEmpty(userData.avatar) &&
          <UserAvatar
            size="80"
            name={`${this.state.firstname} ${this.state.lastname}`}
            src={userData.avatar}
          />
        }
        <br/><br/>
        <Upload key="1" {...props}>
          <Button type="primary">
            <Icon type="upload" /> {i18n.t('chooseImage')}
          </Button>
        </Upload>
        <br/><br/>
      </div>
    )
  }

  renderForm = () => {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form onSubmit={this.handleSubmit} className="profile-form">
        <Form.Item label={i18n.t('email')}>
          {getFieldDecorator('email', {
            rules: [{ required: true, message: 'Please input your email!' }],
            initialValue: this.state.userData.email
          })(
            <Input
              placeholder={i18n.t('email')}
              disabled
            />,
          )}
        </Form.Item>
        <Form.Item label={i18n.t('firstname')}>
          {getFieldDecorator('firstname', {
            rules: [{ required: true, message: 'Please input your firstname!' }],
            initialValue: this.state.firstname
          })(
            <Input
              type="text"
              placeholder={i18n.t('firstname')}
            />,
          )}
        </Form.Item>
        <Form.Item label={i18n.t('lastname')}>
          {getFieldDecorator('lastname', {
            rules: [{ required: true, message: 'Please input your lastname!' }],
            initialValue: this.state.lastname
          })(
            <Input
              type="text"
              placeholder={i18n.t('lastname')}
            />,
          )}
        </Form.Item>
        <Form.Item label={i18n.t('phone')}>
          {getFieldDecorator('phone', {
            rules: [{ required: true, message: 'Please input your phone number!' }],
            initialValue: this.state.phone
          })(
            <Input
              type="text"
              placeholder={i18n.t('phone')}
            />,
          )}
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" className="profile-form-button">
            {i18n.t('save')}
          </Button>
        </Form.Item>
      </Form>
    )
  }

  render() {
    const { userData } = this.state;

    if (helper.isEmpty(userData)) {
      return (<Spin/>)
    }

    return ([
      <PageHeader 
        key="11" 
        title={i18n.t('profile')} 
      />,
      this.renderAvatar(),
      this.renderForm()
    ]);
  }
}

export default Form.create({ name: 'profile' })(Profile);