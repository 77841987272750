import React from 'react';
import {
  Route,
  Redirect,
  Switch
} from "react-router-dom";
import Home from '../views/Home';
import Login from '../views/Login';
import ForgotPassword from '../views/ForgotPassword';
import Register from '../views/Register';
import Profile from '../views/Profile';
import ProjectsDetail from '../views/ProjectsDetail';
import WorkingHours from '../views/WorkingHours';
import WorkingTypes from '../views/WorkingTypes';
import GroupChat from '../views/GroupChat';
import Company from '../views/Company';
import Clients from '../views/Clients';
import Employees from '../views/Employees';
import Analysis from '../views/Analysis';
import Report from '../views/Report';
import Abo from '../views/Abo';
import WebView from '../views/WebView';
import Archive from '../views/Archive';
import moment from 'moment';

class Navigator extends React.Component {

  getUsedTrialDays = () => {
    const { companyData } = this.props
    const end = moment(new Date())
    let trialDuration = moment.duration(end.diff(companyData.createdAt)).asDays()
    if (trialDuration < 0) {
      trialDuration = 0
    }
    return Number(trialDuration).toFixed(0)
  }

  render = () => {
    const { authenticated, currentUser, companyData } = this.props
    const trialDays = authenticated ? this.getUsedTrialDays() : 0

    return (
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/register" component={Register} />
        <PrivateRoute path="/profile" component={Profile} authenticated={authenticated} currentUser={currentUser} companyData={companyData} usedTrialDays={trialDays} />
        <PrivateRoute path="/project/:id" component={ProjectsDetail} authenticated={authenticated} currentUser={currentUser} companyData={companyData} usedTrialDays={trialDays} />
        <PrivateRoute path="/working-hours" component={WorkingHours} authenticated={authenticated} currentUser={currentUser} companyData={companyData} usedTrialDays={trialDays} />
        <PrivateRoute path="/chat" component={GroupChat} authenticated={authenticated} currentUser={currentUser} companyData={companyData} usedTrialDays={trialDays} />
        <PrivateRoute path="/help" component={WebView} authenticated={authenticated} currentUser={currentUser} companyData={companyData} usedTrialDays={trialDays} />
        <PrivateRoute path="/imprint" component={WebView} authenticated={authenticated} currentUser={currentUser} companyData={companyData} usedTrialDays={trialDays} />
        <PrivateRoute path="/data-protection" component={WebView} authenticated={authenticated} currentUser={currentUser} companyData={companyData} usedTrialDays={trialDays} />
        <PrivateRoute path="/terms-of-use" component={WebView} authenticated={authenticated} currentUser={currentUser} companyData={companyData} usedTrialDays={trialDays} />
        <AdminRoute path="/admin/company" component={Company} authenticated={authenticated} currentUser={currentUser} companyData={companyData} usedTrialDays={trialDays} />
        <AdminRoute path="/admin/clients" component={Clients} authenticated={authenticated} currentUser={currentUser} companyData={companyData} usedTrialDays={trialDays} />
        <AdminRoute path="/admin/employees" component={Employees} authenticated={authenticated} currentUser={currentUser} companyData={companyData} usedTrialDays={trialDays} />
        <AdminRoute path="/admin/archive" component={Archive} authenticated={authenticated} currentUser={currentUser} companyData={companyData} usedTrialDays={trialDays} />
        <AdminRoute path="/admin/time-report" component={Analysis} authenticated={authenticated} currentUser={currentUser} companyData={companyData} usedTrialDays={trialDays} />
        <AdminRoute path="/admin/project-report" component={Report} authenticated={authenticated} currentUser={currentUser} companyData={companyData} usedTrialDays={trialDays} />
        <AdminRoute path="/admin/working-types" component={WorkingTypes} authenticated={authenticated} currentUser={currentUser} companyData={companyData} usedTrialDays={trialDays} />
        <AdminRoute path="/admin/abo" component={Abo} authenticated={authenticated} currentUser={currentUser} companyData={companyData} usedTrialDays={trialDays} abo={true} />
        <PrivateRoute path="/" component={Home} authenticated={authenticated} currentUser={currentUser} companyData={companyData} usedTrialDays={trialDays} home={true} />
      </Switch>
    );
  }
}

function PrivateRoute({ component: Component, authenticated, currentUser, companyData, usedTrialDays, home, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        authenticated === true ? (
          (!companyData.stripe && (companyData.userAmount > 1) && usedTrialDays > 30) ? (
            (currentUser.isAdmin) ? (
              <Redirect
                to={{
                  pathname: "/admin/abo",
                  state: { from: props.location }
                }}
              />
            ) : (
              (!home) ? (
                <Redirect
                  to={{
                    pathname: "/",
                    state: { from: props.location }
                  }}
                />
              ) : (
                <Component {...props} />
              )
            )
          ) : (
            <Component {...props} />
          )
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}

function AdminRoute({ component: Component, authenticated, currentUser, companyData, usedTrialDays, abo, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        authenticated === true && currentUser.isAdmin ? (
          (!abo && !companyData.stripe && (companyData.userAmount > 1) && usedTrialDays > 30) ? (
            <Redirect
              to={{
                pathname: "/admin/abo",
                state: { from: props.location }
              }}
            />
          ) : (
            <Component {...props} />
          )
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}

export default Navigator