import React from 'react';
import { PageHeader, Spin, Collapse, Button, Checkbox, message, Tabs, Table, Select } from 'antd';
import firebase from '../constants/firebase';
import i18n from 'i18n-js';
import moment from 'moment';
import { groupBy } from 'lodash'
import WorkingHoursModal from '../modal/WorkingHoursModal';
const { TabPane } = Tabs;
const { Panel } = Collapse;
const { Option } = Select;

export default class Report extends React.Component {
  state = {
    loading: true,
    workingHours: [],
    material: [],
    signing: false,
    uploading: false,
    signatureImage: null,
    projects: [],
    saving: false,
    reports: [],
    items: [],
    activeTab: 0,
    selectedTab: 0,
    selectedDropdown: 0,
    selectedItems: [],
    start: moment().startOf('month').format('YYYY-MM-DD'),
    end: moment().endOf('month').format('YYYY-MM-DD'),
    workingTypes: [
      {
        id: 1,
        name: i18n.t('timeTypeWork'),
        editable: false
      },
      {
        id: 2,
        name: i18n.t('timeTypeLoad'),
        editable: false
      },
      {
        id: 3,
        name: i18n.t('timeTypePause'),
        editable: false
      },
      {
        id: 4,
        name: i18n.t('timeTypeDrive'),
        editable: false
      },
    ]
  };

  componentDidMount = async () => {
    this.loadCompanyDetails()
    this.loadAllProjects()
    this.loadReports()
    this.loadWorkingTypes()
  }

  loadWorkingTypes = () => {
    const tenant = firebase.auth().currentUser.displayName;
    const db = firebase.firestore()

    db.collection(`tenants/${tenant}/working-types`)
      .orderBy('name')
      .onSnapshot((querySnapshot) => {
        if (!querySnapshot.exists) {
          this.setState({
            loading: false
          })
        }
        let workingTypes = [
          {
            id: 1,
            name: i18n.t('timeTypeWork'),
            editable: false
          },
          {
            id: 2,
            name: i18n.t('timeTypeLoad'),
            editable: false
          },
          {
            id: 3,
            name: i18n.t('timeTypePause'),
            editable: false
          },
          {
            id: 4,
            name: i18n.t('timeTypeDrive'),
            editable: false
          },
        ]
        querySnapshot.forEach((doc) => {
          let workingType = {
            id: doc.id,
            name: doc.data().name,
            billable: doc.data().billable,
          }
          workingTypes.push(workingType)
          this.setState({
            workingTypes: workingTypes,
            loading: false
          })
        })
      })
  }

  loadReports = () => {
    const tenant = firebase.auth().currentUser.displayName;
    const db = firebase.firestore()
    db.collection(`tenants/${tenant}/reports`)
      .orderBy('createdAt', 'desc')
      .onSnapshot((querySnapshot) => {
        let reports = []
        querySnapshot.forEach((doc) => {
          let item = {
            id: doc.id,
            signature: doc.data().signature,
            project: doc.data().projectId,
            fileName: doc.data().fileName,
            downloadURL: doc.data().downloadURL,
            mimeType: doc.data().mimeType,
            createdAt: moment(doc.data().createdAt.toDate()).format('DD.MM.YYYY')
          }
          db.collection(`tenants/${tenant}/projects`).doc(item.project).get().then((doc) => {
            item.project = doc.data().name
            reports.push(item)
            this.setState({
              reports: reports,
              loading: false
            })
          })
        })
      })
  }

  loadCompanyDetails = () => {
    const tenant = firebase.auth().currentUser.displayName;
    const db = firebase.firestore()

    db.collection(`tenants`)
      .doc(tenant)
      .onSnapshot((doc) => {
        let companyData = {
          id: doc.id,
          displayName: doc.data().displayName,
          logo: doc.data().logo || '',
          phone: doc.data().phone,
          street: doc.data().street,
          zip: doc.data().zip,
          city: doc.data().city
        }
        this.setState({
          companyData: companyData,
          loading: false
        })
      })
  }

  loadAllProjects = () => {
    const db = firebase.firestore()
    const tenant = firebase.auth().currentUser.displayName;

    db.collection(`tenants/${tenant}/projects`)
      .onSnapshot((docs) => {
        const projects = []
        docs.forEach((doc) => {
          let projectData = {
            id: doc.id,
            name: doc.data().name,
            client: doc.data().client
          }
          projectData.client.get().then((snap) => {
            projectData.client = snap.data()
            projectData.clientId = snap.id
            projects.push(projectData)
            this.setState({
              projects: projects,
              loading: false
            })
          })
        })
      })
  }

  loadProjectDetail = () => {
    const db = firebase.firestore()
    const tenant = firebase.auth().currentUser.displayName;
    const { project } = this.state;

    if (project) {
      db.collection(`tenants/${tenant}/projects`)
        .doc(project)
        .onSnapshot((doc) => {
          let projectData = {
            name: doc.data().name,
            client: doc.data().client
          }
          projectData.client.get().then((snap) => {
            projectData.client = snap.data()
            projectData.clientId = snap.id
            this.setState({
              projectDetails: projectData,
              loading: false
            })
          })
        })
    }
  }

  loadWorkingHours = () => {
    const db = firebase.firestore()
    const tenant = firebase.auth().currentUser.displayName;
    const { project, start, end } = this.state
    const projectRef = db.collection(`tenants/${tenant}/projects`).doc(project)
    let workingHours = []

    db.collection(`tenants/${tenant}/working-hours`)
      .where("project", "==", projectRef)
      .where('date', '>', new Date(start))
      .where('date', '<', new Date(end))
      .orderBy('date')
      .onSnapshot((docs) => {
        if (!docs.exists) {
          this.setState({workingHours: workingHours, loading: false})
        }

        workingHours = []
        docs.forEach((doc) => {
          let item = {
            id: doc.id,
            date: doc.data().date,
            project: doc.data().project,
            comment: doc.data().comment,
            timeSpent: doc.data().timeSpent || '00:00',
            user: doc.data().user,
            signature: doc.data().signature || '',
            type: doc.data().type
          }
          item.user.get().then((snap) => {
            item.user = snap.data()

            item.project.get().then((snap) => {
              item.project = snap.data()
              item.projectId = snap.id
  
              workingHours.push(item)

              this.setState({
                workingHours: workingHours,
                loading: false
              })
            })
          })
        })
      })
  }

  loadMaterial = () => {
    const db = firebase.firestore()
    const tenant = firebase.auth().currentUser.displayName;
    const { project, start, end } = this.state
    db.collection(`tenants/${tenant}/projects/${project}/material`)
      .where("done", "==", true)
      .where('updatedAt', '>', new Date(start))
      .where('updatedAt', '<', new Date(end))
      .orderBy('updatedAt')
      .onSnapshot((doc) => {
        if (!doc.exists) {
          this.setState({material: [], loading: false})
        }
        let m = []
        doc.docs.forEach((item) => {
          m.push({
            name: item.data().name,
            date: item.data().createdAt,
            id: item.id,
            quantity: item.data().quantity,
            signature: item.data().signature || '',
            done: item.data().done,
            type: 'material'
          })
          this.setState({material: m, loading: false})
        })
      })
  }

  toggleItemsForDay = (e, items, key) => {
    e.stopPropagation()
    const { selectedItems } = this.state
    const index = selectedItems.indexOf(key)
    let arr = this.state.items
    if (index > -1) {
      selectedItems.splice(index, 1)
      arr.splice(index, items.length)
    } else {
      selectedItems.push(key)
      items.forEach((el) => {
        arr.push(el)
      })
    }
    this.setState({items: arr})
  }

  getSumForDay = (data) => {
    let hours = 0
    let minutes = 0
    data.forEach((item) => {
      if (item.timeSpent) {
        hours += Number(item.timeSpent.split(':')[0])
        minutes += Number(item.timeSpent.split(':')[1])
      }
    })
    if (minutes >= 60) {
      hours = (hours + minutes/60).toFixed(0)
    }
    minutes = minutes%60
    if (minutes<10) {
      minutes = `0${minutes}`
    }
    return `${hours}:${minutes}`
  }

  getSumForMonth = () => {
    const { workingHours } = this.state
    let hours = 0
    let minutes = 0
    workingHours.forEach((item) => {
      hours += Number(item.timeSpent.split(':')[0])
      minutes += Number(item.timeSpent.split(':')[1])
    })
    
    if (minutes >= 60) {
      hours = (hours + minutes/60).toFixed(0)
    }
    minutes = minutes%60
    if (minutes<10) {
      minutes = `0${minutes}`
    }
    return `${hours}:${minutes}`
  }

  renderTotal = () => {
    return (
      <div key="33">
        <strong>{i18n.t('total')} {i18n.t('hours')}: </strong>
        <span>{this.getSumForMonth()}</span>
      </div>
    )
  }

  renderSignatureButton = (elements, key) => {
    const { active } = this.props
    const a = elements.filter(e => e.signature && e.signature.length && e.signature !== '')

    if (elements.length > a.length) {
      if (active === false) {
        return null
      }
      return (
        <a
          href="#!"
          onClick={(e) => this.toggleItemsForDay(e, elements, key)}
        >
          <Checkbox
            checked={this.state.selectedItems.indexOf(key) >= 0}
          >
          </Checkbox>
        </a>
      )
    } else {
      return (
        <span>{i18n.t('alreadySigned')}</span>
      )
    }
  }

  renderTimeList = (workingHours, material) => {
    const allItems = workingHours.concat(material)
    const grouped = groupBy(allItems, item => moment(item.date.toDate()).format('DD.MM.YYYY'))

    return (
      <div style={{paddingBottom: 15, marginBottom: 15, borderBottomStyle: 'solid' ,borderBottomWidth: 1, borderBottomColor: '#eee'}}>
        <Collapse key="22">
          {Object.keys(grouped).map((e, key) => ([

            <Panel 
              header={`${e} - ${this.getSumForDay(grouped[e])} ${i18n.t('hours')}`} 
              key={key}
              extra={this.renderSignatureButton(grouped[e], key)}
            >
              {grouped[e].filter(e => e.timeSpent).length > 0 && [
                <h3 key="1">{i18n.t('spentTime')}</h3>,
                <table key="2" className="reportTable">
                  <thead>
                    <tr>
                      <td>{i18n.t('employee')}</td>
                      <td>{i18n.t('comment')}</td>
                      <td>{i18n.t('timeType')}</td>
                      <td>{i18n.t('hours')}</td>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody>
                    {grouped[e].map((element, i) => {
                      if (element.timeSpent) {
                          return (
                            <tr key={i}>
                              <td>{element.user.firstname} {element.user.lastname}</td>
                              <td>{element.comment}</td>
                              <td>{this.getTypeText(element.type)}</td>
                              <td>{element.timeSpent}</td>
                              <td><a key="list-edit" href="#!" onClick={() => this.toogleModal(true, true, element)}>{i18n.t('edit')}</a></td>
                            </tr>
                          )
                      }
                      return null
                      })}
                  </tbody>
                </table>
              ]}

              {grouped[e].filter(e => !e.timeSpent).length > 0 && [
                <h3 key="1">{i18n.t('material')}</h3>,
                <table key="2" className="reportTable">
                  <thead>
                    <tr>
                      <td>{i18n.t('material')}</td>
                      <td>{i18n.t('quantity')}</td>
                    </tr>
                  </thead>
                  <tbody>
                    {grouped[e].map((element, i) => {
                      if (!element.timeSpent) {
                          return (
                            <tr key={i}>
                              <td>{element.name}</td>
                              <td>x {element.quantity}</td>
                            </tr>
                          )
                      }
                      return null
                      })}
                  </tbody>
                </table>
              ]}
            </Panel>
    
          ]))}
        </Collapse>
      </div>
    )
  }

  getTypeText(typeId) {
    const { workingTypes } = this.state
    const type = workingTypes.filter(item => item.id === typeId)
    if (type[0]) {
      return type[0].name
    }
    return ''
  }

  generateReport = () => {
    const { items, projectDetails, companyData, project, start, end } = this.state
    const db = firebase.firestore();
    const tenant = firebase.auth().currentUser.displayName;
    this.setState({saving: true})

    let workingHours = items.filter(item => item.type === 'workingHours')
    let material = items.filter(item => item.type === 'material')
    workingHours.map((obj)=> {return Object.assign({}, obj)});
    material.map((obj)=> {return Object.assign({}, obj)});
    
    const element = {
      user: db.doc(`tenants/${tenant}/users/${firebase.auth().currentUser.uid}`),
      createdAt: new Date(),
      workingHours,
      material,
      projectDetails: projectDetails,
      companyData,
      projectId: project,
      mimeType: 'pdf',
      fileName: `${moment(start).format('DD.MM.YY')} - ${moment(end).format('DD.MM.YY')}`
    }
    return db.collection(`tenants/${tenant}/reports`).add(element)
    .then(() => {
      this.setState({saving: false})
      message.info(i18n.t('companyDetailsUpdated'));
      this.setState({items: []})
    })
    .catch((error) => {
      message.error(i18n.t('oops'));
      console.error("Error updating document: ", error)
    })
  }

  changeMonth = (val) => {
    const { selectedDropdown } = this.state
    this.setState({
      loading: true,
      start: moment().subtract(val,'months').startOf('month').format('YYYY-MM-DD'),
      end: moment().subtract(val,'months').endOf('month').format('YYYY-MM-DD'),
      selectedTab: val
    }, () => {
      if (selectedDropdown !== 0) {
        this.loadWorkingHours()
        this.loadMaterial()
        this.loadProjectDetail()
      }
    })
  }

  onDropdownChange = (value) => {
    this.setState({
      selectedDropdown: value,
      project: value
    }, () => {
      this.loadWorkingHours()
      this.loadMaterial()
      this.loadProjectDetail()
    })
  }

  renderDocuments = () => {
    const { reports } = this.state
    const columns = [
      {
        title: i18n.t('project'),
        dataIndex: 'project',
        key: 'project',
      },
      {
        title: i18n.t('period'),
        dataIndex: 'fileName',
        key: 'fileName',
      },
      {
        title: i18n.t('createdAt'),
        dataIndex: 'createdAt',
        key: 'createdAt',
      },
      {
        title: '',
        dataIndex: 'operation',
        render: (text, record) =>
        record.downloadURL && record.downloadURL.length >= 1 ? (
          <a href="#!" onClick={() => window.open(record.downloadURL, '_blank')}>{i18n.t('open')}</a>
        ) : i18n.t('isCreating'),
      },
    ];

    return (
      <Table key="22" columns={columns} dataSource={reports} />
    )
  }

  toogleModal = (val, edit, item) => {
    const { date } = this.state
    this.setState({
      modalVisible: val,
      edit: edit,
      item: item,
      date: date
    })
  }

  render() {
    const { loading, workingHours, material, items, saving, projects, selectedTab, selectedDropdown, modalVisible, item, edit, date } = this.state

    if (loading) {
      return (<Spin />)
    }

    return ([
      <PageHeader 
        key="11" 
        title={i18n.t('folderReports')}
      />,
      <Tabs key="22" defaultActiveKey="1">
        <TabPane tab={i18n.t('overview')} key="1">

          <div style={{ marginTop: 15, alignContent: 'center', paddingBottom: 15, marginBottom: 15, borderBottomStyle: 'solid', borderBottomWidth: 1, borderBottomColor: '#eee'}}>
            <div style={{ marginBottom: 15}}>
              <label>{i18n.t('project')}</label>
              <br/>
              <Select defaultValue={selectedDropdown || '0'} style={{ width: 370 }} onChange={this.onDropdownChange}>
                <Option value="0">{i18n.t('selectProjet')}</Option>
                {projects.map((item, i) => (
                  <Option key={i} value={item.id}>{item.name} | {item.client.firstname} {item.client.lastname}</Option>
                ))}
              </Select>
            </div>
            {(selectedDropdown !== 0) &&
              <div>
                <label>{i18n.t('period')}</label>
                <br/>
                <Select defaultValue={selectedTab || '0'} style={{ width: 170 }} onChange={this.changeMonth}>
                  <Option value="0">{i18n.t('thisMonth')}</Option>
                  <Option key={1} value={1}>{moment().subtract(1, "month").startOf("month").format('MMMM')}</Option>
                  <Option key={2} value={2}>{moment().subtract(2, "month").startOf("month").format('MMMM')}</Option>
                  <Option key={3} value={3}>{moment().subtract(3, "month").startOf("month").format('MMMM')}</Option>
                </Select>
              </div>
            }
          </div>

          {this.renderTimeList(workingHours, material)}
          {this.renderTotal()}
          <br/>
          <Button loading={saving} disabled={!items.length || items.length < 1} key="1" type="primary" icon="file-pdf" onClick={() => this.generateReport()}>
            {i18n.t('createReportButton')}
          </Button>
        </TabPane>
        <TabPane tab={i18n.t('documents')} key="2">
          {this.renderDocuments()}
        </TabPane>
      </Tabs>,
      modalVisible &&
      <WorkingHoursModal
        key={44}
        edit={edit}
        item={item}
        date={date}
        modalVisible={modalVisible}
        toogleModal={this.toogleModal}
      />
    ])
  }
}