import React from 'react';
import Chat from '../components/Chat/Chat';
import { PageHeader } from 'antd';
import i18n from 'i18n-js';
import firebase from '../constants/firebase';

export default class GroupChat extends React.Component {

  state = {
    employees: []
  }

  componentDidMount = async () => {
    this.loadEmployees()
  }

  loadEmployees = () => {
    const tenant = firebase.auth().currentUser.displayName;
    const db = firebase.firestore()

    this.unsubscribeEmployees = db.collection(`tenants/${tenant}/users`)
      .onSnapshot((querySnapshot) => {
        if (!querySnapshot.exists) {
          this.setState({
            loading: false
          })
        }
        let employees = []
        querySnapshot.forEach((doc) => {
          let user = {
            id: doc.id,
            lastname: doc.data().lastname,
            firstname: doc.data().firstname,
            email: doc.data().email,
            avatar: doc.data().avatar
          }
          employees.push(user)
          this.setState({
            employees: employees,
            loading: false
          })
        })
      })
  }

  renderMember = () => {
    const { employees } = this.state
    let members = ''
    employees.forEach((item, i) => {
      members = members + '' + item.firstname + ' ' + item.lastname
      if (employees.length - 1 > i) {
        members = members + ', '
      }
    })
    return members
  }

  render() {
    return ([
      <PageHeader
        key="11" 
        title={i18n.t('chatScreenHL')}
        subTitle={this.renderMember()}
      />,
      <Chat
        key="22"
        chat={`groupChat`}
      />
    ]);
  }
}