import React from 'react';
import { PageHeader, Collapse, Spin, Radio, Select, message, Button, Tabs, Table, Statistic, Col, Row } from 'antd';
import firebase from '../constants/firebase';
import i18n from 'i18n-js';
import moment from 'moment';
import { groupBy } from 'lodash'
import PieChart from 'react-minimal-pie-chart';
import WorkingHoursModal from '../modal/WorkingHoursModal';

const { TabPane } = Tabs;
const { Panel } = Collapse;
const { Option } = Select;

export default class Analysis extends React.Component {
  state = {
    workingHours: [],
    selectedDropdown: 0,
    employees: [],
    loading: true,
    start: moment().startOf('month').format('YYYY-MM-DD'),
    end: moment().endOf('month').format('YYYY-MM-DD'),
    selectedTab: 0,
    saving: false,
    workingTypes: [
      {
        id: 1,
        name: i18n.t('timeTypeWork')
      },
      {
        id: 2,
        name: i18n.t('timeTypeLoad')
      },
      {
        id: 3,
        name: i18n.t('timeTypePause')
      },
      {
        id: 4,
        name: i18n.t('timeTypeDrive')
      },
    ]
  }
 
  componentDidMount = () => {
    this.loadWorkingHours()
    this.loadEmployees()
    this.loadCompanyDetails()
    this.loadAnalysis()
    this.loadWorkingTypes()
  }

  loadAnalysis = () => {
    const tenant = firebase.auth().currentUser.displayName;
    const db = firebase.firestore()

    db.collection(`tenants/${tenant}/analysis`)
      .onSnapshot((querySnapshot) => {
        let analysis = []
        querySnapshot.forEach((doc) => {
          let item = {
            id: doc.id,
            employee: doc.data().employee,
            month: doc.data().month,
            downloadURL: doc.data().downloadURL || '',
            createdAt: moment(doc.data().createdAt.toDate()).format('DD.MM.YYYY HH:mm')
          }
          analysis.push(item)
          this.setState({
            analysis: analysis,
            loading: false
          })
        })
      })
  }

  loadCompanyDetails = () => {
    const tenant = firebase.auth().currentUser.displayName;
    const db = firebase.firestore()

    db.collection(`tenants`)
      .doc(tenant)
      .onSnapshot((doc) => {
        let companyData = {
          id: doc.id,
          displayName: doc.data().displayName,
          logo: doc.data().logo || '',
          phone: doc.data().phone,
          street: doc.data().street,
          zip: doc.data().zip,
          city: doc.data().city
        }
        this.setState({
          companyData: companyData,
          loading: false
        })
      })
  }

  loadEmployees = () => {
    const tenant = firebase.auth().currentUser.displayName;
    const db = firebase.firestore()

    db.collection(`tenants/${tenant}/users`)
      .where("isActive", "==", true)
      .onSnapshot((querySnapshot) => {
        if (!querySnapshot.exists) {
          this.setState({
            loading: false
          })
        }
        let employees = []
        querySnapshot.forEach((doc) => {
          let user = {
            id: doc.id,
            lastname: doc.data().lastname,
            firstname: doc.data().firstname,
            email: doc.data().email,
            avatar: doc.data().avatar
          }
          employees.push(user)
          this.setState({
            employees: employees,
            loading: false
          })
        })
      })
  }

  loadWorkingHours = () => {
    const db = firebase.firestore()
    const tenant = firebase.auth().currentUser.displayName;
    const { start, end, selectedDropdown } = this.state
    let workingHours = []
    let ref


    if (selectedDropdown && Number(selectedDropdown) !== 0) {
      const userRef = db.collection(`tenants/${tenant}/users`).doc(selectedDropdown)
      ref = db.collection(`tenants/${tenant}/working-hours`)
      .where('date', '>', new Date(start))
      .where('date', '<', new Date(end))
      .where('user', '==', userRef)
    } else {
      ref = db.collection(`tenants/${tenant}/working-hours`)
      .where('date', '>', new Date(start))
      .where('date', '<', new Date(end))
    }

    ref
      .onSnapshot((docs) => {
        if (!docs.exists) {
          this.setState({
            workingHours: [],
            loading: false,
          })
        }

        workingHours = []
        docs.forEach((doc) => {
          let item = {
            id: doc.id,
            date: doc.data().date,
            project: doc.data().project,
            comment: doc.data().comment,
            timeSpent: doc.data().timeSpent || '0:00',
            user: doc.data().user,
            signature: doc.data().signature || '',
            type: doc.data().type
          }
          item.user.get().then((snap) => {
            item.user = snap.data()

            item.project.get().then((snap) => {
              item.project = snap.data()
              item.projectId = snap.id
  
              workingHours.push(item)

              this.setState({
                workingHours: workingHours,
                loading: false
              })
            })
          })
        })
      })
  }

  loadWorkingTypes = () => {
    const tenant = firebase.auth().currentUser.displayName;
    const db = firebase.firestore()

    db.collection(`tenants/${tenant}/working-types`)
      .orderBy('name')
      .onSnapshot((querySnapshot) => {
        if (!querySnapshot.exists) {
          this.setState({
            loading: false
          })
        }
        let workingTypes = [
          {
            id: 1,
            name: i18n.t('timeTypeWork'),
            editable: false
          },
          {
            id: 2,
            name: i18n.t('timeTypeLoad'),
            editable: false
          },
          {
            id: 3,
            name: i18n.t('timeTypePause'),
            editable: false
          },
          {
            id: 4,
            name: i18n.t('timeTypeDrive'),
            editable: false
          },
        ]
        querySnapshot.forEach((doc) => {
          let workingType = {
            id: doc.id,
            name: doc.data().name,
            billable: doc.data().billable,
          }
          workingTypes.push(workingType)
          this.setState({
            workingTypes: workingTypes,
            loading: false
          })
        })
      })
  }

  getSumForMonth = () => {
    const { workingHours } = this.state
    let hours = 0
    let minutes = 0
    workingHours.forEach((item) => {
      hours += Number(item.timeSpent.split(':')[0])
      minutes += Number(item.timeSpent.split(':')[1])
    })
    
    if (minutes >= 60) {
      hours = (hours + minutes/60).toFixed(0)
    }
    minutes = minutes%60
    if (minutes<10) {
      minutes = `0${minutes}`
    }
    return `${hours}:${minutes}`
  }

  getSumForDay = (data) => {
    let hours = 0
    let minutes = 0
    data.forEach((item) => {
      hours += Number(item.timeSpent.split(':')[0])
      minutes += Number(item.timeSpent.split(':')[1])
    })
    if (minutes >= 60) {
      hours = (hours + minutes/60).toFixed(0)
    }
    minutes = minutes%60
    if (minutes<10) {
      minutes = `0${minutes}`
    }
    return `${hours}:${minutes}`
  }

  getTypeText(typeId) {
    const { workingTypes } = this.state
    const type = workingTypes.filter(item => item.id === typeId)
    if (type[0]) {
      return type[0].name
    }
    return ''
  }

  renderTimeList = (workingHours) => {
    const grouped = groupBy(workingHours, item => moment(item.date.toDate()).format('DD.MM.YYYY'))

    return (
      <Collapse key="66">
        {Object.keys(grouped).map((e, key) => ([

          <Panel header={`${e} - ${this.getSumForDay(grouped[e])} ${i18n.t('hours')}`} key={key}>
            <table className="reportTable">
              <thead>
                <tr>
                  <td>{i18n.t('employee')}</td>
                  <td>{i18n.t('project')}</td>
                  <td>{i18n.t('timeType')}</td>
                  <td>{i18n.t('hours')}</td>
                  <td>{i18n.t('comment')}</td>
                  <td></td>
                </tr>
              </thead>
              <tbody>
                {grouped[e].map((element, i) => (
                  <tr key={i}>
                    <td>{element.user.firstname} {element.user.lastname}</td>
                    <td>{element.project.name}</td>
                    <td>{this.getTypeText(element.type)}</td>
                    <td>{element.timeSpent}</td>
                    <td>{element.comment}</td>
                    <td><a key="list-edit" href="#!" onClick={() => this.toogleModal(true, true, element)}>{i18n.t('edit')}</a></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Panel>
  
        ]))}
      </Collapse>
    )
  }

  changeMonth = (val) => {
    this.setState({
      loading: true,
      workingHours: [],
      start: moment().subtract(val,'months').startOf('month').format('YYYY-MM-DD'),
      end: moment().subtract(val,'months').endOf('month').format('YYYY-MM-DD'),
      selectedTab: val
    }, () => {
      this.loadWorkingHours()
    })
  }

  onDropdownChange = (value) => {
    this.setState({
      selectedDropdown: value
    }, () => {
      this.loadWorkingHours()
    })
  }

  getMonthNameForSelectedTab = (tab) => {
    let name = moment().subtract(tab, "month").startOf("month").format('MMMM')
    return `${name} ${moment().year()}`
  }

  getNameForSelectedEmployee = (id) => {
    if (id === 0) return 'Alle Mitarbeiter'
    const { employees } = this.state
    let employee = employees.filter(e => e.id === id)
    return `${employee[0].firstname} ${employee[0].lastname}`
  }

  generateReport = () => {
    const { workingHours, companyData, selectedDropdown, selectedTab } = this.state
    const db = firebase.firestore();
    const tenant = firebase.auth().currentUser.displayName;
    this.setState({saving: true})
    const month = this.getMonthNameForSelectedTab(selectedTab)
    const employee = this.getNameForSelectedEmployee(selectedDropdown)

    const element = {
      user: db.doc(`tenants/${tenant}/users/${firebase.auth().currentUser.uid}`),
      createdAt: new Date(),
      workingHours,
      companyData,
      month,
      mimeType: 'pdf',
      employee: employee
    }
    return db.collection(`tenants/${tenant}/analysis`).add(element)
    .then(() => {
      this.setState({saving: false})
      message.info(i18n.t('reportCreated'));
    })
    .catch((error) => {
      message.error(i18n.t('oops'));
      console.error("Error updating document: ", error)
    })
  }

  getWorkingHoursSumForType = (items) => {
    let hours = 0
    let minutes = 0
    items.filter((item) => {
      hours += Number(item.timeSpent.split(':')[0])
      minutes += Number(item.timeSpent.split(':')[1])
      return item
    })
    if (minutes >= 60) {
      hours = (hours + minutes/60).toFixed(0)
    }
    minutes = minutes%60
    if (minutes<10) {
      minutes = `0${minutes}`
    }
    return `${hours}:${minutes}`
  }

  getPercentageForType = (val) => {
    let total = this.getSumForMonth().replace(':','')
    total = Number(total) / 60
    let current = Number(val.replace(':','')) / 60
    return Number(current * 100 / total).toFixed(0) || 0
  }

  renderPieChart = () => {
    const { workingHours } = this.state
    if (!workingHours || workingHours.length < 1) return false
    let work = this.getPercentageForType(this.getWorkingHoursSumForType(workingHours.filter(e => e.type === 1)))
    let load = this.getPercentageForType(this.getWorkingHoursSumForType(workingHours.filter(e => e.type === 2)))
    let pause = this.getPercentageForType(this.getWorkingHoursSumForType(workingHours.filter(e => e.type === 3)))
    let drive = this.getPercentageForType(this.getWorkingHoursSumForType(workingHours.filter(e => e.type === 4)))
    work = Number(work)
    load = Number(load)
    pause = Number(pause)
    drive = Number(drive)
    
    if (!(work >= 0)) return false

    return (
      <PieChart
        data={[
          { title: i18n.t('timeTypeWork'), value: work, color: '#1abc9c' },
          { title: i18n.t('timeTypeLoad'), value: load, color: '#3498db' },
          { title: i18n.t('timeTypePause'), value: pause, color: 'orange' },
          { title: i18n.t('timeTypeDrive'), value: drive, color: '#9b59b6' },
        ]}
        radius={50}
        ratio={2}
        rounded={false}
        startAngle={180}
        paddingAngle={0}
        lengthAngle={180}
        lineWidth={100}
      />
    )
  }

  renderList = () => {
    const { selectedTab, employees, workingHours, selectedDropdown } = this.state
    const work = workingHours.filter(e => e.type === 1)
    const load = workingHours.filter(e => e.type === 2)
    const pause = workingHours.filter(e => e.type === 3)
    const drive = workingHours.filter(e => e.type === 4)

    return ([
      <div key="21" className="analysisWrapper">
        <div className="analysisHeader">
          <div>
            <label>{i18n.t('period')}</label><br/>
            <Radio.Group defaultValue="d" buttonStyle="solid">
              <Radio.Button 
                value="a"
                {...(selectedTab === 3 ? {checked:true} : {})}
                onClick={() => this.changeMonth(3)}
              >
                {moment().subtract(3, "month").startOf("month").format('MMMM')}
              </Radio.Button>
              <Radio.Button 
                value="b"
                {...(selectedTab === 2 ? {checked:true} : {})}
                onClick={() => this.changeMonth(2)}
              >
                {moment().subtract(2, "month").startOf("month").format('MMMM')}
              </Radio.Button>
              <Radio.Button 
                value="c"
                {...(selectedTab === 1 ? {checked:true} : {})}
                onClick={() => this.changeMonth(1)}
              >
                {moment().subtract(1, "month").startOf("month").format('MMMM')}
              </Radio.Button>
              <Radio.Button 
                value="d"
                {...(selectedTab === 0 ? {checked:true} : {})}
                onClick={() => this.changeMonth(0)}
              >
                {i18n.t('thisMonth')}
              </Radio.Button>
            </Radio.Group>
          </div>
          <div>
            <label>{i18n.t('employee')}</label><br/>
            <Select defaultValue={selectedDropdown || '0'} style={{ width: 170 }} onChange={this.onDropdownChange}>
              <Option value="0">{i18n.t('all')}</Option>
              {employees.map((item, i) => (
                <Option key={i} value={item.id}>{item.firstname} {item.lastname}</Option>
              ))}
            </Select>
          </div>
        </div>
        <div className="analysisStats">
          <Row gutter={16}>
            <Col span={12}>
              <Row gutter={16}>
                <Col span={12}>
                  <Statistic title={`${i18n.t('total')} ${i18n.t('hours')}`} value={this.getSumForMonth()} />
                  <br />
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Statistic 
                    title={<div className="ant-statistic-title"><span style={{background: '#1abc9c'}}></span>{i18n.t('timeTypeWork')}</div>} 
                    value={this.getWorkingHoursSumForType(work)} 
                  />
                  <br />
                </Col>
                <Col span={12}>
                  <Statistic 
                    title={<div className="ant-statistic-title"><span style={{background: 'orange'}}></span>{i18n.t('timeTypePause')}</div>} 
                    value={this.getWorkingHoursSumForType(pause)} 
                  />
                  <br />
                </Col>
                <Col span={12}>
                  <Statistic 
                    title={<div className="ant-statistic-title"><span style={{background: '#3498db'}}></span>{i18n.t('timeTypeLoad')}</div>} 
                    value={this.getWorkingHoursSumForType(load)} 
                  />
                  <br />
                </Col>
                <Col span={12}>
                  <Statistic 
                    title={<div className="ant-statistic-title"><span style={{background: '#9b59b6'}}></span>{i18n.t('timeTypeDrive')}</div>} 
                    value={this.getWorkingHoursSumForType(drive)} 
                  />
                  <br />
                </Col>
              </Row>
            </Col>
            <Col span={8}>
              {this.renderPieChart()}    
            </Col>
          </Row>
        </div>

        <div className="analysisList">
          {this.renderTimeList(workingHours)}
        </div>
      </div>
    ])
  }

  renderDocuments = () => {
    const { analysis } = this.state

    const columns = [
      {
        title: i18n.t('employee'),
        dataIndex: 'employee',
        key: 'employee',
      },
      {
        title: i18n.t('month'),
        dataIndex: 'month',
        key: 'month',
      },
      {
        title: i18n.t('createdAt'),
        dataIndex: 'createdAt',
        key: 'createdAt',
      },
      {
        title: '',
        dataIndex: 'operation',
        render: (text, record) =>
        record.downloadURL.length >= 1 ? (
          <a href="#!" onClick={() => window.open(record.downloadURL, '_blank')}>{i18n.t('open')}</a>
        ) : i18n.t('isCreating'),
      },
    ];

    return (
      <Table key="22" columns={columns} dataSource={analysis} />
    )
  }

  toogleModal = (val, edit, item) => {
    const { date } = this.state
    this.setState({
      modalVisible: val,
      edit: edit,
      item: item,
      date: date
    })
  }

  render() {
    const { loading, workingHours, saving,  modalVisible, item, edit, date } = this.state

    if (loading) {
      return (<Spin />)
    }

    return ([
      <PageHeader 
        key="11" 
        title={i18n.t('timeAnalysis')}
      />,
      <Tabs key="22" defaultActiveKey="1">
        <TabPane tab={i18n.t('overview')} key="1">
          {this.renderList()}
          <Button loading={saving} disabled={!workingHours.length || workingHours.length < 1} key="1" type="primary" icon="file-pdf" onClick={() => this.generateReport()}>
            {i18n.t('createReportButton')}
          </Button>
        </TabPane>
        <TabPane tab={i18n.t('documents')} key="2">
          {this.renderDocuments()}
        </TabPane>
      </Tabs>,
      modalVisible &&
      <WorkingHoursModal
        key={44}
        edit={edit}
        item={item}
        date={date}
        modalVisible={modalVisible}
        toogleModal={this.toogleModal}
      />
    ])
  }
}