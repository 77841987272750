import React from 'react';
import { Button, Form, Input, Modal, Select, message, DatePicker } from 'antd';
import firebase from '../constants/firebase';
import i18n from 'i18n-js';
import moment from 'moment';
import InputMask from "react-input-mask";
const { Option } = Select;

class WorkingHoursModal extends React.Component {
  state = {
    projects: [],
    timer: true,
    workingTypes: [
      {
        id: 1,
        name: i18n.t('timeTypeWork'),
        editable: false
      },
      {
        id: 2,
        name: i18n.t('timeTypeLoad'),
        editable: false
      },
      {
        id: 3,
        name: i18n.t('timeTypePause'),
        editable: false
      },
      {
        id: 4,
        name: i18n.t('timeTypeDrive'),
        editable: false
      },
    ],
  }

  componentDidMount = async () => {
    this.loadProjects(this.props.isAdmin)
    this.loadWorkingTypes()
    if (this.props.item && this.props.item.timeSpent) {
      this.setState({timer: false})
    }
  }

  loadWorkingTypes = () => {
    const tenant = firebase.auth().currentUser.displayName;
    const db = firebase.firestore()

    db.collection(`tenants/${tenant}/working-types`)
      .orderBy('name')
      .onSnapshot((querySnapshot) => {
        if (!querySnapshot.exists) {
          this.setState({
            loading: false
          })
        }
        let workingTypes = [
          {
            id: 1,
            name: i18n.t('timeTypeWork'),
            editable: false
          },
          {
            id: 2,
            name: i18n.t('timeTypeLoad'),
            editable: false
          },
          {
            id: 3,
            name: i18n.t('timeTypePause'),
            editable: false
          },
          {
            id: 4,
            name: i18n.t('timeTypeDrive'),
            editable: false
          },
        ]
        querySnapshot.forEach((doc) => {
          let workingType = {
            id: doc.id,
            name: doc.data().name,
            billable: doc.data().billable,
          }
          workingTypes.push(workingType)
          this.setState({
            workingTypes: workingTypes,
            loading: false
          })
        })
      })
  }

  loadProjects = (isAdmin) => {
    const db = firebase.firestore()
    const tenant = firebase.auth().currentUser.displayName;

    return db.collection(`tenants/${tenant}/projects`)
      .where("active", "==", true)
      .orderBy('createdAt', 'desc')
      .onSnapshot((querySnapshot) => {
        let projects = []
        querySnapshot.forEach((doc) => {
          let project = {
            id: doc.id,
            name: doc.data().name,
            client: doc.data().client,
            projectWorker: doc.data().projectWorker || []
          }
          
          project.client.get().then((snap) => {
            project.client = snap.data()
            project.clientId = snap.id
            if (isAdmin || (project.projectWorker.length < 1 || project.projectWorker.indexOf(firebase.auth().currentUser.uid) >= 0)) {
              projects.push(project)
            }
            this.setState({
              projects: projects,
            })
          })
        })
      })
  }

  updateTime = (values) => {
    this.setState({saving: true})
    const db = firebase.firestore()
    const { item  } = this.props
    let { date, comment, spentTime, timeType, project } = values
    const tenant = firebase.auth().currentUser.displayName;
    const ref = db.collection(`tenants/${tenant}/working-hours`).doc(this.props.item.id)
    
    const timerDuration = this.getTimerDuration(item)
    const currentTime = `${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`
    let day
    if (date.toString().indexOf('.') >= 0) {
      day = date
    } else {
      day = moment(date).format('DD.MM.YYYY')
    }
    const timestamp = moment(day + ' ' + currentTime, 'DD.MM.YYYY HH:mm:ss')

    let element = {
      // user: db.doc(`tenants/${tenant}/users/${firebase.auth().currentUser.uid}`),
      date: new Date(timestamp),
      comment: comment
    }

    if (project) {
      element.project = db.doc(`tenants/${tenant}/projects/${project}`)
    }
    if (timeType) {
      element.type = timeType
    }
    if (spentTime) {
      element.timeSpent = spentTime
    }
    if (timerDuration) {
      element.timeSpent = timerDuration
      element.timer = null
    }

    return ref.update(element)
    .then(() => {
      message.info(i18n.t('companyDetailsUpdated'));
      this.setState({saving: false})
      this.props.toogleModal(false)
    })
    .catch((error) => {
      this.setState({saving: false})
      message.error(i18n.t('oops'));
      console.error("Error updating document: ", error)
    })
  }

  addTime = (values) => {
    this.setState({saving: true})
    const db = firebase.firestore()
    const { timer } = this.state
    let { date, comment, spentTime, timeType, project } = values
    const tenant = firebase.auth().currentUser.displayName;
    const currentTime = `${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`
    let day
    if (date.toString().indexOf('.') >= 0) {
      day = date
    } else {
      day = moment(date).format('DD.MM.YYYY')
    }
    const timestamp = moment(day + ' ' + currentTime, 'DD.MM.YYYY HH:mm:ss')
    const element = {
      user: db.doc(`tenants/${tenant}/users/${firebase.auth().currentUser.uid}`),
      comment: comment || '',
      date: new Date(timestamp),
      project: db.doc(`tenants/${tenant}/projects/${project}`),
      type: timeType
    }
    if (spentTime) {
      element.timeSpent = spentTime
    }
    if (timer) {
      element.timer = new Date()
    }
    return db.collection(`tenants/${tenant}/working-hours`).add(element).then(() => {
      this.setState({saving: false})
      this.props.toogleModal(false)
    })
    .catch((error) => {
      this.setState({saving: false})
      message.error(i18n.t('oops'));
      console.error("Error updating document: ", error)
    })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // console.log('Received values of form: ', values);
        if (this.props.edit) {
          this.updateTime(values)
        } else {
          this.addTime(values)
        }
      }
    });
  }

  onTimeChange = (e) => {
    this.setState({timer: false})
  }

  getTimerDuration = (item) => {
    if (!item || (item && !item.timer)) return false
    const end = moment(new Date())
    let timerDuration = end.diff(moment(item.timer), 'minutes')
    var num = timerDuration;
    var hours = (num / 60);
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    if (rhours < 10) {
      rhours = '0' + rhours
    }
    if (rminutes < 10) {
      rminutes = '0' + rminutes
    }
    return `${rhours}:${rminutes}`
  }

  getSaveBtnlabel = () => {
    const { item } = this.props
    const { timer } = this.state
    const timerDuration = this.getTimerDuration(item)

    if ((item && item.timeSpent) || !timer) {
      return i18n.t('save')
    } else if (timer && timerDuration) {
      return i18n.t('stop')
    } else {
      return i18n.t('start')
    }
  }

  render = () => {
    const { projects, workingTypes, saving } = this.state
    const { item, edit, date  } = this.props
    const { getFieldDecorator } = this.props.form;
    const timerDuration = this.getTimerDuration(item)

    return (
      <Modal
        title={edit ? i18n.t('editTimeScreenHL') : i18n.t('addTimeScreenHL')}
        visible={this.props.modalVisible}
        onOk={this.handleOk}
        onCancel={() => this.props.toogleModal(false)}
        footer={null}
      >
        <Form onSubmit={this.handleSubmit} className="project-form">
          <Form.Item label={i18n.t('date')}>
            {getFieldDecorator('date', {
              initialValue: (item) ? moment(item.date.toDate()) : (date) ? moment(date) : moment(),
            })(
              <DatePicker format="DD.MM.YYYY" />,
            )}
          </Form.Item>
          <Form.Item label={i18n.t('selectProjet')}>
            {getFieldDecorator('project', {
              initialValue: (item) ? item.projectId : '',
              rules: [{ required: true }],
            })(
              <Select 
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => 
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                placeholder={i18n.t('selectProjet')}
              >
                {projects.map((item) => (
                  <Option key={item.id} value={item.id}>{item.name}</Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item label={i18n.t('timeType')}>
            {getFieldDecorator('timeType', {
              initialValue: (item && item.type) ? item.type : 1,
              rules: [{ required: true }],
            })(
              <Select placeholder={i18n.t('selectTimeType')}>
                {workingTypes.map((item) => (
                  <Option key={item.id} value={item.id}>{item.name}</Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item label={i18n.t('comment')}>
            {getFieldDecorator('comment', {
              initialValue: (item) ? item.comment : '',
            })(
              <Input
                placeholder={i18n.t('comment')}
              />,
            )}
          </Form.Item>
          <Form.Item label={i18n.t('spentTime')}>
            {getFieldDecorator('spentTime', {
              initialValue: (item) ? (timerDuration) ? timerDuration : item.timeSpent : '',
            })(
              <InputMask 
                className="ant-input"
                mask={[/[0-1]/, /[0-9]/, ':', /[0-5]/, /[0-9]/]}
                maskPlaceholder="00:00"
                placeholder="00:00"
                onChange={this.onTimeChange}
              />
            )}
          </Form.Item>

          <Form.Item>
            <Button type="primary" loading={saving} htmlType="submit" className="project-form-button">
              {this.getSaveBtnlabel()}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}

export default Form.create({ name: 'project_modal' })(WorkingHoursModal);