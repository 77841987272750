import React from 'react';
import { Tabs, List, Button, PageHeader, Icon, Spin, Popconfirm, message } from 'antd';
import firebase from '../constants/firebase';
import i18n from 'i18n-js';
import moment from 'moment';
import WorkingHoursModal from '../modal/WorkingHoursModal';
const { TabPane } = Tabs;

export default class WorkingHours extends React.Component {
  state = {
    modalVisible: false,
    selectedDropdown: undefined,
    selectedTypeDropdown: undefined,
    date: new Date(),
    comment: undefined,
    time: undefined,
    loading: false,
    start: moment().startOf('isoWeek').format('YYYY-MM-DD'),
    end: moment().endOf('isoWeek').format('YYYY-MM-DD'),
    workingTypes: [
      {
        id: 1,
        name: i18n.t('timeTypeWork'),
        editable: false
      },
      {
        id: 2,
        name: i18n.t('timeTypeLoad'),
        editable: false
      },
      {
        id: 3,
        name: i18n.t('timeTypePause'),
        editable: false
      },
      {
        id: 4,
        name: i18n.t('timeTypeDrive'),
        editable: false
      },
    ],
    projects: [],
    workingHours: [],
    days: [],
    count: 0,
    selectedDayIndex: moment().isoWeekday()-1,
    selectedHours: 0,
    selectedMinutes: 0,
    runningTimer: [],
    isAdmin: false
  }

  componentDidMount = async () => {
    const tenant = firebase.auth().currentUser.displayName;
    this.setState({tenant: tenant, loading: true})
    this.loadProjects()
    this.setWeekDays()
    this.loadRunningTimer()
    this.loadWorkingTypes()
    this.loadUserData()
  }

  _increaseCount = () => {
    this.setState({ count: this.state.count + 1 }, () => {
      this.setWeekDays()
    })
  }

  _decreaseCount = () => {
    this.setState({ count: this.state.count - 1 }, () => {
      this.setWeekDays()
    })
  }

  _today = () => {
    this.setState({ 
      count: 0,
      selectedDayIndex: moment().isoWeekday()-1,
      date: moment().format('DD.MM.YYYY')
    }, () => {
      this.setWeekDays()
    })
  }

  loadUserData = async () => {
    const db = firebase.firestore()
    const tenant = firebase.auth().currentUser.displayName;
    const currentUser = firebase.auth().currentUser.uid
    this.unsubscribeUserData = db.collection(`tenants/${tenant}/users`)
      .doc(currentUser)
      .onSnapshot((querySnapshot) => {
        let userData = {
          stars: querySnapshot.data().stars,
          isAdmin: querySnapshot.data().isAdmin
        }
        this.setState({
          isAdmin: userData.isAdmin,
        })
      })
    
  }

  setWeekDays = () => {
    const { count } = this.state
    let startOfWeek = moment().startOf('isoWeek')
    let endOfWeek = moment().endOf('isoWeek')
    if (count > 0) {
      startOfWeek = moment().add(count, 'weeks').startOf('isoWeek')
      endOfWeek = moment().add(count, 'weeks').endOf('isoWeek')
    }
    if (count < 0) {
      startOfWeek = moment().subtract(Math.abs(count), 'weeks').startOf('isoWeek')
      endOfWeek = moment().subtract(Math.abs(count), 'weeks').endOf('isoWeek')
    }

    let days = []
    let day = startOfWeek

    while (day <= endOfWeek) {
        days.push(day.toDate())
        day = day.clone().add(1, 'd')
    }
    this.setState({
      days: days,
      start: startOfWeek.format('YYYY-MM-DD'),
      end: endOfWeek.format('YYYY-MM-DD')
    }, () => {
      this.loadWorkingHours()
      this.onChangeTab()
    })
  }

  loadWorkingTypes = () => {
    const tenant = firebase.auth().currentUser.displayName;
    const db = firebase.firestore()

    db.collection(`tenants/${tenant}/working-types`)
      .orderBy('name')
      .onSnapshot((querySnapshot) => {
        if (!querySnapshot.exists) {
          this.setState({
            loading: false
          })
        }
        let workingTypes = [
          {
            id: 1,
            name: i18n.t('timeTypeWork'),
            editable: false
          },
          {
            id: 2,
            name: i18n.t('timeTypeLoad'),
            editable: false
          },
          {
            id: 3,
            name: i18n.t('timeTypePause'),
            editable: false
          },
          {
            id: 4,
            name: i18n.t('timeTypeDrive'),
            editable: false
          },
        ]
        querySnapshot.forEach((doc) => {
          let workingType = {
            id: doc.id,
            name: doc.data().name,
            billable: doc.data().billable,
          }
          workingTypes.push(workingType)
          this.setState({
            workingTypes: workingTypes,
            loading: false
          })
        })
      })
  }

  loadRunningTimer = () => {
    const db = firebase.firestore()
    const { tenant } = this.state
    const currentUserRef = db.collection(`tenants/${tenant}/users`).doc(firebase.auth().currentUser.uid)

    return db.collection(`tenants/${tenant}/working-hours`)
      .where('timer', '<=', new Date())
      .where("user", '==', currentUserRef)
      .onSnapshot((querySnapshot) => {
        if (!querySnapshot.exists) {
          this.setState({runningTimer: []})
        }
        
        let workingHours = []
        querySnapshot.forEach((doc) => {
          console.log("test")
          let item = {
            id: doc.id,
          }
          workingHours.push(item)

          this.setState({
            runningTimer: workingHours
          })
        })
      })
  }

  loadProjects = () => {
    const db = firebase.firestore()
    const { tenant } = this.state

    return db.collection(`tenants/${tenant}/projects`)
      .where("active", "==", true)
      .orderBy('createdAt', 'desc')
      .onSnapshot((querySnapshot) => {
        let projects = []
        querySnapshot.forEach((doc) => {
          let project = {
            id: doc.id,
            name: doc.data().name,
          }
          projects.push(project)
          this.setState({
            projects: projects,
          })
        })
      })
  }

  loadWorkingHours = () => {
    const db = firebase.firestore()
    const { tenant, start, end } = this.state
    const currentUserRef = firebase.firestore().collection(`tenants/${tenant}/users`).doc(firebase.auth().currentUser.uid)

    return db.collection(`tenants/${tenant}/working-hours`)
      .where('date', '>', new Date(start))
      .where('date', '<', new Date(moment(end).add('d',1)))
      .where("user", '==', currentUserRef)
      .orderBy('date')
      .onSnapshot((querySnapshot) => {
        if (!querySnapshot.exists) {
          this.setState({loading: false, workingHours: []})
        }
        
        let workingHours = []
        querySnapshot.forEach((doc) => {
          let item = {
            id: doc.id,
            date: doc.data().date,
            project: doc.data().project,
            comment: doc.data().comment,
            timeSpent: doc.data().timeSpent || '0:00',
            signature: doc.data().signature,
            user: doc.data().user,
            type: doc.data().type,
            timer: (doc.data().timer) ? doc.data().timer.toDate() : null
          }
          item.project.get().then((snap) => {
            item.project = snap.data()
            item.projectId = snap.id
            item.client = snap.data().client

            item.client.get().then((snap) => {
              item.client = snap.data()
              item.clientId = snap.id
              workingHours.push(item)

              this.setState({
                workingHours: workingHours,
                loading: false
              })
            })
          })

          this.setState({
            workingHours: workingHours,
            loading: false
          })
        })
      })
  }

  onChangeTab = (data) => {
    const { days, selectedDayIndex } = this.state
    let selectedDayIndexTMP = selectedDayIndex
    if (!data) {
      selectedDayIndexTMP = selectedDayIndex
    } else {
      selectedDayIndexTMP = data['i']
    }
    const selectedDate = (moment(days[selectedDayIndexTMP]).format('DD.MM.YY') === moment(new Date()).format('DD.MM.YY')) ? `${i18n.t('today')}, ${moment(days[selectedDayIndexTMP]).format('DD MMM')}` : moment(days[selectedDayIndexTMP]).format('dddd, DD MMM')
    this.setState({ 
      date: new Date(days[selectedDayIndexTMP]), 
      selectedDayIndex: selectedDayIndexTMP, 
      selectedDate: selectedDate 
    })
  }

  getWorkingHoursSumForDate = (day) => {
    const { workingHours } = this.state
    let hours = 0
    let minutes = 0
    workingHours.filter((item) => {
      if (item.timeSpent && moment(item.date.toDate()).format('DD.MM.YYYY') === moment(day).format('DD.MM.YYYY')) {
        hours += Number(item.timeSpent.split(':')[0])
        minutes += Number(item.timeSpent.split(':')[1])
      }
      return item
    })
    if (minutes >= 60) {
      hours = (hours + minutes/60).toFixed(0)
    }
    minutes = minutes%60
    if (minutes<10) {
      minutes = `0${minutes}`
    }
    return `${hours}:${minutes}`
  }

  toogleModal = (val, edit, item) => {
    const { date } = this.state
    this.setState({
      modalVisible: val,
      edit: edit,
      item: item,
      date: date
    })
  }

  getTypeText(typeId) {
    const { workingTypes } = this.state
    const type = workingTypes.filter(item => item.id === typeId)
    if (type[0]) {
      return type[0].name
    }
    return ''
  }

  deleteTime = (id) => {
    const db = firebase.firestore()
    const { tenant } = this.state
    const ref = db.collection(`tenants/${tenant}/working-hours`).doc(id)
    return ref.delete()
    .then(() => {
      message.info(i18n.t('hasBeenDeleted'));
    })
    .catch((error) => {
      message.error(i18n.t('oops'));
      console.error("Error updating document: ", error)
    })
  }

  renderTimeList = (day) => {
    const { workingHours } = this.state
    let itemsForSelectedDay = []
    workingHours.filter((item) => {
      if (moment(item.date.toDate()).format('DD.MM.YYYY') === moment(day).format('DD.MM.YYYY')) {
        itemsForSelectedDay.push(item)
      }
      return item
    })

    return (
      <List
        className="hours-list"
        itemLayout="horizontal"
        dataSource={itemsForSelectedDay}
        renderItem={item => (
          <List.Item
            actions={[
            <a key="list-edit" href="#!" onClick={() => this.toogleModal(true, true, item)}>{(item.timer) ? i18n.t('stop') : i18n.t('edit')}</a>, 
            <Popconfirm
              title={i18n.t('areYouSure')}
              onConfirm={() => this.deleteTime(item.id)}
              okText={i18n.t('yes')}
              cancelText={i18n.t('no')}
            >
              <a href="#!">{i18n.t('delete')}</a>
            </Popconfirm>
          ]}
          >
            <List.Item.Meta
              title={item.project.name}
              description={
                <div>
                  <div>{item.client.firstname} {item.client.lastname}</div>
                  <div>
                    <small>{this.getTypeText(item.type)}</small>
                  </div>
                  <div>
                    <small>{item.comment}</small>
                  </div>
                </div>
              }
            />
            <div>
              {item.timer &&
                <div className="runningTimer">
                  <Icon type="clock-circle" className="runningClock" spin /> 
                  <span> </span>
                  {moment(item.timer).fromNow(true)}
                </div>
              }
              {!item.timer &&
                item.timeSpent
              }
            </div>
          </List.Item>
        )}
      />
    )
  }

  onTabClick = (i) => {
    this.setState({selectedDayIndex: i}, () => {
      this.onChangeTab()
    })
  }

  render() {
    const { modalVisible, isAdmin, loading, days, selectedDayIndex, selectedDate, edit, item, date, runningTimer } = this.state

    if (loading) {
      return (<Spin />)
    }

    // console.log(runningTimer)

    return ([
      <PageHeader 
        key="11" 
        title={i18n.t('timeScreenHL')} 
        extra={[
          <Button key="1" type="primary" icon="clock-circle" onClick={() => this.toogleModal(true)}>
            {i18n.t('addTimeScreenHL')}
          </Button>,
        ]}
      />,
      // runningTimer.length > 0 &&
      //   <Alert key="111"  message="Informational Notes" type="info" showIcon />,
      <div key="22" className="timeNavWrapper">
        <div>
          <Icon type="arrow-left" onClick={this._decreaseCount} />
        </div>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
          <span>{selectedDate || moment(new Date()).format('DD MMMM')}</span>
          <Button type="link" onClick={this._today}>
            {i18n.t('today')}
          </Button>
        </div>
        <div>
          <Icon type="arrow-right" onClick={this._increaseCount} />
        </div>
      </div>,
      <Tabs key="33" onTabClick={this.onTabClick} defaultActiveKey={selectedDayIndex.toString()} activeKey={selectedDayIndex.toString()}>
        {days.map((day, i) => (
          <TabPane 
            tab={
              <div style={{textAlign: 'center'}}>
                <div className={(selectedDayIndex === i) ? (moment(day).format('DD.MM.YYYY') === moment().format('DD.MM.YYYY')) ? 'textHeadingActiveToday' : 'textHeadingActive' : (moment(day).format('DD.MM.YYYY') === moment().format('DD.MM.YYYY')) ? 'textHeadingToday' : 'textHeading'}>{moment(day).format('dddd').substr(0,2)}</div>
                <div className={(selectedDayIndex === i) ? (moment(day).format('DD.MM.YYYY') === moment().format('DD.MM.YYYY')) ? 'tabDateActiveToday' : 'tabDateActive' : (moment(day).format('DD.MM.YYYY') === moment().format('DD.MM.YYYY')) ? 'tabDateToday' : 'tabDate'}>{this.getWorkingHoursSumForDate(day)}</div>
              </div>
            } 
            key={i}
          >
            {this.renderTimeList(day)}
          </TabPane>
        ))}
      </Tabs>,
      modalVisible &&
        <WorkingHoursModal
          key={44}
          edit={edit}
          item={item}
          date={date}
          isAdmin={isAdmin}
          modalVisible={modalVisible}
          toogleModal={this.toogleModal}
        />
    ]);
  }
}