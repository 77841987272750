import React from 'react';
import { Button, Form, Input, Modal, message, Switch } from 'antd';
import firebase from '../constants/firebase';
import i18n from 'i18n-js';

class WorkingTypesModal extends React.Component {
  state = {
    loading: true,
  }

  componentDidMount = async () => {
    if (this.props.edit) {
      this.setState({selectedColor: this.props.item.color})
    }
  }

  addMaterial = (values) => {
    this.setState({saving: true})
    const db = firebase.firestore();
    const tenant = firebase.auth().currentUser.displayName;
    const element = {
      user: db.doc(`tenants/${tenant}/users/${firebase.auth().currentUser.uid}`),
      name: values.name,
      createdAt: new Date(),
      billable: values.billable
    }
    return db.collection(`tenants/${tenant}/working-types`).add(element)
    .then(() => {
      this.setState({saving: false})
      this.props.toogleModal(false)
    })
    .catch((error) => {
      message.error(i18n.t('oops'));
      console.error("Error updating document: ", error)
    })
  }

  updateItem = (values) => {
    this.setState({saving: true})
    const db = firebase.firestore()
    const { item } = this.props;
    const tenant = firebase.auth().currentUser.displayName;
    const ref = db.collection(`tenants/${tenant}/working-types`).doc(item.id)

    let element = {}
    if (values.name) {
      element.name = values.name
    }
    element.billable = values.billable

    return ref.update(element).then(() => {
      message.info(i18n.t('companyDetailsUpdated'));
      this.setState({saving: false})
      this.props.toogleModal(false)
    })
    .catch((error) => {
      message.error(i18n.t('oops'));
      console.error("Error updating document: ", error)
    })
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // console.log('Received values of form: ', values);
        if (this.props.edit) {
          this.updateItem(values)
        } else {
          this.addMaterial(values)
        }
      }
    });
  };

  render = () => {
    const { saving } = this.state;
    const { item, edit } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <Modal
        title={edit ? i18n.t('editWorkingTypes') : i18n.t('addWorkingTypes')}
        visible={this.props.modalVisible}
        onOk={this.handleOk}
        onCancel={() => this.props.toogleModal(false)}
        footer={null}
      >
        <Form onSubmit={this.handleSubmit} className="material-form">
          <Form.Item label={i18n.t('type')}>
            {getFieldDecorator('name', {
              initialValue: (item) ? item.name : '',
              rules: [{ required: true }],
            })(
              <Input
                placeholder={i18n.t('type')}
              />,
            )}
          </Form.Item>
          <Form.Item label={i18n.t('billable')}>
            {getFieldDecorator('billable', {
              valuePropName: 'checked',
              initialValue: (item) ? item.billable : false,
            })(
              <Switch />,
            )}
          </Form.Item>
          <Form.Item>
            <Button type="primary" loading={saving} htmlType="submit" className="material-form-button">
              {i18n.t('save')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}

export default Form.create({ name: 'workingTypes_modal' })(WorkingTypesModal);