import React from 'react'
import { Form, Icon, Input, Button, Alert, message } from 'antd';
import firebase from '../constants/firebase';
import { Link } from "react-router-dom";
import i18n from 'i18n-js';
import helper from '../utils/helper';

class Login extends React.Component {
  state = {
    tenant: '',
    error: ''
  }

  componentDidMount = () => {
    const currentUser = firebase.auth().currentUser
    if (currentUser) {
      return this.props.history.push('/');
    }
  }

  _signInAsync = async (res) => {
    const db = firebase.firestore()
    const tenant = res.user.displayName;
    
    db
      .collection(`tenants/${tenant}/users`)
      .doc(res.user.uid)
      .get()
      .then(async(snap) => {
        this.setState({fetching: false})
        if (snap.exists && tenant === snap.data().tenant && snap.data().isActive) {
        } else {
          helper.logOutUser(this.props.history);
          message.error(i18n.t('notActive'), 10);
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // console.log('Received values of form: ', values);
        this.setState({fetching: true})
        firebase
        .auth()
        .signInWithEmailAndPassword(values.email, values.password)
        .then(user => {
          return this._signInAsync(user)
        })
        .catch(error => {
          let loginError = ""
          if (error.code = 'auth/invalid-email') {
            loginError = i18n.t('checkUserPw')
          } else if (error.code == 'auth/user-not-found') {
            loginError = i18n.t('checkUserPw')
          } else if (error.code == 'auth/wrong-password') {
            loginError = i18n.t('checkUserPw')
          }
          return this.setState({error: loginError});
        });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { error, tenant, fetching } = this.state

    return (
      <Form onSubmit={this.handleSubmit} className="login-form">
        <h3 style={{textAlign: 'center', marginBottom: 20}}>{i18n.t('loginText', {tenant: tenant})}</h3>
        <Form.Item>
          {getFieldDecorator('email', {
            rules: [{ required: true }],
          })(
            <Input
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder={i18n.t('email')}
            />,
          )}
        </Form.Item>
        <Form.Item style={{marginBottom: 0}}>
          {getFieldDecorator('password', {
            rules: [{ required: true }],
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder={i18n.t('password')}
            />,
          )}
        </Form.Item>
        <div style={{textAlign: 'right', marginBottom: 20}}>
          <Link to="/forgot-password" className="login-form-forgot">{i18n.t('forgotPassword')}</Link>
        </div>
        <Form.Item>
          <Button loading={fetching} type="primary" htmlType="submit" className="login-form-button" style={{width: '100%'}}>
            {i18n.t('login')}
          </Button>
          {/* <Link to="/register">{i18n.t('signup')}</Link> */}
        </Form.Item>
        <a href="https://smartwerker.app/support/erste-schritte/app/allgemein-anmeldung/" rel="noopener noreferrer" target="_blank">
          {i18n.t('loginHelp')}
        </a>
        {error && 
          <Alert message={error} type="error" />
        }
      </Form>
    );
  }
}

export default Form.create({ name: 'normal_login' })(Login);
