import React from 'react';
import { Tabs, List, Avatar, Button, Skeleton, PageHeader, Alert } from 'antd';
import firebase from '../constants/firebase';
import ProjectModal from '../modal/ProjectModal';
import { Link } from "react-router-dom";
import i18n from 'i18n-js';
import moment from 'moment';
import UserAvatar from '../components/UserAvatar';
const { TabPane } = Tabs;

export default class Home extends React.Component {
  state = {
    loading: true,
    projects: [],
    tasks: [],
    favorites: [],
    modalVisible: false,
  };

  componentDidMount = async () => {
    this.loadUserData()
    this.loadCompanyDetails()
  }

  loadCompanyDetails = () => {
    const tenant = firebase.auth().currentUser.displayName;
    const db = firebase.firestore()

    db.collection(`tenants`)
      .doc(tenant)
      .onSnapshot((doc) => {
        let companyData = {
          createdAt: doc.data().createdAt.toDate(),
          stripe: doc.data().stripe,
          userAmount: doc.data().userAmount
        }
        this.setState({
          companyData: companyData,
          loading: false
        })
      })
  }

  loadUserData = async () => {
    const db = firebase.firestore()
    const tenant = firebase.auth().currentUser.displayName;
    const currentUser = firebase.auth().currentUser.uid
    this.unsubscribeUserData = db.collection(`tenants/${tenant}/users`)
      .doc(currentUser)
      .onSnapshot((querySnapshot) => {
        let favorites = []
        let userData = {
          stars: querySnapshot.data().stars,
          isAdmin: querySnapshot.data().isAdmin
        }
        this.loadProjects(userData.isAdmin)
        if (userData.stars) {
          userData.stars.forEach(item => {
            db.collection(`tenants/${tenant}/projects`)
            .doc(item)
            .get()
            .then((data) => {
              let project = {
                id: data.id,
                name: data.data().name,
                client: data.data().client,
                info: data.data().info,
                active: data.data().active,
                color: data.data().color
              }
              project.client.get().then((snap) => {
                project.client = snap.data()
                if (project.active === true) {
                  favorites.push(project)
                } 
                this.setState({
                  favorites: favorites,
                })
              })
            })
          })
        }
      })
    
  }

  loadProjects = (isAdmin) => {
    const tenant = firebase.auth().currentUser.displayName;
    const db = firebase.firestore()
    const currentUserRef = firebase.firestore().collection(`tenants/${tenant}/users`).doc(firebase.auth().currentUser.uid)

    this.unsubscribeProjects = db.collection(`tenants/${tenant}/projects`)
      .where('active', '==', true)
      .orderBy('createdAt', 'desc')
      .onSnapshot((querySnapshot) => {
        if (!querySnapshot.exists) {
          this.setState({
            loading: false
          })
        }
        let projects = []
        let tasks = []
        querySnapshot.forEach((doc) => {
          let project = {
            id: doc.id,
            name: doc.data().name,
            client: doc.data().client,
            info: doc.data().info,
            active: doc.data().active,
            containsMaterial: doc.data().containsMaterial,
            containsReport: doc.data().containsReport,
            planedTime: doc.data().planedTime,
            projectWorker: doc.data().projectWorker || []
          }

          doc.ref.collection('tasks')
            .where("assignee", '==', currentUserRef)
            .where("done", '==', false)
            .onSnapshot((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                let taskData = {
                  id: doc.id,
                  name: doc.data().name,
                  createdAt: doc.data().createdAt,
                  deadline: doc.data().deadline,
                  uid: doc.id,
                  done: doc.data().done,
                  containsDeadline: doc.data().containsDeadline,
                  project: project.name,
                  projectId: project.id
                }
                tasks.push(taskData)
              })
          });

          project.client.get().then((snap) => {
            project.client = snap.data()
            project.clientId = snap.id
            if (isAdmin || (project.projectWorker.length < 1 || project.projectWorker.indexOf(firebase.auth().currentUser.uid) >= 0)) {
              projects.push(project)
            } 
            this.setState({
              projects: projects,
              tasks: tasks,
              loading: false
            })
          })
        })
      })
  }

  toogleModal = (val, edit, item) => {
    this.setState({modalVisible: val, edit: edit, item: item})
  }

  _addProject = () => {
    this.setState({modalVisible: true})
  }

  getUsedTrialDays = () => {
    const { companyData } = this.state
    const end = moment(new Date())
    let trialDuration = moment.duration(end.diff(companyData.createdAt)).asDays()
    if (trialDuration < 0) {
      trialDuration = 0
    }
    return Number(trialDuration).toFixed(0)
  }

  render() {
    const { loading, projects, tasks, favorites, modalVisible, edit, item, companyData } = this.state;

    if (!companyData) {
      return null
    }

    return ([
      (this.getUsedTrialDays() > 30 && !companyData.stripe && companyData.userAmount > 1) &&
        <div key="111">
          <Alert message={i18n.t('tialExpiredUserMessage')} type="error" />
          <br/>
        </div>,
      <PageHeader 
        key="11" 
        title={i18n.t('projectsScreenHL')} 
        extra={[
          <Button key="1" type="primary" icon="plus" onClick={this._addProject}>
            {i18n.t('addProjectScreenHL')}
          </Button>,
        ]}
      />,
      <Tabs key="22" defaultActiveKey="1">
        <TabPane tab={i18n.t('active')} key="1">
          <List
            className="projects-list"
            loading={loading}
            itemLayout="horizontal"
            dataSource={projects}
            renderItem={item => (
              <List.Item
                actions={[<a key="list-edit" href="#!" onClick={() => this.toogleModal(true, true, item)}>{i18n.t('edit')}</a>, <Link to={`/project/${item.id}`}>{i18n.t('open')}</Link>]}
              >
                <Skeleton avatar title={false} loading={item.loading} active>
                  <List.Item.Meta
                    avatar={
                      <UserAvatar size="50" name={item.name} />
                    }
                    title={<Link to={`/project/${item.id}`}>{item.name}</Link>}
                    description={`${item.client.firstname} ${item.client.lastname}`}
                  />
                </Skeleton>
              </List.Item>
            )}
          />
        </TabPane>
        <TabPane tab={i18n.t('myProjects')} key="2">
          <List
            className="projects-list"
            loading={loading}
            itemLayout="horizontal"
            dataSource={favorites}
            renderItem={item => (
              <List.Item
                actions={[<a key="list-edit" href="#!" onClick={() => this.toogleModal(true, true, item)}>{i18n.t('edit')}</a>, <Link to={`/project/${item.id}`}>{i18n.t('open')}</Link>]}
              >
                <Skeleton avatar title={false} loading={item.loading} active>
                  <List.Item.Meta
                    avatar={
                      <UserAvatar size="50" name={item.name} />
                    }
                    title={<Link to={`/project/${item.id}`}>{item.name}</Link>}
                    description={`${item.client.city} / ${item.client.street}`}
                  />
                </Skeleton>
              </List.Item>
            )}
          />
        </TabPane>
        <TabPane tab={i18n.t('myTasks')} key="3">
          <List
            className="projects-list"
            loading={loading}
            itemLayout="horizontal"
            dataSource={tasks}
            renderItem={item => (
              <List.Item
                actions={[
                  <Link to={`/project/${item.projectId}`}>{i18n.t('open')}</Link>
                ]}
              >
                <List.Item.Meta
                  avatar={(item.done) ? <Avatar icon="check" style={{border: '1px solid', borderColor: '#ccc', backgroundColor: 'white', color: 'black'}} onClick={() => this.toggleItemStatus(item)} /> : <Avatar style={{border: '1px solid', borderColor: '#ccc', backgroundColor: 'white'}} onClick={() => this.toggleItemStatus(item)}> </Avatar>}
                  title={item.name}
                  description={(item.containsDeadline) ? <span style={{color: (new Date(moment(item.deadline.toDate()).format('YYYY-MM-DD')) <= new Date() && !item.done) ? 'red' : 'inherit'}}>{moment(item.deadline.toDate()).format('DD.MM.YY')}</span> : ''}
                />
                {/* <div>
                  {item.containsAssignee &&
                    <UserAvatar
                      size="30"
                      name={`${item.assignee.firstname} ${item.assignee.lastname}`}
                      src={item.assignee.avatar}
                    />
                  }
                </div> */}
              </List.Item>
            )}
          />
        </TabPane>
      </Tabs>,
      modalVisible &&
        <ProjectModal
          key={33}
          edit={edit}
          item={item}
          modalVisible={modalVisible}
          toogleModal={this.toogleModal}
        />
    ]);
  }
}