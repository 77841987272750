import React from 'react';
import { PageHeader, Button, List, Spin, Popconfirm, message } from 'antd';
import firebase from '../constants/firebase';
import i18n from 'i18n-js';
import WorkingTypesModal from '../modal/WorkingTypesModal';

export default class WorkingTypes extends React.Component {
  state = {
    workingTypes: [
      {
        id: 1,
        name: i18n.t('timeTypeWork'),
        editable: false
      },
      {
        id: 2,
        name: i18n.t('timeTypeLoad'),
        editable: false
      },
      {
        id: 3,
        name: i18n.t('timeTypePause'),
        editable: false
      },
      {
        id: 4,
        name: i18n.t('timeTypeDrive'),
        editable: false
      },
    ],
    loading: true
  }

  componentDidMount = () => {
    this.loadWorkingTypes()
  }

  loadWorkingTypes = () => {
    const tenant = firebase.auth().currentUser.displayName;
    const db = firebase.firestore()

    db.collection(`tenants/${tenant}/working-types`)
      .orderBy('name')
      .onSnapshot((querySnapshot) => {
        if (!querySnapshot.exists) {
          this.setState({
            loading: false
          })
        }
        let workingTypes = [
          {
            id: 1,
            name: i18n.t('timeTypeWork'),
            editable: false
          },
          {
            id: 2,
            name: i18n.t('timeTypeLoad'),
            editable: false
          },
          {
            id: 3,
            name: i18n.t('timeTypePause'),
            editable: false
          },
          {
            id: 4,
            name: i18n.t('timeTypeDrive'),
            editable: false
          },
        ]
        querySnapshot.forEach((doc) => {
          let workingType = {
            id: doc.id,
            name: doc.data().name,
            editable: true,
            billable: doc.data().billable,
          }
          workingTypes.push(workingType)
          this.setState({
            workingTypes: workingTypes,
            loading: false
          })
        })
      })
  }

  toogleModal = (val, edit, item) => {
    this.setState({
      modalVisible: val,
      edit: edit,
      item: item
    })
  }

  removeItem = (itemId) => {
    const db = firebase.firestore()
    const tenant = firebase.auth().currentUser.displayName;
    const ref = db.collection(`tenants/${tenant}/working-types`).doc(itemId)
    ref.delete()
    .then(function() {
      message.info(i18n.t('hasBeenDeleted'));
    })
    .catch(function(error) {
      message.error(i18n.t('oops'));
    })
  }

  render() {
    const { workingTypes, loading, modalVisible, edit, item } = this.state

    if (loading) {
      return (<Spin/>)
    }

    return ([
      <PageHeader 
        key="11" 
        title={i18n.t('workingTypes')}
        extra={[
          <Button key="1" type="primary" icon="user-add" onClick={() => this.toogleModal(true)}>
            {i18n.t('addWorkingTypes')}
          </Button>,
        ]}
      />,
      <List
        key="22"
        itemLayout="horizontal"
        dataSource={workingTypes}
        loading={loading}
        renderItem={item => (
          <List.Item
            actions={item.editable ? [
              <a key="list-edit" href="#!" onClick={() => this.toogleModal(true, true, item)}>{i18n.t('edit')}</a>, 
              <Popconfirm
                title={i18n.t('areYouSure')}
                onConfirm={() => this.removeItem(item.id)}
                okText={i18n.t('yes')}
                cancelText={i18n.t('no')}
              >
                <a href="#!">{i18n.t('delete')}</a>
              </Popconfirm>
            ] : null}
          >
            <List.Item.Meta
              title={item.name}
              description={item.editable ? item.billable ? i18n.t('billable') : null : i18n.t('predefined')}
            />
          </List.Item>
        )}
      />,
      modalVisible &&
        <WorkingTypesModal
          key="33"
          item={item}
          edit={edit}
          toogleModal={this.toogleModal}
          modalVisible={modalVisible}
        />
      
    ])
  }
}