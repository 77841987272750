import React from 'react';
import { PageHeader, List, Avatar, Button, Spin, message, Popconfirm } from 'antd';
import firebase from '../../constants/firebase';
import MaterialModal from '../../modal/MaterialModal';
import i18n from 'i18n-js';

export default class Material extends React.Component {
  state = {
    loading: true,
    material: []
  }

  componentDidMount = () => {
    this.loadMaterial()
  }

  loadMaterial = () => {
    const db = firebase.firestore()
    const tenant = firebase.auth().currentUser.displayName;
    db.collection(`tenants/${tenant}/projects/${this.props.project}/material`)
      .orderBy('done')
      .orderBy('createdAt')
      .onSnapshot((doc) => {
      let m = [],
          materialProcessed = [],
          materialWillDelivered = []

      doc.docs.forEach((item) => {
        const data = {
          name: item.data().name,
          createdAt: item.data().createdAt,
          uid: item.id,
          quantity: item.data().quantity || '1',
          done: item.data().done,
          delivery: item.data().delivery
        }

        if (data.done === true) {
          materialProcessed.push(data)
        } else if (data.delivery === true) {
          materialWillDelivered.push(data)
        } else {
          m.push(data)
        }
      })
      this.setState({
        material: m,
        materialProcessed: materialProcessed,
        materialWillDelivered: materialWillDelivered,
        loading: false
      })
    })
  }

  toggleItemStatus = (item) => {
    const db = firebase.firestore()
    const tenant = firebase.auth().currentUser.displayName;
    const ref = db.collection(`tenants/${tenant}/projects/${this.props.project}/material`).doc(item.uid)

    return ref.update({
      done: !item.done,
      updatedAt: new Date()
    })
  }

  removeItem = (itemId) => {
    const db = firebase.firestore()
    const tenant = firebase.auth().currentUser.displayName;
    const ref = db.collection(`tenants/${tenant}/projects/${this.props.project}/material`).doc(itemId)
    ref.delete()
    .then(function() {
      message.info(i18n.t('hasBeenDeleted'));
    })
    .catch(function(error) {
      message.error(i18n.t('oops'));
      // console.error("Error removing document: ", error)
    })
  }

  toogleModal = (val, edit, item) => {
    this.setState({
      modalVisible: val,
      edit: edit,
      item: item,
      materialQuantity: '',
      materialItem: '',
    })
  }

  renderList = (data) => {
    const { loading } = this.state
    if (data.length < 1) {
      return null
    }

    return (
      <List
        itemLayout="horizontal"
        dataSource={data}
        loading={loading}
        renderItem={item => (
          <List.Item
            actions={[
              <a key="list-edit" href="#!" onClick={() => this.toogleModal(true, true, item)}>{i18n.t('edit')}</a>,
              <Popconfirm
                title={i18n.t('areYouSure')}
                onConfirm={() => this.removeItem(item.uid)}
                okText={i18n.t('yes')}
                cancelText={i18n.t('no')}
              >
                <a href="#!">{i18n.t('delete')}</a>
              </Popconfirm>
            ]}
          >
            <List.Item.Meta
              avatar={(item.done) ? <Avatar icon="check" onClick={() => this.toggleItemStatus(item)} /> : <Avatar onClick={() => this.toggleItemStatus(item)}> </Avatar>}
              title={item.name}
              description={`x ${item.quantity}`}
            />
          </List.Item>
        )}
      />
    )
  }

  render() {
    const { material, materialWillDelivered, materialProcessed, modalVisible, edit, item, loading } = this.state

    if (loading) {
      return (<Spin />)
    }

    return ([
      <PageHeader 
        key="11" 
        title={i18n.t('material')}
        extra={[
          <Button key="1" type="primary" icon="plus" onClick={() => this.toogleModal(true)}>
            {i18n.t('addMaterialScreenHL')}
          </Button>,
        ]}
      />,
      <div key="22">
        {material.length > 0 &&
          <h4>{i18n.t('cargoList')}</h4>
        }
        {this.renderList(material)}

        {materialWillDelivered.length > 0 &&
          <h4 style={{marginTop: 20}}>{i18n.t('buildingSiteDelivery')}</h4>
        }
        {this.renderList(materialWillDelivered)}

        {materialProcessed.length > 0 &&
          <h4 style={{marginTop: 20}}>{i18n.t('processed')}</h4>
        }
        {this.renderList(materialProcessed)}
      </div>,
      modalVisible &&
        <MaterialModal
          key="33"
          item={item}
          edit={edit}
          project={this.props.project}
          toogleModal={this.toogleModal}
          modalVisible={modalVisible}
          tenant={this.props.tenant}
        />
    ])
  }
}