import React from 'react';
import { Button, Form, Input, Modal, message } from 'antd';
import firebase from '../constants/firebase';
import i18n from 'i18n-js';

class FolderModal extends React.Component {
  state = {
    loading: true,
  }

  componentDidMount = async () => {
    if (this.props.edit) {
      this.setState({selectedColor: this.props.item.color})
    }
  }

  addFolder = (values) => {
    const db = firebase.firestore()
    const { name } = values
    const tenant = firebase.auth().currentUser.displayName;
    let element = {
      user: db.doc(`tenants/${tenant}/users/${firebase.auth().currentUser.uid}`),
      name: name,
      createdAt: new Date(),
      type: 'folder'
    }
    return db.collection(`tenants/${tenant}/projects/${this.props.project}/gallery`).add(element)
    .then(() => {
      this.props.toogleModal(false)
    })
    .catch((error) => {
      message.error(i18n.t('oops'));
      console.error("Error updating document: ", error)
    })
  }

  updateItem = (values) => {
    const db = firebase.firestore()
    const { name } = values
    const { project, item } = this.props
    const tenant = firebase.auth().currentUser.displayName;
    const ref = db.collection(`tenants/${tenant}/projects/${project}/gallery`).doc(item.id)

    let element = {
      name: name,
    }

    return ref.update(element)
      .then(() => {
        message.info(i18n.t('companyDetailsUpdated'));
        this.props.toogleModal(false)
      })
      .catch((error) => {
        message.error(i18n.t('oops'));
        console.error("Error updating document: ", error)
      })
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // console.log('Received values of form: ', values);
        if (this.props.edit) {
          this.updateItem(values)
        } else {
          this.addFolder(values)
        }
      }
    });
  };

  render = () => {
    const { saving } = this.state;
    const { item, edit } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <Modal
        title={edit ? i18n.t('editFolderScreenHL') : i18n.t('addFolderScreenHL')}
        visible={this.props.modalVisible}
        onOk={this.handleOk}
        onCancel={() => this.props.toogleModal(false)}
        footer={null}
      >
        <Form onSubmit={this.handleSubmit} className="folder-form">
          <Form.Item label={i18n.t('folderName')}>
            {getFieldDecorator('name', {
              initialValue: (item) ? item.name : '',
              rules: [{ required: true }],
            })(
              <Input
                placeholder={i18n.t('folderName')}
              />,
            )}
          </Form.Item>
          <Form.Item>
            <Button type="primary" loading={saving} htmlType="submit" className="folder-form-button">
              {i18n.t('save')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}

export default Form.create({ name: 'folder_modal' })(FolderModal);