import React from 'react';
import { List, Skeleton, PageHeader } from 'antd';
import firebase from '../constants/firebase';
import ProjectModal from '../modal/ProjectModal';
import { Link } from "react-router-dom";
import i18n from 'i18n-js';
import UserAvatar from '../components/UserAvatar';

export default class Home extends React.Component {
  state = {
    loading: true,
    projects: [],
    tasks: [],
    favorites: [],
    modalVisible: false,
  };

  componentDidMount = async () => {
    this.loadProjects()
  }

  loadProjects = () => {
    const tenant = firebase.auth().currentUser.displayName;
    const db = firebase.firestore()

    this.unsubscribeProjects = db.collection(`tenants/${tenant}/projects`)
      .where('active', '==', false)
      .orderBy('createdAt', 'desc')
      .onSnapshot((querySnapshot) => {
        if (!querySnapshot.exists) {
          this.setState({
            loading: false
          })
        }
        let projects = []
        querySnapshot.forEach((doc) => {
          let project = {
            id: doc.id,
            name: doc.data().name,
            client: doc.data().client,
            info: doc.data().info,
            active: doc.data().active,
            color: doc.data().color
          }

          project.client.get().then((snap) => {
            project.client = snap.data()
            projects.push(project)
            this.setState({
              projects: projects,
              loading: false
            })
          })
        })
      })
  }

  toogleModal = (val, edit, item) => {
    this.setState({modalVisible: val, edit: edit, item: item})
  }

  render() {
    const { loading, projects, modalVisible, edit, item } = this.state;

    return ([
      <PageHeader 
        key="11" 
        title={i18n.t('archive')} 
      />,
      <List
        key="12" 
        className="projects-list"
        loading={loading}
        itemLayout="horizontal"
        dataSource={projects}
        renderItem={item => (
          <List.Item
            actions={[<a key="list-edit" href="#!" onClick={() => this.toogleModal(true, true, item)}>{i18n.t('edit')}</a>, <Link to={`/project/${item.id}`}>{i18n.t('open')}</Link>]}
          >
            <Skeleton avatar title={false} loading={item.loading} active>
              <List.Item.Meta
                avatar={
                  <UserAvatar size="50" name={item.name} />
                }
                title={<Link to={`/project/${item.id}`}>{item.name}</Link>}
                description={`${item.client.city} / ${item.client.street}`}
              />
            </Skeleton>
          </List.Item>
        )}
      />,
      modalVisible &&
        <ProjectModal
          key={33}
          edit={edit}
          item={item}
          modalVisible={modalVisible}
          toogleModal={this.toogleModal}
        />
    ]);
  }
}