import React from 'react';
import './App.css';
import Navigator from './navigation/Navigator'
import logo from './logo.png';
import logo_white from './logo_white.png';
import { Layout, Menu, Icon, Popover, Button } from 'antd';
import { Link, BrowserRouter as Router } from "react-router-dom";
import firebase from './constants/firebase';
import './i18n/i18n';
import i18n from 'i18n-js';
import helper from './utils/helper';
import {StripeProvider, Elements} from 'react-stripe-elements'
import UserAvatar from './components/UserAvatar';
// import Tour from 'reactour'
const { Header, Sider, Content, Footer } = Layout;
const { SubMenu } = Menu;

// const steps = [
//   {
//     selector: '[data-tour="my-first-step"]',
//     content: ({ goTo, inDOM }) => (
//       <div>
//         Lorem ipsum
//         <br />
//         {inDOM && '🎉 Look at your step!'}
//       </div>
//     ),
//     position: 'right',
//     // you could do something like:
//     // position: [160, 250],
//     style: {
//       backgroundColor: '#fff',
//     },
//     // Disable interaction for this specific step.
//     // Could be enabled passing `true`
//     // when `disableInteraction` prop is present in Tour
//     stepInteraction: false,
//   },
//   {
//     selector: '[data-tour="my-second-step"]',
//     content: ({ goTo, inDOM }) => (
//       <div>
//         Lorem ipsum
//         <br />
//         {inDOM && '🎉 Look at your step!'}
//       </div>
//     ),
//     position: 'right',
//     // you could do something like:
//     // position: [160, 250],
//     style: {
//       backgroundColor: '#fff',
//     },
//     // Disable interaction for this specific step.
//     // Could be enabled passing `true`
//     // when `disableInteraction` prop is present in Tour
//     stepInteraction: false,
//   },
// ]

export default class App extends React.Component {
  state = {
    collapsed: false,
    loading: true,
    userData: {},
    visible: false
    // showTour: true
  };

  UNSAFE_componentWillMount() {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.loadUserData(user.uid)
        this.loadCompanyDetails()
      } else {
        this.setState({
          authenticated: false,
          loading: false
        });
      }
    });
  }

  loadCompanyDetails = () => {
    const tenant = firebase.auth().currentUser.displayName
    const db = firebase.firestore()

    db.collection(`tenants`)
      .doc(tenant)
      .get()
      .then((doc) => {
        let companyData = {
          createdAt: doc.data().createdAt.toDate(),
          stripe: doc.data().stripe,
          userAmount: doc.data().userAmount
        }
        this.setState({
          companyData: companyData,
        })
      })
  }

  loadUserData = (uid) => {
    const db = firebase.firestore()
    const tenant = firebase.auth().currentUser.displayName
    let userData = {}
    db.collection(`tenants/${tenant}/users`)
      .doc(uid)
      .get()
      .then((doc) => {
        if (doc.data()) {
          userData = {
            firstname: doc.data().firstname || "",
            lastname: doc.data().lastname || "",
            avatar: doc.data().avatar || "",
            email: doc.data().email || "",
            isAdmin: doc.data().isAdmin || false,
            isActive: doc.data().isActive || false,
            phone: doc.data().phone
          }
          this.setState({
            userData: userData,
            authenticated: true,
            loading: false
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  // closeTour = () => {
  //   this.setState({showTour: false})
  // }

  hide = () => {
    this.setState({
      visible: false,
    });
  };

  handleVisibleChange = visible => {
    this.setState({ visible });
  };

  render() {
    const { authenticated, loading, collapsed, userData, companyData } = this.state

    if (loading || (authenticated && !companyData)) {
      return null
    }

    if (!authenticated) {
      return (
        <Router>
          <Layout className="layout" style={{display: 'flex', paddingTop: 100, backgroundColor: '#32a060', minHeight: '100vh'}}>
            <img src={logo_white} className="App-logo" alt="logo" style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: 250 }} />
            <h1 style={{color: '#fff', textAlign: 'center', fontSize: 40, marginTop: 20, marginBottom: 10}}>{i18n.t('welcomeBack')}</h1>
            <Content style={{ padding: '0 50px', marginTop: 20 }}>
              <div style={{ background: '#fff', padding: 24, minHeight: 280, maxWidth: 350, margin: 'auto' }}>
                <Navigator authenticated={authenticated} currentUser={userData} companyData={companyData} />
              </div>
            </Content>
            <Footer style={{ textAlign: 'center', backgroundColor: '#32a060', marginTop: 20 }}>
              <a href="https://smartwerker.app/impressum/" rel="noopener noreferrer" target="_blank" style={{paddingLeft: 10, color: '#fff'}}>{i18n.t('imprint')}</a>
              <a href="https://smartwerker.app/datenschutzerklaerung/" rel="noopener noreferrer" target="_blank" style={{paddingLeft: 10, color: '#fff'}}>{i18n.t('dataProtection')}</a>
              <a href="https://smartwerker.app/nutzungsvereinbarung/" rel="noopener noreferrer" target="_blank" style={{paddingLeft: 10, color: '#fff'}}>{i18n.t('termsOfUse')}</a>
            </Footer>
          </Layout>
        </Router>
      )
    }

    return (
      <StripeProvider apiKey="pk_live_9YXDw9WQQzzA3eILzXbvVemA00MF6EZpgZ">
      <Elements>
      <Router>
        <Layout>
          <Sider
            collapsible
            onCollapse={(collapsed, type) => {
              this.setState({collapsed})
            }}
            collapsed={this.state.collapsed}
            style={{
              height: '100vh',
              position: 'fixed',
              left: 0,
            }}
          >
            <Menu 
              theme="dark" 
              mode="inline" 
              defaultOpenKeys={['admin', 'analysis']}
            >
              <Menu.Item key="1" data-tour="my-first-step">
                <Icon type="ordered-list" />
                <span>{i18n.t('projectsScreenHL')}</span>
                <Link to="/" />
              </Menu.Item>
              <Menu.Item key="2" data-tour="my-second-step">
                <Icon type="clock-circle" />
                <span>{i18n.t('timeScreenHL')}</span>
                <Link to="/working-hours" />
              </Menu.Item>
              <Menu.Item key="3">
                <Icon type="wechat" />
                <span>{i18n.t('chatScreenHL')}</span>
                <Link to="/chat" />
              </Menu.Item>
              {userData.isAdmin &&
                <SubMenu
                  key="admin"
                  title={
                    <span>
                      <Icon type="setting" />
                      <span>{i18n.t('administration')}</span>
                    </span>
                  }
                >
                  <Menu.Item key="4">
                    <Link to="/admin/company">{i18n.t('manageCompany')}</Link>
                  </Menu.Item>
                  <Menu.Item key="5">
                    <Link to="/admin/clients">{i18n.t('manageClients')}</Link>
                  </Menu.Item>
                  <Menu.Item key="6">
                    <Link to="/admin/employees">{i18n.t('manageEmployees')}</Link>
                  </Menu.Item>
                  <Menu.Item key="64">
                    <Link to="/admin/working-types">{i18n.t('workingTypes')}</Link>
                  </Menu.Item>
                  <Menu.Item key="78">
                    <Link to="/admin/archive">{i18n.t('archive')}</Link>
                  </Menu.Item>
                  <Menu.Item key="7">
                    <Link to="/admin/abo">{i18n.t('manageAbo')}</Link>
                  </Menu.Item>
                </SubMenu>
              }
              {userData.isAdmin &&
                <SubMenu
                  key="analysis"
                  title={
                    <span>
                      <Icon type="line-chart" />
                      <span>{i18n.t('analysis')}</span>
                    </span>
                  }
                >
                  <Menu.Item key="8">
                    <Link to="/admin/time-report">{i18n.t('timeAnalysis')}</Link>
                  </Menu.Item>
                  <Menu.Item key="81">
                    <Link to="/admin/project-report">{i18n.t('folderReports')}</Link>
                  </Menu.Item>
                </SubMenu>
              }
              <SubMenu
                key="help"
                title={
                  <span>
                    <Icon type="question" />
                    <span>{i18n.t('help')}</span>
                  </span>
                }
              >
                <Menu.Item key="9">
                  {/* <Link to={{ pathname: '/help', query: { contentId: 848 } }}>{i18n.t('help')}</Link> */}
                  <a href="https://smartwerker.app/support/erste-schritte/" rel="noopener noreferrer" target="_blank">{i18n.t('help')}</a>
                </Menu.Item>
              </SubMenu>
              <SubMenu
                key="legal"
                title={
                  <span>
                    <Icon type="copyright" />
                    <span>{i18n.t('legal')}</span>
                  </span>
                }
              >
                <Menu.Item key="10">
                  <Link to={{ pathname: '/imprint', query: { contentId: 849 } }}>{i18n.t('imprint')}</Link>
                </Menu.Item>
                <Menu.Item key="11">
                  <Link to={{ pathname: '/data-protection', query: { contentId: 847 } }}>{i18n.t('dataProtection')}</Link>
                </Menu.Item>
                <Menu.Item key="12">
                  <Link to={{ pathname: '/terms-of-use', query: { contentId: 1317 } }}>{i18n.t('termsOfUse')}</Link>
                </Menu.Item>
              </SubMenu>
            </Menu>
          </Sider>
          <Layout style={{ marginLeft: (collapsed) ? 80 : 200}}>
            <Header style={{ width: (collapsed) ? 'calc(100% - 80px)' : 'calc(100% - 200px)' }}>
              <img src={logo} className="App-logo" alt="logo" style={{ marginLeft: 'auto' }} />
              
              <Popover
                content={
                  <div>
                    <div style={{marginBottom: 5}}><Link to="/profile">{i18n.t('profile')}</Link></div>
                    <a href="#!" onClick={() => helper.logOutUser(this.props.history)}>{i18n.t('logOut')}</a>
                  </div>
                }
                title={
                  <div>
                    <div>{userData.firstname} {userData.lastname}</div>
                    <small>{userData.isAdmin ? i18n.t('administrator') : ''}</small>
                  </div>
                }
                placement="bottomRight"
                trigger="click"
                visible={this.state.visible}
                onVisibleChange={this.handleVisibleChange}
              >
                <Button type="link" ghost style={{ marginLeft: 'auto', paddingRight: 0 }}>
                  <UserAvatar
                    size="30"
                    name={`${userData.firstname} ${userData.lastname}`}
                    src={userData.avatar}
                  />
                </Button>
              </Popover>
            </Header>
            <Content
              style={{
                margin: '24px 16px',
                padding: 24,
                background: '#fff',
                minHeight: 280,
                marginTop: 80 
              }}
            >
              <Navigator authenticated={authenticated} currentUser={userData} companyData={companyData} />
              {/* <Tour
                steps={steps}
                isOpen={showTour}
                onRequestClose={this.closeTour} /> */}
            </Content>
          </Layout>
        </Layout>
      </Router>
      </Elements>
      </StripeProvider>
    );
  }
}