import React from 'react';
import { Button, Form, Input, Modal, message } from 'antd';
import firebase from '../constants/firebase';
import i18n from 'i18n-js';

class ClientsModal extends React.Component {
  state = {
    loading: true,
  }

  updateClient = (values) => {
    const db = firebase.firestore()
    const tenant = firebase.auth().currentUser.displayName;
    const { item } = this.props
    const ref = db.collection(`tenants/${tenant}/clients`).doc(item.id)

    let element = {
      updatedAt: new Date(),
    }

    if (values.firstname) {
      element.firstname = values.firstname
    }
    if (values.lastname) {
      element.lastname = values.lastname
    }
    if (values.city) {
      element.city = values.city
    }
    if (values.street) {
      element.street = values.street
    }
    if (values.zip) {
      element.zip = values.zip
    }
    if (values.phone) {
      element.phone = values.phone
    }
    if (values.email) {
      element.email = values.email
    }

    return ref.update(element)
    .then(() => {
      message.info(i18n.t('companyDetailsUpdated'));
      this.props.toogleModal(false)
    })
    .catch((error) => {
      message.error(i18n.t('oops'));
      console.error("Error updating document: ", error)
    })
  }

  addClient = (values) => {
    const db = firebase.firestore()
    const tenant = firebase.auth().currentUser.displayName;
    const element = {
      firstname: values.firstname,
      lastname: values.lastname,
      createdAt: new Date(),
      createdBy: db.doc(`tenants/${tenant}/users/${firebase.auth().currentUser.uid}`),
      street: values.street || '',
      city : values.city || '',
      zip: values.zip || '',
      phone: values.phone || '',
      email: values.email || ''
    }
    return db.collection(`tenants/${tenant}/clients`).add(element)
    .then(() => {
      this.props.toogleModal(false)
    })
    .catch((error) => {
      message.error(i18n.t('oops'));
      console.error("Error updating document: ", error)
    })
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // console.log('Received values of form: ', values);
        if (this.props.edit) {
          this.updateClient(values)
        } else {
          this.addClient(values)
        }
      }
    });
  };

  render = () => {
    const { saving } = this.state;
    const { item, edit } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <Modal
        title={edit ? i18n.t('editClientsScreenHL') : i18n.t('addClientScreenHL')}
        visible={this.props.modalVisible}
        onOk={this.handleOk}
        onCancel={() => this.props.toogleModal(false)}
        footer={null}
      >
        <Form onSubmit={this.handleSubmit} className="material-form">
          <Form.Item label={i18n.t('firstname')}>
            {getFieldDecorator('firstname', {
              initialValue: (item) ? item.firstname : '',
              rules: [{ required: true }],
            })(
              <Input
                placeholder={i18n.t('firstname')}
              />,
            )}
          </Form.Item>
          <Form.Item label={i18n.t('lastname')}>
            {getFieldDecorator('lastname', {
              initialValue: (item) ? item.lastname : '',
              rules: [{ required: true }],
            })(
              <Input 
                placeholder={i18n.t('lastname')}
              />,
            )}
          </Form.Item>
          <Form.Item label={i18n.t('phone')}>
            {getFieldDecorator('phone', {
              initialValue: (item) ? item.phone : '',
              rules: [{ required: false }],
            })(
              <Input 
                placeholder={i18n.t('phone')}
              />,
            )}
          </Form.Item>
          <Form.Item label={i18n.t('email')}>
            {getFieldDecorator('email', {
              initialValue: (item) ? item.email : '',
              rules: [{ required: false }],
            })(
              <Input 
                placeholder={i18n.t('email')}
              />,
            )}
          </Form.Item>
          <Form.Item label={i18n.t('street')}>
            {getFieldDecorator('street', {
              initialValue: (item) ? item.street : '',
              rules: [{ required: false }],
            })(
              <Input 
                placeholder={i18n.t('street')}
              />,
            )}
          </Form.Item>
          <Form.Item label={i18n.t('zip')}>
            {getFieldDecorator('zip', {
              initialValue: (item) ? item.zip : '',
              rules: [{ required: false }],
            })(
              <Input 
                placeholder={i18n.t('zip')}
              />,
            )}
          </Form.Item>
          <Form.Item label={i18n.t('city')}>
            {getFieldDecorator('city', {
              initialValue: (item) ? item.city : '',
              rules: [{ required: false }],
            })(
              <Input 
                placeholder={i18n.t('city')}
              />,
            )}
          </Form.Item>

          <Form.Item>
            <Button type="primary" loading={saving} htmlType="submit" className="clients-form-button">
              {i18n.t('save')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}

export default Form.create({ name: 'clients_modal' })(ClientsModal);