import React from 'react';
import { Spin, Avatar, Button, Icon, Upload, Modal, message, Popconfirm, PageHeader, List } from 'antd';
import firebase from '../../constants/firebase';
import i18n from 'i18n-js';
import mime from 'mime-types';
import { Player } from 'video-react';
import moment from 'moment';

export default class FolderDocuments extends React.Component {
  state = {
    loading: true,
    documents: [],
    modalVisible: false,
    modalDoc: '',
    projectDetails: {},
    allowUpload: false,
    list: true
  }

  componentDidUpdate = (data) => {
    const { folderId, projectId } = data
    if (folderId !== this.state.folderId) {
      this.setState({
        folderId: folderId, 
        projectId: projectId,
        documents: [],
        list: true
      }, () => {
        this.documentRouter()
        this.loadProjectDetail()
        this.getFolderNameFromId()
      })
    }
  }

  loadProjectDetail = () => {
    const db = firebase.firestore()
    const tenant = firebase.auth().currentUser.displayName;
    const { projectId } = this.state;

    if (projectId) {
      db.collection(`tenants/${tenant}/projects`)
        .doc(projectId)
        .onSnapshot((doc) => {
          let project = {
            id: doc.id,
            name: doc.data().name,
            client: doc.data().client,
            info: doc.data().info,
            createdAt: doc.data().createdAt,
            active: doc.data().active,
            notes: doc.data().notes || '',
            color: doc.data().color || '',
          }
          project.client.get().then((snap) => {
            project.client = snap.data()
            project.clientId = snap.id
            this.setState({
              project: project,
              loading: false
            })
          })
        })
    }
  }

  getFolderNameFromId = () => {
    let { folderId } = this.state
    if (folderId.length === 1) {
      folderId = Number(folderId)
    }
    let folderName = ''
    let allowUpload = false
    let allowDeletion = false
    switch (folderId) {
      case 1:
        folderName = i18n.t('folderPlan')
        allowUpload = true
        allowDeletion = true
        break
      case 2:
        folderName = i18n.t('folderInProgress')
        allowUpload = true
        allowDeletion = true
        break
      case 3:
        folderName = i18n.t('folderFinal')
        allowUpload = true
        allowDeletion = true
        break
      case 5:
        folderName = i18n.t('folderDeliveryNote')
        allowUpload = true
        allowDeletion = true
        break
      case 4:
        folderName = i18n.t('folderReports')
        break
      case 6:
        folderName = i18n.t('folderChat')
        break
      default:
        allowUpload = true
        allowUpload = true
        allowDeletion = true
        break
    }
    return this.setState({allowUpload: allowUpload, folderName: folderName, allowDeletion: allowDeletion})
  }

  documentRouter = () => {
    let { folderId } = this.state
    if (folderId.length === 1) {
      folderId = Number(folderId)
    }
    switch (folderId) {
      case 6:
        return this.loadDocumentsFromChat()
      case 4:
        return this.loadReportDocuments()
      default:
        return this.loadDocumentsFromGallery()
    }
  }

  loadDocumentsFromChat = () => {
    const { projectId } = this.state
    const tenant = firebase.auth().currentUser.displayName;
    const db = firebase.firestore()
    db.collection(`tenants/${tenant}/projects/${projectId}/comments`)
      .orderBy('createdAt')
      .onSnapshot((doc) => {
      let m = []
      doc.docs.forEach((document) => {
        if (document.data().image || document.data().video) {
          let doc = {
            downloadURL: document.data().image,
            createdAt: document.data().createdAt.toDate(),
            mimeType: document.data().mimeType,
            folderId: document.data().folderId,
            duration: document.data().duration,
            video: document.data().video,
            id: document.id,
            createdBy: document.data().createdBy || '',
            fileName: document.data().fileName || '',
            reportId: document.data().reportId,
            thumb_128: document.data().thumb_128,
            thumb_500: document.data().thumb_500,
            thumb_1024: document.data().thumb_1024
          }
  
          if (doc.createdBy) {
            doc.createdBy.get().then((snap) => {
              doc.createdBy = snap.data().firstname + ' ' + snap.data().lastname
              m.push(doc)
              this.setState({documents: m})
            })
          } else {
            m.push({doc})
            this.setState({documents: m})
          }
        }
        this.setState({documents: m, loading: false})
      })
    })
  }

  loadReportDocuments = () => {
    const { projectId } = this.state
    const tenant = firebase.auth().currentUser.displayName;
    const db = firebase.firestore()
    db.collection(`tenants/${tenant}/reports`)
      .where('projectId', '==', projectId)
      .orderBy('createdAt')
      .onSnapshot((doc) => {
      let m = []
      doc.docs.forEach((document) => {
        let doc = {
          downloadURL: document.data().downloadURL,
          createdAt: document.data().createdAt.toDate(),
          mimeType: document.data().mimeType,
          folderId: 4,
          id: document.id,
          createdBy: document.data().user || '',
          fileName: document.data().fileName || '',
          reportId: document.id,
        }

        if (doc.createdBy) {
          doc.createdBy.get().then((snap) => {
            doc.createdBy = snap.data().firstname + ' ' + snap.data().lastname
            m.push(doc)
            this.setState({documents: m})
          })
        } else {
          m.push({doc})
          this.setState({documents: m})
        }
      })
    })
  }

  loadDocumentsFromGallery = () => {
    let { folderId, projectId } = this.state
    if (folderId.length === 1) {
      folderId = Number(folderId)
    }
    const tenant = firebase.auth().currentUser.displayName;
    const db = firebase.firestore()
    db.collection(`tenants/${tenant}/projects/${projectId}/gallery`)
      .where("folderId", "==", folderId)
      .orderBy('createdAt')
      .onSnapshot((doc) => {
        let m = []
        doc.docs.forEach((document) => {
          let doc = {
            downloadURL: document.data().downloadURL,
            createdAt: document.data().createdAt.toDate(),
            mimeType: document.data().mimeType,
            folderId: document.data().folderId,
            duration: document.data().duration,
            video: document.data().video,
            id: document.id,
            createdBy: document.data().createdBy || '',
            fileName: document.data().fileName || '',
            reportId: document.data().reportId,
            thumb_128: document.data().thumb_128,
            thumb_500: document.data().thumb_500,
            thumb_1024: document.data().thumb_1024
          }
  
          if (doc.createdBy) {
            doc.createdBy.get().then((snap) => {
              doc.createdBy = snap.data().firstname + ' ' + snap.data().lastname
              m.push(doc)
              this.setState({documents: m})
            })
          } else {
            m.push({doc})
            this.setState({documents: m})
          }
        })
        this.setState({documents: m, loading: false})
      })
  }

  renderDelete = (item) => {
    const { allowDeletion } = this.state
    return (
      allowDeletion && 
      <Popconfirm
        title={i18n.t('areYouSure')}
        onConfirm={() => this.deleteImage(item)}
        okText={i18n.t('yes')}
        cancelText={i18n.t('no')}
      >
        <a href="#!delete" key="list-loadmore-edit">{i18n.t('delete')}</a>
      </Popconfirm>
    )
  }

  sendByEmail = (item) => {
    const { project } = this.state
    const receiver = project && project.client && project.client.email || ''
    return (
      <a href={`mailto:${receiver}?body=${item.downloadURL}`} key="send-email">{i18n.t('share')}</a>
    )
  }

  renderDocument = (item) => {
    if (item.mimeType && item.mimeType.indexOf('pdf') >= 0) {
      if (item.downloadURL && item.downloadURL.length) {
        return (
          <List.Item 
            actions={[this.renderDelete(item), this.sendByEmail(item)]}
          >
            <List.Item.Meta
              avatar={<a rel="noopener noreferrer" href={item.downloadURL} target="_blank"><Avatar icon="file-pdf" /></a>}
              title={<a rel="noopener noreferrer" href={item.downloadURL} target="_blank">{item.fileName}</a>}
              description={`${i18n.t('on')} ${moment(item.createdAt).format('DD.MM.YYYY')} ${i18n.t('at')} ${moment(item.createdAt).format('HH:mm')} ${i18n.t('by')} ${item.createdBy}`}
            />
          </List.Item>
        )
      } else {
        return (
          <List.Item>
            <List.Item.Meta
              avatar={<Avatar icon="file-pdf" />}
              title={`${item.fileName} (${i18n.t('isCreating')})`}
              description={`${i18n.t('on')} ${moment(item.createdAt).format('DD.MM.YYYY')} ${i18n.t('at')} ${moment(item.createdAt).format('HH:mm')} ${i18n.t('by')} ${item.createdBy}`}
            />
          </List.Item>
        )
      }
      
    }

    if (item.mimeType && item.mimeType.indexOf('video') >= 0) {
      return (
        <List.Item 
          actions={[this.renderDelete(item), this.sendByEmail(item)]}
        >
          <List.Item.Meta
            avatar={<Avatar icon="video-camera" onClick={() => this.setModal(item)} />}
            title={<a href="#!video" onClick={() => this.setModal(item)}>{item.fileName}</a>}
            description={`${i18n.t('on')} ${moment(item.createdAt).format('DD.MM.YYYY')} ${i18n.t('at')} ${moment(item.createdAt).format('HH:mm')} ${i18n.t('by')} ${item.createdBy}`}
          />
        </List.Item>
      )
    }

    return (
      <List.Item 
        actions={[this.renderDelete(item), this.sendByEmail(item)]}
      >
        <List.Item.Meta
          avatar={<Avatar icon="file-image" onClick={() => this.setModal(item)} />}
          title={<a href="#!image" onClick={() => this.setModal(item)}>{item.fileName}</a>}
          description={`${i18n.t('on')} ${moment(item.createdAt).format('DD.MM.YYYY')} ${i18n.t('at')} ${moment(item.createdAt).format('HH:mm')} ${i18n.t('clock')} ${i18n.t('by')} ${item.createdBy}`}
        />
      </List.Item>
    )
  }

  uploadDocument = async(uri) => {
    const { folderId, projectId } = this.state
    const tenant = firebase.auth().currentUser.displayName;
    const fileType = uri.originFileObj.name.substr(uri.originFileObj.name.lastIndexOf('.') + 1);
    const mimeType = mime.lookup(fileType)
    const db = firebase.firestore()
    this.setState({uploading: true})
    const galleryRef = db.collection(`tenants/${tenant}/projects/${projectId}/gallery`).doc()
    const metadata = {
      contentType: mimeType,
      customMetadata: {
        folderId: folderId,
        projectId: projectId,
        galleryId: galleryRef.id,
        tenant: tenant
      }
    }
    const name = uri.originFileObj.name.replace(/\s/g,'-')
    const ref = firebase.storage().ref().child(`tenants/${tenant}/projects/${projectId}/gallery/${folderId}/${name}`)
    return ref.put(uri.originFileObj, metadata).then((snapshot) => {
      snapshot.ref.getDownloadURL().then((downloadURL) => {
        const document = {
          downloadURL: downloadURL,
          createdAt: new Date(),
          createdBy: db.doc(`tenants/${tenant}/users/${firebase.auth().currentUser.uid}`),
          folderId: folderId,
          mimeType: mimeType,
          fileName: name
        }
        this.documentRouter()
        this.setState({uploading: false})
        return galleryRef.set(document)
      })
    })
  }

  getStorageLocationForURL = (url) => {
    let storageLocation = url.split('/o/')[1]
    storageLocation = storageLocation.split('?')[0]
    storageLocation = storageLocation.split('projects%2F')[1]
    storageLocation = storageLocation.replace(/%2F/g, '/')
    return storageLocation 
  }

  deleteImage = (doc) => {
    const tenant = firebase.auth().currentUser.displayName;
    const { projectId } = this.state
    const db = firebase.firestore()
    const storagePath = firebase.storage().ref()
    storagePath.child(`tenants/${tenant}/projects/${this.getStorageLocationForURL(doc.downloadURL)}`).delete()
    .then(() => {
      db.collection(`tenants/${tenant}/projects/${projectId}/gallery`).doc(doc.id).delete()
      .then(function() {
        message.info(i18n.t('hasBeenDeleted'));
      })
      .catch(function(error) {
        message.error(i18n.t('oops'));
        // console.error("Error removing document: ", error)
      })
    })
    .catch(function(error) {
      message.error(i18n.t('oops'));
      // console.error("Error removing document: ", error)
    })
  }

  closeModal = () => this.setState({ modalVisible: false });

  setModal = async file => {
    this.setState({
      modalDoc: file,
      modalIsVideo: file.mimeType.indexOf('video') >= 0,
      modalVisible: true,
    });
  }

  toggleView = () => {
    this.setState({list: !this.state.list})
  }

  renderThumbItem = (item) => {
    return (
      <a key={item.id} href="#!image" onClick={() => this.setModal(item)} className="galleryThumbItem">
        <img alt="" style={{ width: '100%' }} src={item.thumb_500 ? item.thumb_500 : item.downloadURL} />
      </a>
    )
  }

  render() {
    const { loading, list, documents, modalVisible, modalDoc, modalIsVideo, allowUpload, uploading, folderName } = this.state

    const upload = (file) => {
      this.uploadDocument(file)
    }

    const props = {
      name: 'file',
      action: 'upload',
      headers: {
        authorization: 'authorization-text',
      },
      onChange(info) {
        if (info.file.status !== 'uploading') {
          // console.log(info.file, info.fileList);
          upload(info.file)
        }
        if (info.file.status === 'done') {
          // console.log(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === 'error') {
          // console.log(`${info.file.name} file upload failed.`);
        }
      },
    };

    if (loading) {
      return (
        <Spin />
      )
    }

    return ([
      <PageHeader 
        key="11" 
        title={folderName} 
        extra={
          <div style={{display: 'flex'}}>
            <Button key="2" type="primary" onClick={() => this.toggleView()}>
              {list &&
                <Icon type="table" />
              }
              {!list &&
                <Icon type="ordered-list" />
              }
            </Button>
              &nbsp;&nbsp;
            {allowUpload && 
            <div>
              <Upload key="1" {...props}>
                <Button type="primary" loading={uploading}>
                  <Icon type="upload" /> {i18n.t('chooseDocument')}
                </Button>
              </Upload>
            </div>}
          </div>
        }
      />,
      <div key={22} className="documentList">
        {list &&
          <List
            itemLayout="horizontal"
            dataSource={documents}
            renderItem={item => (
              this.renderDocument(item)
            )}
          />
        }
        {!list &&
          <div style={{display: 'flex', flexWrap: 'wrap'}}>
            {documents.map(item => this.renderThumbItem(item))}
          </div>
        }
        {/* <Upload key="1" {...props}>
          <Card
            className="addDocument"
            hoverable
            cover={
              <Icon type="upload" style={{fontSize: 30}}/>
            }
          >
            <div>{i18n.t('chooseDocument')}</div>
          </Card>
        </Upload> */}
      </div>,
      <Modal className="galleryModal" key="33" visible={modalVisible} footer={null} onCancel={this.closeModal}>
        {modalIsVideo &&
          <div>
            <Player
              playsInline
              src={modalDoc.downloadURL}
            />
            <p>{`${i18n.t('on')} ${moment(modalDoc.createdAt).format('DD.MM.YYYY')} ${i18n.t('at')} ${moment(modalDoc.createdAt).format('HH:mm')} ${i18n.t('clock')} ${i18n.t('by')} ${modalDoc.createdBy}`}</p>
          </div>
        }
        {!modalIsVideo &&
          <div>
            <img alt="" style={{ width: '100%' }} src={modalDoc.thumb_1024 ? modalDoc.thumb_1024 : modalDoc.downloadURL} />
            <p>{`${i18n.t('on')} ${moment(modalDoc.createdAt).format('DD.MM.YYYY')} ${i18n.t('at')} ${moment(modalDoc.createdAt).format('HH:mm')} ${i18n.t('clock')} ${i18n.t('by')} ${modalDoc.createdBy}`}</p>
          </div>
        }
      </Modal>
    ]);
  }
}