import React from 'react';
import { PageHeader, Icon, Spin, Popconfirm, message, Row, Col, Statistic, Typography, Alert } from 'antd';
import firebase from '../constants/firebase';
import i18n from 'i18n-js';
import helper from '../utils/helper';
import moment from 'moment';
import PaymentModal from '../modal/PaymentModal';
import payment_visa from '../payment_visa.png';
import payment_discover from '../payment_discover.png';
import payment_americanexpress from '../payment_americanexpress.png';
import payment_mastercard from '../payment_mastercard.png';
const { Title } = Typography;

export default class Abo extends React.Component {
  state = {
    active: [],
    inActive: [],
    loading: true,
    companyData: {},
    plan: {
      "id": "plan_G5olhDiZtRm7l3",
      "object": "plan",
      "active": true,
      "aggregate_usage": null,
      "amount": null,
      "amount_decimal": null,
      "billing_scheme": "tiered",
      "created": 1572526285,
      "currency": "eur",
      "interval": "month",
      "interval_count": 1,
      "livemode": true,
      "metadata": {},
      "nickname": "Smartwerker Standard",
      "product": "prod_G5olz82kven5Qm",
      "tiers": [
      {
      "flat_amount": null,
      "flat_amount_decimal": null,
      "unit_amount": 0,
      "unit_amount_decimal": "0",
      "up_to": 1
      },
      {
      "flat_amount": null,
      "flat_amount_decimal": null,
      "unit_amount": 900,
      "unit_amount_decimal": "900",
      "up_to": 5
      },
      {
      "flat_amount": null,
      "flat_amount_decimal": null,
      "unit_amount": 800,
      "unit_amount_decimal": "800",
      "up_to": 30
      },
      {
      "flat_amount": null,
      "flat_amount_decimal": null,
      "unit_amount": 700,
      "unit_amount_decimal": "700",
      "up_to": null
      }
      ],
      "tiers_mode": "graduated",
      "transform_usage": null,
      "trial_period_days": 30,
      "usage_type": "licensed"
      }
  }

  componentDidMount = () => {
    // this.fetchStripePlan()
    this.loadEmployees()
    this.loadCompanyDetails()
  }

  loadCompanyDetails = () => {
    const tenant = firebase.auth().currentUser.displayName;
    const db = firebase.firestore()

    db.collection(`tenants`)
      .doc(tenant)
      .onSnapshot((doc) => {
        let companyData = {
          id: doc.id,
          createdAt: doc.data().createdAt.toDate(),
          stripe: doc.data().stripe,
          phone: doc.data().phone,
          street: doc.data().street,
          zip: doc.data().zip,
          company: doc.data().company,
          user: doc.data().user
        }
        companyData.user.get().then((snap) => {
          companyData.user = snap.data()
          this.setState({
            companyData: companyData,
            loading: false
          })
        })
      })
  }

  loadEmployees = () => {
    const tenant = firebase.auth().currentUser.displayName;
    const db = firebase.firestore()

    db.collection(`tenants/${tenant}/users`)
      .onSnapshot((querySnapshot) => {
        let activeUser = []
        let inActiveUser = []
        querySnapshot.forEach((doc) => {
          let user = {
            id: doc.id,
            isAdmin: doc.data().isAdmin,
            isActive: doc.data().isActive,
          }
          if (user.isActive) {
            activeUser.push(user)
          } else {
            inActiveUser.push(user)
          }
          this.setState({
            active: activeUser,
            inActive: inActiveUser,
          })
        })
      })
  }

  fetchStripePlan = () => {
    fetch(`https://smartwerker-payment.cuisines.io/plans`)
      .then(response => response.json())
      .then((res) => {
        const plan = res.data[0]
        this.setState({loading: false, plan: plan})
      })
      .catch(error => {
        this.setState({loading: false})
        // console.log(error)
      })
  }

  deleteTenant = () => {
    const db = firebase.firestore()
    const tenant = firebase.auth().currentUser.displayName;
    const ref = db.collection(`tenants`).doc(tenant)
    ref.update({toDelete: true})
    .then(function() {
      helper.logOutUser(this.props.history);
      message.info(i18n.t('hasBeenDeleted'));
    })
    .catch(function(error) {
      message.error(i18n.t('oops'));
      // console.error("Error removing document: ", error)
    })
  }

  getMonthlyCosts = () => {
    const { plan, active } = this.state
    let selectedPlan = plan.tiers.filter(e => active.length <= e.up_to)
    if (!selectedPlan.length) {
      selectedPlan = plan.tiers.filter(e => !e.up_to)
    }
    return ((selectedPlan[0].unit_amount / 100) * (active.length - 1))
  }

  getUsedTrialDays = () => {
    const { companyData } = this.state
    const end = moment(new Date())
    let trialDuration = moment.duration(end.diff(companyData.createdAt)).asDays()
    if (trialDuration < 0) {
      trialDuration = 0
    }
    return Number(trialDuration).toFixed(0)
  }

  toogleModal = (val, edit) => {
    this.setState({
      modalVisible: val,
      edit: edit
    })
  }

  renderPayment = () => {
    const { companyData } = this.state

    if (!companyData.stripe) {
      return (
        <div>
          <Alert message={i18n.t('paymentInfoMissing')} type="error" />
          <br />
          <a href="#!" onClick={() => this.toogleModal(true)}>{i18n.t('addCreditCard')}</a>
        </div>
      )
    }

    return (
      <div>
        <div>
          {companyData.stripe.creditCard.brand === 'Visa' &&
            <img width="30" src={payment_visa} alt="" />
          }
          {companyData.stripe.creditCard.brand === 'Amex' &&
            <img width="30" src={payment_americanexpress} alt="" />
          }
          {companyData.stripe.creditCard.brand === 'Master Card' &&
            <img width="30" src={payment_mastercard} alt="" />
          }
          {companyData.stripe.creditCard.brand === 'Discover' &&
            <img width="30" src={payment_discover} alt="" />
          }
          <span> •••• {companyData.stripe.creditCard.last4}</span>
          <br/>
          <span>{i18n.t('expires')} {companyData.stripe.creditCard.exp_month}/{companyData.stripe.creditCard.exp_year}</span>
        </div>
        <br />
        <a href="#!" onClick={() => this.toogleModal(true, true)}>{i18n.t('updateCreditCard')}</a>
      </div>
    )
  }

  renderDetails = () => {
    const { inActive, active, companyData } = this.state

    if (active.length === 1) {
      return (
        <Alert message={i18n.t('singleUserMessage')} type="info" />
      )
    }

    if (active.length > 1 || companyData.stripe) {
      return (
        <div>
          {(this.getUsedTrialDays() <= 30) &&
            <div>
              <Alert message={i18n.t('trialAdminMessage', { days: (30 - this.getUsedTrialDays()) })} type="info" />
              <br/>
            </div>
          }
          {(this.getUsedTrialDays() > 30 && !companyData.stripe) &&
            <div>
              <Alert message={i18n.t('trialExpiredAdminMessage')} type="error" />
              <br/>
            </div>
          }
          <Row gutter={16}>
            <Col span={12}>
              <Statistic title={i18n.t('costsPerMonth')} value={this.getMonthlyCosts()} suffix="€" />
            </Col>
          </Row>
          <br />
          <br />
          <br />
          <Row gutter={16}>
            <Col span={12}>
              <Statistic title={i18n.t('activeUser')} value={active.length} />
            </Col>
            <Col span={12}>
              <Statistic title={i18n.t('inActiveUser')} value={inActive.length} />
            </Col>
          </Row>
          <br />
          <br />
          <Title level={4}>{i18n.t('payment')}</Title>
          {this.renderPayment()}
        </div>
      )
    }
  }

  render() {
    const { loading, modalVisible, plan, companyData, active, edit } = this.state

    if (loading) {
      return (<Spin/>)
    }

    return ([
      <PageHeader 
        key="11" 
        title={i18n.t('manageAbo')}
      />,
      <div key="21">
        {companyData.stripe && companyData.stripe.creditCard &&
          this.renderDetails()
        }
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <Popconfirm
          key="22" 
          className="danger"
          title={i18n.t('areYouSure')}
          onConfirm={this.deleteTenant}
          okText={i18n.t('yes')}
          cancelText={i18n.t('no')}
          icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
        >
          <a href="#!">{i18n.t('deleteTenant')}</a>
        </Popconfirm>
      </div>,
      modalVisible &&
        <PaymentModal
          key="33"
          toogleModal={this.toogleModal}
          modalVisible={modalVisible}
          companyData={companyData}
          plan={plan}
          active={active}
          edit={edit}
        />
    ])
  }
}