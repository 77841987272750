import React from 'react';
import { PageHeader, Button, Table, Spin, Popconfirm } from 'antd';
import firebase from '../constants/firebase';
import ClientsModal from '../modal/ClientsModal';
import i18n from 'i18n-js';

export default class Clients extends React.Component {
  state = {
    clients: [],
    loading: true
  }

  componentDidMount = () => {
    this.loadClients()
  }

  loadClients = () => {
    const tenant = firebase.auth().currentUser.displayName;
    const db = firebase.firestore()

    db.collection(`tenants/${tenant}/clients`)
      .orderBy('lastname')
      .onSnapshot((querySnapshot) => {
        if (!querySnapshot.exists) {
          this.setState({
            loading: false
          })
        }
        let clients = []
        querySnapshot.forEach((doc) => {
          let client = {
            id: doc.id,
            key: doc.id,
            firstname: doc.data().firstname,
            lastname: doc.data().lastname,
            phone: doc.data().phone,
            street: doc.data().street,
            zip: doc.data().zip,
            city: doc.data().city,
            active: (doc.data().active !== undefined) ? doc.data().active : true
          }
          clients.push(client)
          this.setState({
            clients: clients,
            loading: false
          })
        })
      })
  }

  toogleModal = (val, edit, item) => {
    this.setState({
      modalVisible: val,
      edit: edit,
      item: item
    })
  }

  removeItem = (item) => {
    const db = firebase.firestore()
    const tenant = firebase.auth().currentUser.displayName;
    const ref = db.collection(`tenants/${tenant}/clients`).doc(item.id)

    return ref.update({
      active: !item.active
    })
  }

  render() {
    const { clients, loading, modalVisible, edit, item } = this.state

    if (loading) {
      return (<Spin/>)
    }

    const activeClients = clients.filter(item => item.active === true)

    const columns = [
      {
        title: i18n.t('firstname'),
        dataIndex: 'firstname',
        key: 'firstname',
      },
      {
        title: i18n.t('lastname'),
        dataIndex: 'lastname',
        key: 'lastname',
      },
      {
        title: i18n.t('city'),
        dataIndex: 'city',
        key: 'city',
      },
      {
        title: '',
        dataIndex: 'operation',
        render: (text, record) =>
        clients.length >= 1 ? ([
            <a key="1" href="#!" onClick={() => this.toogleModal(true, true, record)}>{i18n.t('edit')}</a>,
            <span key="2" style={{color: '#e8e8e8'}}> | </span>,
            <Popconfirm
              key="3"
              title={i18n.t('areYouSure')}
              onConfirm={() => this.removeItem(record)}
              okText={i18n.t('yes')}
              cancelText={i18n.t('no')}
            >
              <a href="#!">{i18n.t('delete')}</a>
            </Popconfirm>
        ]) : null,
      },
    ];

    return ([
      <PageHeader 
        key="11" 
        title={i18n.t('manageClients')}
        extra={[
          <Button key="1" type="primary" icon="user-add" onClick={() => this.toogleModal(true)}>
            {i18n.t('addClientScreenHL')}
          </Button>,
        ]}
      />,
      <Table key="22" columns={columns} dataSource={activeClients} />,
      modalVisible &&
        <ClientsModal
          key="33"
          item={item}
          edit={edit}
          toogleModal={this.toogleModal}
          modalVisible={modalVisible}
        />
      
    ])
  }
}