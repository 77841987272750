import React from 'react';
import { PageHeader, Timeline, Row, Col, message } from 'antd';
import firebase from '../../constants/firebase';
import i18n from 'i18n-js';
import GoogleMapReact from 'google-map-react';
import moment from 'moment';
import TextArea from 'antd/lib/input/TextArea';
import Title from 'antd/lib/typography/Title';
import { key } from '../../constants/google';
import { sortBy } from 'lodash'

export default class Informations extends React.Component {
  state = {
    loading: true,
    notes: 'loading',
    messages: []
  }

  componentDidMount = () => {
    this.setState({ info: this.props.project.info })
    this.loadMessages()
    this.getCoordinatesForAddress()
    this.loadNotes()
  }

  loadNotes = () => {
    const db = firebase.firestore()
    const tenant = firebase.auth().currentUser.displayName;
    const { project } = this.props
    db.collection(`tenants/${tenant}/projects`)
      .doc(project.id)
      .get()
      .then((doc) => {
        this.setState({
          notes: doc.data().notes || '',
          loading: false
        })
    })
  }

  loadMessages = () => {
    const db = firebase.firestore()
    const tenant = firebase.auth().currentUser.displayName;
    const { project } = this.props
    db.collection(`tenants/${tenant}/projects/${project.id}/messages`)
      .orderBy('createdAt', 'desc')
      .get()
      .then((doc) => {
      let m = []
      doc.docs.forEach((item) => {
        let message = {
          message: item.data().message,
          createdAt: item.data().createdAt,
          uid: item.id,
          type: item.data().type,
          user: item.data().user,
          data: item.data().data
        }
        if (item.data().user._id) {
          message.user = item.data().user.name
          m.push(message)
          this.setState({
            messages: m,
          })
        } else {
          message.user.get().then((snap) => {
            message.user = snap.data().firstname + ' ' + snap.data().lastname
            m.push(message)
            this.setState({
              messages: m,
            })
          })
        }
        
      })
    })
  }

  openMap = () => {
    const { project } = this.props
    const url = `https://maps.google.com/?q=${project.client.street}, ${project.client.zip} ${project.client.city}`
    
    return window.open(url, '_blank');
  }

  getCoordinatesForAddress = () => {
    const { project } = this.props

    if (!project.client.street) {
      return false
    }

    fetch(`https://maps.google.com/maps/api/geocode/json?key=${key}&address=${project.client.street}, ${project.client.zip} ${project.client.city}`)
      .then(response => response.json())
      .then(response => {
        this.setState({region: response.results[0].geometry.location, loading: false})
      })
  }

  renderClientInfo = () => {
    const { project } = this.props

    return (
      <div>
        <strong>{i18n.t('projectDesc')}:</strong><br/>
        {project.info}
        <br/><br/>
        <strong>{i18n.t('client')}: </strong>
        {project.client.firstname} {project.client.lastname}
        <br/>
        <strong>{i18n.t('phone')}: </strong>
        {project.client.phone}
        <br/>
        <strong>{i18n.t('address')}: </strong>
        {project.client.street}, {project.client.zip} {project.client.city}
      </div>
    )
  }

  renderMap = () => {
    const { region } = this.state

    if(!region) {
      return null
    }

    return (
      <div>
        <Title level={3}>{i18n.t('route')}</Title>
        <div style={{ height: '150px', width: '100%' }}>
          <GoogleMapReact
            onClick={() => this.openMap()}
            bootstrapURLKeys={{ key: key }}
            defaultCenter={{
              lat: region.lat,
              lng: region.lng
            }}
            defaultZoom={13}
          >
          </GoogleMapReact>
        </div>
      </div>
    )
  }

  renderMessages = () => {
    const { messages } = this.state

    const sortedMessages = sortBy(messages, [function(o) { return o.createdAt.toDate(); }]).reverse()

    return (
      <div>
        <Title level={3}>{i18n.t('history')}</Title>
        <Timeline style={{maxHeight: 400, overflow: 'auto'}}>
          {sortedMessages.map((item, i) => ([
            <Timeline.Item>{item.user} {i18n.t(item.message, {name: item.data})} - {moment(item.createdAt.toDate()).fromNow()}</Timeline.Item>,
          ]))}
        </Timeline>
      </div>
    )
  }

  updateNotes = (notes) => {
    this.setState({ notes: notes.target.value })
  }

  saveNotes = () => {
    const { notes } = this.state
    const { project } = this.props
    const tenant = firebase.auth().currentUser.displayName;
    const db = firebase.firestore()
    const newData = {
      notes: notes
    }
    return db.collection(`tenants/${tenant}/projects`).doc(project.id).update(newData)
    .then(() => {
      message.info(i18n.t('notesUpdated'));
    })
  }

  renderNotes = () => {
    const { notes } = this.state

    return (
      <div>
        <Title level={3}>{i18n.t('notes')}</Title>
        <TextArea 
          rows={4} 
          value={notes} 
          onBlur={this.saveNotes} 
          onChange={notes => this.updateNotes(notes)} 
        />
      </div>
    )
  }

  render() {

    return ([
      <PageHeader 
        key="11" 
        title={i18n.t('projectInfo')}
      />,
      <Row key="22">
        <Col span={16} style={{paddingRight: 30}}>
          <Row>
            <Col span={24}>
              {this.renderClientInfo()}
            </Col>
            <Col span={24} style={{paddingTop: 30}}>
              {this.renderMap()}
            </Col>
            <Col span={24} style={{paddingTop: 30}}>
              {this.renderNotes()}
            </Col>
          </Row>
        </Col>
        <Col span={8}>
          {this.renderMessages()}
        </Col>
      </Row>
    ])
  }
}