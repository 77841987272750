import React from 'react';
import { PageHeader, Spin, Avatar, Form, Button, Input, Upload, Icon, message } from 'antd';
import firebase from '../constants/firebase';
import i18n from 'i18n-js';
import helper from '../utils/helper'
import mime from 'mime-types';

class Company extends React.Component {
  state = {
    loading: true,
    uploading: false,
    companyData: {}
  }

  componentDidMount = async () => {
    this.loadCompanyDetails()
  }

  loadCompanyDetails = () => {
    const tenant = firebase.auth().currentUser.displayName;
    const db = firebase.firestore()

    db.collection(`tenants`)
      .doc(tenant)
      .onSnapshot((doc) => {
        let companyData = {
          id: doc.id,
          displayName: doc.data().displayName,
          logo: doc.data().logo || '',
          phone: doc.data().phone,
          street: doc.data().street,
          zip: doc.data().zip,
          city: doc.data().city,
          ust: doc.data().ust
        }
        this.setState({
          companyData: companyData,
          loading: false
        })
      })
  }

  updateCompanyDetails = (values) => {
    const db = firebase.firestore()
    const tenant = firebase.auth().currentUser.displayName;
    const { logo } = this.state
    const ref = db.collection(`tenants`).doc(tenant)

    let element = {
      updatedAt: new Date(),
    }

    if (values && values.displayName) {
      element.displayName = values.displayName
    }
    if (logo) {
      element.logo = logo
    }
    if (values && values.city) {
      element.city = values.city
    }
    if (values && values.street) {
      element.street = values.street
    }
    if (values && values.zip) {
      element.zip = values.zip
    }
    if (values && values.phone) {
      element.phone = values.phone
    }
    if (values && values.ust) {
      element.ust = values.ust
    }

    return ref.update(element)
    .then(() => {
      message.info(i18n.t('companyDetailsUpdated'));
    })
    .catch((error) => {
      message.error(i18n.t('oops'));
      console.error("Error updating document: ", error)
    })
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // console.log('Received values of form: ', values);
        this.updateCompanyDetails(values)
      }
    });
  };

  uploadImage = async(uri) => {
    const tenant = firebase.auth().currentUser.displayName;
    const fileType = uri.originFileObj.name.substr(uri.originFileObj.name.lastIndexOf('.') + 1);
    const mimeType = mime.lookup(fileType)
    this.setState({uploading: true})
    const metadata = {
      contentType: mimeType,
    }
    const name = uri.originFileObj.name.replace(/\s/g,'-')
    const ref = firebase.storage().ref().child(`tenants/${tenant}/${name}.${fileType}`)
    return ref.put(uri.originFileObj, metadata).then((snapshot) => {
      snapshot.ref.getDownloadURL().then((downloadURL) => {
        this.setState({uploading: false})
        return this.setState({ logo: downloadURL }, () => {
          this.updateCompanyDetails()
        })
      })
    })
  }

  renderAvatar = () => {
    const { uploading, companyData } = this.state
    
    const upload = (file) => {
      this.uploadImage(file)
    }

    const props = {
      name: 'file',
      action: 'upload',
      headers: {
        authorization: 'authorization-text',
      },
      onChange(info) {
        if (info.file.status !== 'uploading') {
          // console.log(info.file, info.fileList);
          upload(info.file)
        }
        if (info.file.status === 'done') {
          // console.log(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === 'error') {
          // console.log(`${info.file.name} file upload failed.`);
        }
      },
    };
    
    return (
      <div key="22">
        {uploading &&
          <Spin />
        }

        {!helper.isEmpty(companyData.logo) &&
          <img src={companyData.logo} width="300" alt="logo" />
        }
        {helper.isEmpty(companyData.logo) &&
          <Avatar icon="user" size={100}>{companyData.displayName}</Avatar>
        }
        <br/><br/>
        <Upload key="1" {...props}>
          <Button type="primary">
            <Icon type="upload" /> {i18n.t('chooseImage')}
          </Button>
        </Upload>
        <br/><br/>
      </div>
    )
  }

  renderForm = () => {
    const { companyData } = this.state
    const { getFieldDecorator } = this.props.form;

    return (
      <Form key="33" onSubmit={this.handleSubmit} className="profile-form">
        <Form.Item label={i18n.t('companyName')}>
          {getFieldDecorator('displayName', {
            rules: [{ required: true, message: 'Please input your email!' }],
            initialValue: companyData.displayName
          })(
            <Input
              placeholder={i18n.t('companyName')}
            />,
          )}
        </Form.Item>
        <Form.Item label={i18n.t('phone')}>
          {getFieldDecorator('phone', {
            rules: [{ required: true, message: 'Please input your phone number!' }],
            initialValue: companyData.phone
          })(
            <Input
              type="text"
              placeholder={i18n.t('phone')}
            />,
          )}
        </Form.Item>
        <Form.Item label={i18n.t('street')}>
          {getFieldDecorator('street', {
            rules: [{ required: true, message: 'Please input your street!' }],
            initialValue: companyData.street
          })(
            <Input
              type="text"
              placeholder={i18n.t('street')}
            />,
          )}
        </Form.Item>
        <Form.Item label={i18n.t('zip')}>
          {getFieldDecorator('zip', {
            rules: [{ required: true, message: 'Please input your zip!' }],
            initialValue: companyData.zip
          })(
            <Input
              type="text"
              placeholder={i18n.t('zip')}
            />,
          )}
        </Form.Item>
        <Form.Item label={i18n.t('city')}>
          {getFieldDecorator('city', {
            rules: [{ required: true, message: 'Please input your city!' }],
            initialValue: companyData.city
          })(
            <Input
              type="text"
              placeholder={i18n.t('city')}
            />,
          )}
        </Form.Item>
        <Form.Item label={i18n.t('ust')}>
          {getFieldDecorator('ust', {
            rules: [{ required: false, message: 'Please input your ust!' }],
            initialValue: companyData.ust
          })(
            <Input
              type="text"
              placeholder={i18n.t('ust')}
            />,
          )}
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" className="company-form-button">
            {i18n.t('save')}
          </Button>
        </Form.Item>
      </Form>
    )
  }

  render() {
    const { loading } = this.state

    if (loading) {
      return (<Spin/>)
    }

    return ([
      <PageHeader 
        key="11" 
        title={i18n.t('manageCompany')} 
      />,
      this.renderAvatar(),
      this.renderForm()
    ]);
  }
}

export default Form.create({ name: 'company' })(Company);