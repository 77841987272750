import React from 'react'
import { Form, Icon, Input, Button, Alert, Checkbox } from 'antd';
import firebase from '../constants/firebase';
import i18n from 'i18n-js';
import helper from '../utils/helper';

class Register extends React.Component {
  state = {
    tenant: '',
    error: ''
  }

  componentDidMount = () => {
    const tenant = firebase.auth().currentUser.displayName;
    if (!tenant) {
      return this.props.history.push('/choose-workspace')
    }
    this.setState({tenant: tenant})
  }

  SignUp = async (values) => {
    if (!values.dataProtection || !values.email || !values.password) {
      return false
    }

    const tenant = firebase.auth().currentUser.displayName;
    const { firstname, lastname, phone, email } = values
    const db = firebase.firestore()
    this.setState({fetching: true})

    try {
      firebase
        .auth()
        .createUserWithEmailAndPassword(values.email, values.password)
        .then(async (userData) => {
          const user = await firebase.auth().currentUser

          return db
            .collection(`tenants/${tenant}/users`)
            .doc(user.uid)
            .set({
              email,
              phone,
              tenant: tenant,
              firstname,
              lastname,
              isActive: false,
              createdAt: new Date()
            })
            .then(() => {
              this.setState({fetching: false})
              return helper.logOutUser(this.props.history);
            })
            .catch((error) => {
              // console.log(error)
              this.setState({fetching: false, error: error.toString()})
            })
        })
        .catch((error) => {
          let registerError = ""
          if (error.code = 'auth/email-already-in-use') {
            registerError = i18n.t('emailExists')
          } else if (error.code == 'auth/invalid-email') {
            registerError = i18n.t('invalidEmail')
          } else if (error.code == 'auth/weak-password') {
            registerError = i18n.t('passwordWeak')
          }
          this.setState({fetching: false, error: registerError})
          // console.log(error)
        })
    } catch (error) {
      this.setState({fetching: false, error: error.toString()})
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // console.log('Received values of form: ', values);
        this.SignUp(values)
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { error, fetching } = this.state

    return (
      <Form onSubmit={this.handleSubmit} className="register-form">
        <h3>{i18n.t('signupText')}</h3>
        <Form.Item>
          {getFieldDecorator('firstname', {
            rules: [{ required: true, message: 'Please input your firstname!' }],
          })(
            <Input
              placeholder={i18n.t('firstname')}
            />,
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('lastname', {
            rules: [{ required: true, message: 'Please input your lastname!' }],
          })(
            <Input
              placeholder={i18n.t('lastname')}
            />,
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('phone', {
            rules: [{ required: true, message: 'Please input your phone number!' }],
          })(
            <Input
              prefix={<Icon type="phone" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder={i18n.t('phone')}
            />,
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('email', {
            rules: [{ required: true, message: 'Please input your email!' }],
          })(
            <Input
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder={i18n.t('email')}
            />,
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('password', {
            rules: [{ required: true, message: 'Please input your Password!' }],
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder={i18n.t('password')}
            />,
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('dataProtection', {
            rules: [{ required: true, message: 'Please accept to Data Protection!' }],
          })(
            <Checkbox>{i18n.t('acceptDataProtection')}</Checkbox>,
          )}
          <a href="https://smartwerker.app/datenschutzerklaerung/" rel="noopener noreferrer" target="_blank" style={{paddingLeft: 10, marginTop: -15, display: 'block'}}>{i18n.t('readMore')}</a>
        </Form.Item>
        <br/>
        <Form.Item>
          <Button loading={fetching} type="primary" htmlType="submit" className="login-form-button" style={{width: '100%'}}>
            {i18n.t('send')}
          </Button>
          {/* <Link to="/login">{i18n.t('signin')}</Link> */}
        </Form.Item>
        {error && 
          <Alert message={error} type="error" />
        }
      </Form>
    );
  }
}

export default Form.create({ name: 'normal_register' })(Register);
