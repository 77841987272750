import React from 'react';
import { Tabs, PageHeader, Spin, Button, message, Icon } from 'antd';
import firebase from '../constants/firebase';
import Material from '../components/Project/Material';
import Tasks from '../components/Project/Tasks'
import Folder from '../components/Project/Folder'
import Informations from '../components/Project/Informations';
import i18n from 'i18n-js';
import Comments from '../components/Project/Comments';
import WorkingHoursModal from '../modal/WorkingHoursModal';
import moment from 'moment';
import Report from '../components/Project/Report';
const { TabPane } = Tabs;

export default class ProjectsDetail extends React.Component {
  state = {
    loading: true,
    activeTab: '1',
    runningTimer: []
  }

  componentDidMount = () => {
    const projectId = this.props.location.pathname.split('project/')[1]
    this.setState({projectId: projectId}, () => {
      this.loadProjectDetail()
      this.loadUserData()
      this.loadRunningTimer()
    })
    let params = (new URL(document.location)).searchParams
    let tab = params.get('tab')
    let folder = params.get('folder')
    if (tab) {
      this.setState({activeTab: tab})
    }
    if (folder) {
      this.setState({activeFolder: folder})
    }
  }

  // componentDidUpdate = (data) => {
  //   let params = (new URL(document.location)).searchParams
  //   let tab = params.get('tab')
  //   let folder = params.get('folder')
  //   if (folder !== this.state.activeFolder || tab !== this.state.activeTab) {
  //     if (tab) {
  //       this.setState({activeTab: tab})
  //     }
  //     if (folder) {
  //       this.setState({activeFolder: folder})
  //     }
  //   }
  // }

  loadRunningTimer = () => {
    const db = firebase.firestore()
    const tenant = firebase.auth().currentUser.displayName;
    const currentUserRef = db.collection(`tenants/${tenant}/users`).doc(firebase.auth().currentUser.uid)
    const currentProjectRef =  db.collection(`tenants/${tenant}/projects`).doc(this.state.projectId)
    
    return db.collection(`tenants/${tenant}/working-hours`)
      .where('timer', '<=', new Date())
      .where('project', '==', currentProjectRef)
      .where("user", '==', currentUserRef)
      .limit(1)
      .onSnapshot((querySnapshot) => {
        if (!querySnapshot.exists) {
          this.setState({runningTimer: []})
        }
        
        let workingHours = []
        querySnapshot.forEach((doc) => {
          let item = {
            id: doc.id,
          }
          workingHours.push(item)

          this.setState({
            runningTimer: workingHours,
          })
        })
      })
  }

  loadProjectDetail = () => {
    const db = firebase.firestore()
    const tenant = firebase.auth().currentUser.displayName;
    const { projectId } = this.state;

    if (projectId) {
      db.collection(`tenants/${tenant}/projects`)
        .doc(projectId)
        .onSnapshot((doc) => {
          let project = {
            id: doc.id,
            name: doc.data().name,
            client: doc.data().client,
            info: doc.data().info,
            createdAt: doc.data().createdAt,
            active: doc.data().active,
            notes: doc.data().notes || '',
            color: doc.data().color || '',
            containsMaterial: doc.data().containsMaterial || true,
            containsReport: doc.data().containsReport || true
          }
          project.client.get().then((snap) => {
            project.client = snap.data()
            project.clientId = snap.id
            this.setState({
              project: project,
              loading: false
            })
          })
        })
    }
  }

  loadUserData = () => {
    const db = firebase.firestore()
    const tenant = firebase.auth().currentUser.displayName;
    const currentUser = firebase.auth().currentUser.uid
    this.unsubscribeUserData = db.collection(`tenants/${tenant}/users`)
      .doc(currentUser)
      .onSnapshot((doc) => {
        const stars = doc.data().stars
        const isAdmin = doc.data().isAdmin || false
        if (stars) {
          const itemId = this.state.projectId
          const isStarred = stars.includes(itemId)
          return this.setState({
            stars: stars,
            starred: isStarred,
            isAdmin: isAdmin
          })
        }
        this.setState({
          stars: [],
          starred: false,
          isAdmin: isAdmin
        })
      })
  }

  updateData = () => {
    const { starred, stars, projectId } = this.state
    const tenant = firebase.auth().currentUser.displayName;
    const db = firebase.firestore()
    const currentUser = firebase.auth().currentUser.uid
    const itemId = projectId
    let notificationMessage = ''
    let newUserData = {}
    if (stars) {
      newUserData = {
        stars: (starred) ? firebase.firestore.FieldValue.arrayRemove(itemId) : firebase.firestore.FieldValue.arrayUnion(itemId)
      }
    } else {
      newUserData = {
        stars: [itemId]
      }
    }
    if (starred) {
      notificationMessage = i18n.t('projectNotStarred') 
    } else {
      notificationMessage = i18n.t('projectStarred')
    }
    message.info(notificationMessage);

    return db.collection(`tenants/${tenant}/users`).doc(currentUser).update(newUserData)
  }

  onTabClick = (key) => {
    this.setState({activeTab: key})
    this.props.history.push({
      search: `?tab=${key}`
    })
  }

  toogleModal = (val, edit, item) => {
    this.setState({
      modalVisible: val,
      edit: edit,
      item: item
    })
  }

  render() {
    const { loading, project, activeTab, edit, modalVisible, starred, runningTimer, isAdmin } = this.state

    if (loading) {
      return (
        <Spin />
      )
    }

    // console.log(runningTimer)

    const item = {
      projectId: project.id,
      date: moment()
    }

    return ([
      <PageHeader 
        key="11" 
        title={project.name || ''}
        subTitle={`${project.client.firstname} ${project.client.lastname}`}
        onBack={() => this.props.history.push('/')}
        extra={[
          <Button key="1" onClick={() => this.toogleModal(true, false, item)}>
            <Icon type="clock-circle" />
          </Button>,
          <Button key="2" onClick={() => this.updateData()}>
            {!starred ? <Icon type="star" /> : <Icon type="star" theme="filled" />}
          </Button>,
        ]}
      />,
      <Tabs key="22" defaultActiveKey="1" activeKey={activeTab} onTabClick={this.onTabClick}>
        <TabPane tab={i18n.t('tasks')} key="1">
          <Tasks 
            project={project.id}
            active={project.active}
          />
        </TabPane>
        <TabPane tab={i18n.t('projectChat')} key="2">
          <Comments
            project={project.id}
            active={project.active}
          />
        </TabPane>
        <TabPane tab={i18n.t('projectDocuments')} key="3">
          <Folder 
            project={project.id}
            active={project.active}
            activeFolder={this.state.activeFolder}
          />
        </TabPane>
        {project.containsMaterial &&
          <TabPane tab={i18n.t('material')} key="4">
            <Material 
              project={project.id}
              active={project.active}
            />
          </TabPane>
        }
        {(project.containsReport && isAdmin) &&
          <TabPane tab={i18n.t('reportHL')} key="5">
            <Report
              project={project.id}
              active={project.active}
              history={this.props.history}
            />
          </TabPane>
        }
        <TabPane tab={i18n.t('projectInfo')} key="6">
          <Informations
            project={project}
            active={project.active}
          />
        </TabPane>
      </Tabs>,
      modalVisible &&
        <WorkingHoursModal
          key={44}
          edit={edit}
          item={item}
          isAdmin={isAdmin}
          modalVisible={modalVisible}
          toogleModal={this.toogleModal}
        />
    ]);
  }
}