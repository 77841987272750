import React from 'react';
import { List, Avatar, Button, Menu, Row, Col, Icon, Dropdown } from 'antd';
import firebase from '../../constants/firebase';
import i18n from 'i18n-js';
import FolderModal from '../../modal/FolderModal';
import FolderDocuments from './FolderDocuments';

export default class Tasks extends React.Component {
  state = {
    loading: true,
    folder: [],
    folderId: 1,
    fixedFodler: [
      {
        id: 1,
        title: i18n.t('folderPlan'),
        icon: 'folder',
      },
      {
        id: 2,
        title: i18n.t('folderInProgress'),
        icon: 'folder',
      },
      {
        id: 3,
        title: i18n.t('folderFinal'),
        icon: 'folder',
      },
      {
        id: 5,
        title: i18n.t('folderDeliveryNote'),
        icon: 'folder',
      },
      {
        id: 4,
        title: i18n.t('folderReports'),
        icon: 'folder',
      },
      {
        id: 6,
        title: i18n.t('folderChat'),
        icon: 'folder',
      }
    ]
  }

  componentDidMount = () => {
    this.loadFolderAndFiles()
    this.loadDocumentsFromChat()
    if (this.props.activeFolder) {
      this.setState({folderId: this.props.activeFolder})
    }
  }

  loadDocumentsFromChat = () => {
    const { project } = this.props
    const tenant = firebase.auth().currentUser.displayName;
    const db = firebase.firestore()
    db.collection(`tenants/${tenant}/projects/${project}/comments`)
      .orderBy('createdAt')
      .onSnapshot((doc) => {
      let m = []
      doc.docs.forEach((document) => {
        if (document.data().image || document.data().video) {
          m.push({
            // createdAt: document.data().createdAt.toDate(),
            user: document.data().user,
            downloadURL: document.data().image,
            duration: document.data().duration,
            video: document.data().video,
            id: document.id
          })
        }
      })
      this.setState({chatDocuments: m, loading: false})
    })
  }

  loadFolderAndFiles = () => {
    const { project } = this.props
    const tenant = firebase.auth().currentUser.displayName;
    const db = firebase.firestore()
    db.collection(`tenants/${tenant}/projects/${project}/gallery`)
      .orderBy('createdAt')
      .onSnapshot((doc) => {
      let m = []
      doc.docs.forEach((doc) => {
        m.push({
          downloadURL: doc.data().downloadURL,
          createdAt: doc.data().createdAt,
          folderId: doc.data().folderId,
          type: doc.data().type,
          id: doc.id,
          name: doc.data().name
        })
      })
      this.setState({folder: m, loading: false})
    })
  }

  getTotalItems = (folderId) => {
    const { folder, chatDocuments } = this.state
    if (folderId === 6 && chatDocuments) {
      return chatDocuments.length
    } else {
      const amount = folder.filter(item => item.folderId === folderId)
      return amount.length
    }
  }

  toogleModal = (val, edit, item) => {
    if (edit) {
      this.setState({
        edit: edit,
        item: item,
        folderItem: item.name,
        modalVisible: val,
      })
    } else {
      this.setState({modalVisible: val, edit: edit, item: item})
    }
  }

  removeItem = (rowId) => {
    const db = firebase.firestore()
    const tenant = firebase.auth().currentUser.displayName
    const ref = db.collection(`tenants/${tenant}/projects/${this.props.project}/gallery`).doc(rowId)
    ref.delete().then(function() {
      // console.log("Document successfully deleted!")
    }).catch(function(error) {
      // console.error("Error removing document: ", error)
    })
  }

  renderCustomFolder = () => {
    const { folder, loading, folderId } = this.state
    const folderList = folder.filter(item => item.type === 'folder')

    if (!folderList.length) {
      return null
    }

    const menu = (item) => {
      return (
        <Menu>
          <Menu.Item key="1" onClick={() => this.toogleModal(true, true, item)}>{i18n.t('edit')}</Menu.Item>
          <Menu.Item key="2" onClick={() => this.removeItem(item.id)}>{i18n.t('delete')}</Menu.Item>
        </Menu>
      )
    }

    return (
      <List
        key="22"
        itemLayout="horizontal"
        dataSource={folderList}
        loading={loading}
        renderItem={item => (
          <List.Item
            actions={[
              <Dropdown overlay={menu(item)}>
                <Button type="link" className="folderMoreButton">
                  <Icon type="more" style={{fontSize: 20}} />
                </Button>
              </Dropdown>
          ]}
            className={(folderId === item.id) ? 'active' : ''}
          >
            <List.Item.Meta
              avatar={<Avatar size={20} icon="folder"> </Avatar>}
              title={<a href="#!" onClick={() => this.setState({folderId: item.id})}>{item.name}</a>}
              description={i18n.t('folderItemsAmount', {count: this.getTotalItems(item.id)})}
            />
          </List.Item>
        )}
      />
    )
  }

  render() {
    const { fixedFodler, loading, item, edit, modalVisible, folderId} = this.state
    const { project } = this.props

    return ([
      <Row key="22" className="folderWrapper">
        <Col span={6}>
          <List
            itemLayout="horizontal"
            dataSource={fixedFodler}
            loading={loading}
            renderItem={item => (
              <List.Item className={(folderId === item.id) ? 'active' : ''}>
                <List.Item.Meta
                  avatar={<Avatar size={20} icon={item.icon}> </Avatar>}
                  title={<a href="#!" onClick={() => this.setState({folderId: item.id})}>{item.title}</a>}
                  description={i18n.t('folderItemsAmount', {count: this.getTotalItems(item.id)})}
                />
              </List.Item>
            )}
          />
          <div>
            {this.renderCustomFolder()}
          </div>
          <Button type="primary" icon="folder-add" onClick={() => this.toogleModal(true)}>
            {i18n.t('addFolderScreenHL')}
          </Button>
        </Col>
        <Col span={18}>
          <FolderDocuments 
            folderId={folderId}
            projectId={project}
          />
        </Col>
      </Row>,
      modalVisible &&
        <FolderModal
          key="44"
          item={item}
          edit={edit}
          project={this.props.project}
          toogleModal={this.toogleModal}
          modalVisible={modalVisible}
          tenant={this.props.tenant}
        />
    ])
  }
}