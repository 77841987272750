import React from 'react';
import { Button, Form, Modal, Switch } from 'antd';
import firebase from '../constants/firebase';
import i18n from 'i18n-js';

class EmployeesModal extends React.Component {
  state = {
    loading: true
  }

  updateData = (values) => {
    const tenant = firebase.auth().currentUser.displayName;
    const { item } = this.props
    const db = firebase.firestore()
    let newUserData = {}

    if (values.isAdmin !== undefined) {
      newUserData.isAdmin = values.isAdmin
    }
    if (values.isActive !== undefined) {
      newUserData.isActive = values.isActive
    }
    return db.collection(`tenants/${tenant}/users`).doc(item.id).set(newUserData, {merge: true}).then(() => {
      this.props.toogleModal(false)
    })
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // console.log('Received values of form: ', values);
        this.updateData(values)
      }
    });
  };

  render = () => {
    const { saving } = this.state;
    const { item } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <Modal
        title={i18n.t('editEmployeeScreenHL')}
        visible={this.props.modalVisible}
        onOk={this.handleOk}
        onCancel={() => this.props.toogleModal(false)}
        footer={null}
      >
        <Form onSubmit={this.handleSubmit} className="material-form">
          <Form.Item label={i18n.t('isActive')}>
            {getFieldDecorator('isActive', {
              valuePropName: 'checked',
              initialValue: (item) ? item.isActive : false,
            })(
              <Switch />,
            )}
          </Form.Item>
          <Form.Item label={i18n.t('isAdmin')}>
            {getFieldDecorator('isAdmin', {
              valuePropName: 'checked',
              initialValue: (item) ? item.isAdmin : false,
            })(
              <Switch />,
            )}
          </Form.Item>

          <Form.Item>
            <Button type="primary" loading={saving} htmlType="submit" className="employees-form-button">
              {i18n.t('save')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}

export default Form.create({ name: 'employees_modal' })(EmployeesModal);