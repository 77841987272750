import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ConfigProvider } from 'antd';
import de from 'antd/es/locale/de_DE';
import en from 'antd/es/locale/en_GB';
import es from 'antd/es/locale/es_ES';
const userLang = navigator.language || navigator.userLanguage; 
let lang = de
switch (userLang) {
    case 'es':
        lang = es
        break;
    case 'en':
        lang = en
        break;
    default:
        lang = de
        break;
}
console.log("userLang", userLang)

ReactDOM.render(<ConfigProvider locale={lang}><App /></ConfigProvider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
