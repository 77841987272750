import * as firebase from 'firebase/app'
import 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'

export const firebaseConfig = {
  apiKey: "AIzaSyAPjPwaRJCqqw1SU5QzblKfST4IF1t9Ynw",
  authDomain: "handwerker-app-5d600.firebaseapp.com",
  databaseURL: "https://handwerker-app-5d600.firebaseio.com",
  projectId: "handwerker-app-5d600",
  storageBucket: "handwerker-app-5d600.appspot.com",
  messagingSenderId: "945489591556",
  appId: "1:945489591556:web:a1a49a22ffadf4e1"
  }
firebase.initializeApp(firebaseConfig);

export default firebase;