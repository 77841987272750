import React from 'react';
import { Spin } from 'antd';

export default class WebView extends React.Component {
  state = {
    loading: true
  }

  componentDidMount = () => {
    this.loadExternalContent(this.props)
  }

  UNSAFE_componentWillReceiveProps = (newProps) => {
    this.loadExternalContent(newProps)
  }

  loadExternalContent = (props) => {
    this.setState({ loading: true })
    const contentId = (props.location && props.location.query) ? props.location.query.contentId : 849
    fetch('https://smartwerker.app/wp-json/acf/v3/mobile_content')
    .then(response => response.json())
    .then(response => response.filter((item => item.id === contentId)))
    .then(htmlContent => this.setState({ loading: false, htmlContent: htmlContent[0].acf.content }))
  }

  render() {
    const { loading, htmlContent } = this.state

    if (loading) {
      return (<Spin/>)
    }

    return ([
      <div 
        key="22"
        dangerouslySetInnerHTML={{__html: htmlContent}} />
    ])
  }
}