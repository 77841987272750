import React from 'react';
import { Button, Form, Modal, Switch, Input } from 'antd';
import firebase, { firebaseConfig } from '../constants/firebase';
import i18n from 'i18n-js';

class AddEmployeesModal extends React.Component {
  state = {
    loading: true,
    emailLogin: false
  }

  addEmployeeWithPhone = (values) => {
    const tenant = firebase.auth().currentUser.displayName;
    const db = firebase.firestore()
    let newUserData = {
      email: values.email || '',
      firstname: values.firstname,
      lastname: values.lastname,
      phone: values.phone || '',
      isActive: true,
      isAdmin: values.isAdmin || false,
      tenant: tenant
    }

    try {
      return db.collection(`employees`)
        .doc(values.phone.replace(/\s/g, '').replace('+', ''))
        .set(newUserData)
        .then(() => {
          this.setState({saving: false})
          this.props.toogleModal(false)
        })
    } catch (error) {
      this.setState({saving: false})
      console.log(error)
    }
  }

  addEmployee = (values) => {
    const tenant = firebase.auth().currentUser.displayName;
    const db = firebase.firestore()
    const authApp = firebase.initializeApp(firebaseConfig, 'authApp')
    const detachedAuth = authApp.auth()
    return detachedAuth.createUserWithEmailAndPassword(values.email, values.password).then(async(data) => {
      const user = await authApp.auth().currentUser
      user.updateProfile({
        displayName: tenant,
      });
      let newUserData = {
        id: user.uid,
        email: values.email,
        firstname: values.firstname,
        lastname: values.lastname,
        phone: values.phone || '',
        isActive: true,
        isAdmin: values.isAdmin || false,
        tenant: tenant,
        createdAt: new Date()
      }

      try {
        return db.collection(`tenants/${tenant}/users`)
          .doc(user.uid)
          .set(newUserData)
          .then(() => {
            this.setState({saving: false})
            authApp.delete()
            this.props.toogleModal(false)
          })
      } catch (error) {
        this.setState({saving: false})
        console.log(error)
      }
      
    })
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // console.log('Received values of form: ', values);
        if (this.state.emailLogin) {
          this.addEmployee(values)
        } else {
          this.addEmployeeWithPhone(values)
        }
      }
    });
  };

  render = () => {
    const { saving } = this.state;
    const { item } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <Modal
        title={i18n.t('addEmployee')}
        visible={this.props.modalVisible}
        onOk={this.handleOk}
        onCancel={() => this.props.toogleModal(false)}
        footer={null}
      >
        <Form onSubmit={this.handleSubmit} className="employee-form">
          <Form.Item label={i18n.t('firstname')}>
            {getFieldDecorator('firstname', {
              valuePropName: 'firstname',
              rules: [{ required: true }],
            })(
              <Input
                type="text"
                placeholder={i18n.t('firstname')}
              />,
            )}
          </Form.Item>
          <Form.Item label={i18n.t('lastname')}>
            {getFieldDecorator('lastname', {
              valuePropName: 'lastname',
              rules: [{ required: true }],
            })(
              <Input
                type="text"
                placeholder={i18n.t('lastname')}
              />,
            )}
          </Form.Item>

          <Form.Item label={i18n.t('mobileNumber')}>
            {getFieldDecorator('phone', {
              valuePropName: 'phone',
              rules: [{ required: true }],
            })(
              <Input
                type="tel"
                placeholder={i18n.t('mobileNumber')}
              />,
            )}
          </Form.Item>

          <Form.Item label={i18n.t('loginMethod')}>
            {getFieldDecorator('loginMethod', {
              valuePropName: 'checked',
              initialValue: true,
            })(
              <Switch 
                checkedChildren={i18n.t('mobileNumber')}
                unCheckedChildren={i18n.t('email')} 
                onChange={() => this.setState({emailLogin: !this.state.emailLogin})}
              />,
            )}
          </Form.Item>

          {this.state.emailLogin &&
            <Form.Item label={i18n.t('email')}>
              {getFieldDecorator('email', {
                valuePropName: 'email',
                rules: [{ required: true }],
              })(
                <Input
                  placeholder={i18n.t('email')}
                />,
              )}
            </Form.Item>
          }
          {this.state.emailLogin &&
            <Form.Item label={i18n.t('password')}>
              {getFieldDecorator('password', {
                valuePropName: 'password',
                rules: [{ required: true }],
              })(
                <Input
                  type="password"
                  placeholder={i18n.t('password')}
                />,
              )}
            </Form.Item>
          }

          <Form.Item label={i18n.t('isAdmin')}>
            {getFieldDecorator('isAdmin', {
              valuePropName: 'checked',
              initialValue: (item) ? item.isAdmin : false,
            })(
              <Switch />,
            )}
          </Form.Item>

          <Form.Item>
            <Button type="primary" loading={saving} htmlType="submit" className="employees-form-button">
              {i18n.t('save')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}

export default Form.create({ name: 'add_employees_modal' })(AddEmployeesModal);