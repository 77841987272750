import React from 'react';
import { PageHeader, Spin, Collapse, Button, Checkbox, message, Statistic } from 'antd';
import firebase from '../../constants/firebase';
import i18n from 'i18n-js';
import moment from 'moment';
import { groupBy } from 'lodash'
import WorkingHoursModal from '../../modal/WorkingHoursModal';
const { Panel } = Collapse;

export default class Report extends React.Component {
  state = {
    loading: true,
    workingHours: [],
    material: [],
    signing: false,
    uploading: false,
    signatureImage: null,
    items: [],
    selectedItems: [],
    saving: false,
    reports: [],
    projectDetails: {}
  };

  componentDidMount = async () => {
    this.loadWorkingHours()
    this.loadMaterial()
    this.loadProjectDetail()
    this.loadCompanyDetails()
  }

  loadCompanyDetails = () => {
    const tenant = firebase.auth().currentUser.displayName;
    const db = firebase.firestore()

    db.collection(`tenants`)
      .doc(tenant)
      .onSnapshot((doc) => {
        let companyData = {
          id: doc.id,
          displayName: doc.data().displayName,
          logo: doc.data().logo || '',
          phone: doc.data().phone,
          street: doc.data().street,
          zip: doc.data().zip,
          city: doc.data().city
        }
        this.setState({
          companyData: companyData,
          loading: false
        })
      })
  }

  loadProjectDetail = () => {
    const db = firebase.firestore()
    const tenant = firebase.auth().currentUser.displayName;
    const { project } = this.props;

    if (project) {
      db.collection(`tenants/${tenant}/projects`)
        .doc(project)
        .onSnapshot((doc) => {
          let projectData = {
            name: doc.data().name,
            client: doc.data().client,
            planedTime: doc.data().planedTime || i18n.t('notDefined')
          }
          projectData.client.get().then((snap) => {
            projectData.client = snap.data()
            projectData.clientId = snap.id
            this.setState({
              projectDetails: projectData,
              loading: false
            })
          })
        })
    }
  }

  loadWorkingHours = () => {
    const db = firebase.firestore()
    const tenant = firebase.auth().currentUser.displayName;
    const { project } = this.props
    const projectRef = db.collection(`tenants/${tenant}/projects`).doc(project)
    let workingHours = []

    db.collection(`tenants/${tenant}/working-hours`)
      .where("project", "==", projectRef)
      .orderBy('date')
      .onSnapshot((docs) => {
        if (!docs.exists) {
          this.setState({loading: false})
        }

        workingHours = []
        docs.forEach((doc) => {
          let item = {
            id: doc.id,
            date: doc.data().date,
            project: doc.data().project,
            comment: doc.data().comment,
            timeSpent: doc.data().timeSpent || '0:00',
            user: doc.data().user,
            signature: doc.data().signature || '',
            type: doc.data().type
          }
          item.user.get().then((snap) => {
            item.user = snap.data()

            item.project.get().then((snap) => {
              item.project = snap.data()
              item.projectId = snap.id
  
              workingHours.push(item)

              this.setState({
                workingHours: workingHours,
                loading: false
              })
            })
          })
        })
      })
  }

  loadMaterial = () => {
    const db = firebase.firestore()
    const tenant = firebase.auth().currentUser.displayName;
    const { project } = this.props
    db.collection(`tenants/${tenant}/projects/${project}/material`)
      .where("done", "==", true)
      .orderBy('createdAt')
      .onSnapshot((doc) => {
      let m = []
      doc.docs.forEach((item) => {
        m.push({
          name: item.data().name,
          date: item.data().createdAt,
          id: item.id,
          quantity: item.data().quantity,
          signature: item.data().signature || '',
          done: item.data().done,
          type: 'material'
        })
      })
      this.setState({material: m, loading: false})
    })
  }

  toggleItemsForDay = (e, items, key) => {
    e.stopPropagation()
    const { selectedItems } = this.state
    const index = selectedItems.indexOf(key)
    let arr = this.state.items
    if (index > -1) {
      selectedItems.splice(index, 1)
      arr.splice(index, items.length)
    } else {
      selectedItems.push(key)
      items.forEach((el) => {
        arr.push(el)
      })
    }
    this.setState({items: arr})
  }

  getSumForDay = (data) => {
    let hours = 0
    let minutes = 0
    data.forEach((item) => {
      if (item.timeSpent) {
        hours += Number(item.timeSpent.split(':')[0])
        minutes += Number(item.timeSpent.split(':')[1])
      }
    })
    if (minutes >= 60) {
      hours = (hours + minutes/60).toFixed(0)
    }
    minutes = minutes%60
    if (minutes<10) {
      minutes = `0${minutes}`
    }
    return `${hours}:${minutes}`
  }

  getSumForMonthInHours = () => {
    const { workingHours } = this.state
    let hours = 0
    let minutes = 0
    workingHours.forEach((item) => {
      hours += Number(item.timeSpent.split(':')[0])
      minutes += Number(item.timeSpent.split(':')[1])
    })
    
    if (minutes >= 60) {
      hours = (hours + minutes/60).toFixed(0)
    }
    minutes = minutes%60
    hours = hours + (minutes / 60)
    return Number(hours).toFixed(1)
  }

  renderSignatureButton = (elements, key) => {
    const { active } = this.props
    const a = elements.filter(e => e.signature && e.signature.length && e.signature !== '')

    if (elements.length > a.length) {
      if (active === false) {
        return null
      }
      return (
        <a
          href="#!"
          onClick={(e) => this.toggleItemsForDay(e, elements, key)}
        >
          <Checkbox
            checked={this.state.selectedItems.indexOf(key) >= 0}
          >
          </Checkbox>
        </a>
      )
    } else {
      return (
        <span>{i18n.t('alreadySigned')}</span>
      )
    }
  }

  renderTimeList = (workingHours, material) => {
    const allItems = workingHours.concat(material)
    const grouped = groupBy(allItems, item => moment(item.date.toDate()).format('DD.MM.YYYY'))

    return (
      <div key="22" style={{paddingBottom: 15, marginBottom: 15, borderBottomStyle: 'solid' ,borderBottomWidth: 1, borderBottomColor: '#eee'}}>
        <Collapse>
          {Object.keys(grouped).map((e, key) => ([

            <Panel 
              header={`${e} - ${this.getSumForDay(grouped[e])} ${i18n.t('hours')}`} 
              key={key}
              extra={this.renderSignatureButton(grouped[e], key)}
            >
              {grouped[e].filter(e => e.timeSpent).length > 0 && [
                <h3 key="1">{i18n.t('spentTime')}</h3>,
                <table key="2" className="reportTable">
                  <thead>
                    <tr>
                      <td>{i18n.t('employee')}</td>
                      <td>{i18n.t('comment')}</td>
                      <td>{i18n.t('hours')}</td>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody>
                    {grouped[e].map((element, i) => {
                      if (element.timeSpent) {
                          return (
                            <tr key={i}>
                              <td>{element.user.firstname} {element.user.lastname}</td>
                              <td>{element.comment}</td>
                              <td>{element.timeSpent}</td>
                              <td><a key="list-edit" href="#!" onClick={() => this.toogleModal(true, true, element)}>{i18n.t('edit')}</a></td>
                            </tr>
                          )
                      }
                      return null
                      })}
                  </tbody>
                </table>
              ]}

              {grouped[e].filter(e => !e.timeSpent).length > 0 && [
                <h3 key="1">{i18n.t('material')}</h3>,
                <table key="2" className="reportTable">
                  <thead>
                    <tr>
                      <td>{i18n.t('material')}</td>
                      <td>{i18n.t('quantity')}</td>
                    </tr>
                  </thead>
                  <tbody>
                    {grouped[e].map((element, i) => {
                      if (!element.timeSpent) {
                          return (
                            <tr key={i}>
                              <td>{element.name}</td>
                              <td>x {element.quantity}</td>
                            </tr>
                          )
                      }
                      return null
                      })}
                  </tbody>
                </table>
              ]}
            </Panel>
    
          ]))}
        </Collapse>
      </div>
    )
  }

  generateReport = () => {
    const { items, projectDetails, companyData } = this.state
    const { project } = this.props;
    const db = firebase.firestore();
    const tenant = firebase.auth().currentUser.displayName;
    this.setState({saving: true})

    let workingHours = items.filter(item => item.type !== 'material')
    let material = items.filter(item => item.type === 'material')
    workingHours.map((obj)=> {return Object.assign({}, obj)});
    material.map((obj)=> {return Object.assign({}, obj)});
    let start = workingHours.sort((a, b) => {
      return new Date(a.date.toDate()) - new Date(b.date.toDate())
    });
    const end = start[start.length - 1].date.toDate()
    start = start[0].date.toDate()
    
    const element = {
      user: db.doc(`tenants/${tenant}/users/${firebase.auth().currentUser.uid}`),
      createdAt: new Date(),
      workingHours,
      material,
      projectDetails: projectDetails,
      companyData,
      mimeType: 'pdf',
      projectId: project,
      fileName: `${moment(start).format('DD.MM.YY')} - ${moment(end).format('DD.MM.YY')}`
    }
    return db.collection(`tenants/${tenant}/reports`).add(element)
    .then(() => {
      this.setState({saving: false})
      this.props.history.push(`/project/${project}?tab=3&folder=4`);
      message.info(i18n.t('companyDetailsUpdated'));
      this.setState({items: []})
    })
    .catch((error) => {
      message.error(i18n.t('oops'));
      console.error("Error updating document: ", error)
    })
  }

  toogleModal = (val, edit, item) => {
    this.setState({
      modalVisible: val,
      edit: edit,
      item: item,
      date: item ? item.date.toDate() : new Date()
    })
  }

  render() {
    const { loading, workingHours, material, items, saving, modalVisible, item, edit, date, projectDetails } = this.state

    if (loading) {
      return (<Spin />)
    }

    return ([
      <PageHeader 
        key="11" 
        title={i18n.t('reportHL')}
        extra={[
          <Button loading={saving} disabled={!items.length || items.length < 1} key="1" type="primary" icon="file-pdf" onClick={() => this.generateReport()}>
            {i18n.t('createReportButton')}
          </Button>,
        ]}
      />,
      <div key="111">
        <Statistic title={`${i18n.t('total')} ${i18n.t('hours')}`} valueStyle={ (this.getSumForMonthInHours() > projectDetails.planedTime) ? { color: '#ff0000' } : null} value={this.getSumForMonthInHours()} suffix={`/ ${projectDetails.planedTime}`} />
        <br/><br/>
      </div>,
      this.renderTimeList(workingHours, material),
      modalVisible &&
        <WorkingHoursModal
          key={44}
          edit={edit}
          item={item}
          date={date}
          modalVisible={modalVisible}
          toogleModal={this.toogleModal}
        />
    ])
  }
}