import React from 'react';
import { Modal, Spin, Icon } from 'antd';
import firebase from '../constants/firebase';

export default class UserInformationModal extends React.Component {
  state = {
    loading: true,
  }

  componentDidMount = () => {
    this.loadUserInfo()
  }

  loadUserInfo = () => {
    const db = firebase.firestore()
    const tenant = firebase.auth().currentUser.displayName;
    const { userId } = this.props
    db.collection(`tenants/${tenant}/users`)
      .doc(userId)
      .get()
      .then((doc) => {
        let userInfo = {
          phone: doc.data().phone,
          lastname: doc.data().lastname,
          firstname: doc.data().firstname,
          avatar: doc.data().avatar || '',
        }

        this.setState({
          userInfo: userInfo,
          loading: false
        })
    })
  }

  renderContent = () => {
    const { userInfo } = this.state;

    return (
      <div>
        <div style={{padding: 10, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
          <div style={{flexDirection: 'row'}}>
            <Icon type="phone" />&nbsp;
            <span>{userInfo.phone}</span>
          </div>
        </div>
      </div>
    )
  }

  render = () => {
    const { userInfo, loading } = this.state

    if (loading) {
      return (
        <Spin/>
      )
    }

    return (
      <Modal
        title={`${userInfo.firstname} ${userInfo.lastname}`}
        visible={true}
        onOk={this.handleOk}
        onCancel={() => this.props.toggleModal(false)}
        footer={null}
      >
        {this.renderContent()}
      </Modal>
    )
  }
}