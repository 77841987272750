import React from 'react';
import { PageHeader, List, Button, message, Popconfirm, } from 'antd';
import firebase from '../constants/firebase';
import EmployeesModal from '../modal/EmployeesModal';
import i18n from 'i18n-js';
import UserAvatar from '../components/UserAvatar';
import AddEmployeesModal from '../modal/AddEmployeesModal';

export default class Employees extends React.Component {
  state = {
    employees: [],
    loading: true,
    invitations: []
  }

  componentDidMount = () => {
    this.loadEmployees()
    this.loadInvitations()
  }

  loadEmployees = () => {
    const tenant = firebase.auth().currentUser.displayName;
    const db = firebase.firestore()

    db.collection(`tenants/${tenant}/users`)
      .onSnapshot((querySnapshot) => {
        if (!querySnapshot.exists) {
          this.setState({
            loading: false
          })
        }
        let employees = []
        querySnapshot.forEach((doc) => {
          let user = {
            id: doc.id,
            lastname: doc.data().lastname,
            firstname: doc.data().firstname,
            email: doc.data().email,
            isAdmin: doc.data().isAdmin,
            isActive: doc.data().isActive,
            avatar: doc.data().avatar,
            phone: doc.data().phone
          }
          employees.push(user)
          this.setState({
            employees: employees,
            loading: false
          })
        })
      })
  }

  loadInvitations = () => {
    const tenant = firebase.auth().currentUser.displayName;
    const db = firebase.firestore()

    db.collection(`employees`)
      .where('tenant', '==', tenant)
      .onSnapshot((querySnapshot) => {
        if (!querySnapshot.exists) {
          this.setState({
            loading: false,
            invitations: []
          })
        }
        let invitations = []
        querySnapshot.forEach((doc) => {
          let user = {
            id: doc.id,
            lastname: doc.data().lastname,
            firstname: doc.data().firstname,
            isAdmin: doc.data().isAdmin,
            isActive: doc.data().isActive,
            avatar: doc.data().avatar,
            phone: doc.data().phone
          }
          invitations.push(user)
          this.setState({
            invitations: invitations,
            loading: false
          })
        })
      })
  }

  toogleModal = (val, edit, item) => {
    this.setState({
      modalVisible: val,
      edit: edit,
      item: item
    })
  }

  toogleAddModal = (val) => {
    this.setState({
      addModalVisible: val,
    })
  }

  removeInvitation = (item) => {
    const db = firebase.firestore()

    db.collection(`employees`).doc(item.id).delete()
    .then(function() {
      message.info(i18n.t('hasBeenDeleted'));
    })
    .catch(function(error) {
      message.error(i18n.t('oops'));
      // console.error("Error removing document: ", error)
    })
  }

  render() {
    const { employees, loading, modalVisible, edit, item, addModalVisible, invitations } = this.state

    return ([
      <PageHeader 
        key="11" 
        title={i18n.t('manageEmployees')}
        extra={[
          <Button key="1" type="primary" icon="employee-add" onClick={() => this.toogleAddModal(true)}>
            {i18n.t('addEmployee')}
          </Button>,
        ]}
      />,
      <List
        key="22"
        itemLayout="horizontal"
        dataSource={employees}
        loading={loading}
        renderItem={item => (
          <List.Item
            actions={[
              item.id !== firebase.auth().currentUser.uid ? <a key="list-edit" href="#!" onClick={() => this.toogleModal(true, true, item)}>{i18n.t('edit')}</a> : null
            ]}
          >
            <List.Item.Meta
              avatar={
                <UserAvatar
                  size="30"
                  name={`${item.firstname} ${item.lastname}`}
                  src={item.avatar}
                />
               }
              title={`${item.email.length ? item.email : item.phone} ${item.id === firebase.auth().currentUser.uid ? `(${i18n.t('me')})` : ''}`}
              description={`${item.lastname}, ${item.firstname}`}
              style={!item.isActive ? {opacity: 0.5} : {opacity: 1}}
            />
          </List.Item>
        )}
      />,
      invitations.length > 0 &&
        <div key="221">
          <h3 style={{marginTop: 40}}>Ausstehenden Einladungen</h3>
          <List
            key="222"
            itemLayout="horizontal"
            dataSource={invitations}
            loading={loading}
            renderItem={item => (
              <List.Item
                actions={[
                  <Popconfirm
                    title={i18n.t('areYouSure')}
                    onConfirm={() => this.removeInvitation(item)}
                    okText={i18n.t('yes')}
                    cancelText={i18n.t('no')}
                  >
                    <a href="#!">{i18n.t('delete')}</a>
                  </Popconfirm>
                ]}
              >
                <List.Item.Meta
                  avatar={
                    <UserAvatar
                      size="30"
                      name={`${item.firstname} ${item.lastname}`}
                      src={item.avatar}
                    />
                  }
                  title={`${item.phone}`}
                  description={`${item.lastname}, ${item.firstname}`}
                  style={!item.isActive ? {opacity: 0.5} : {opacity: 1}}
                />
              </List.Item>
            )}
          />
        </div>
      ,
      modalVisible &&
        <EmployeesModal
          key="33"
          item={item}
          edit={edit}
          toogleModal={this.toogleModal}
          modalVisible={modalVisible}
        />,
      addModalVisible &&
        <AddEmployeesModal
          key="34"
          item={item}
          edit={edit}
          toogleModal={this.toogleAddModal}
          modalVisible={addModalVisible}
        />
    ])
  }
}