import React from 'react';
import { Button, Form, Modal, message, Alert, Checkbox } from 'antd';
import i18n from 'i18n-js';
import { injectStripe, CardElement } from 'react-stripe-elements'
import PropTypes from 'prop-types'
import axios from 'axios'
import payment_visa from '../payment_visa.png';
import payment_discover from '../payment_discover.png';
import payment_americanexpress from '../payment_americanexpress.png';
import payment_mastercard from '../payment_mastercard.png';
import powered_by_stripe from '../powered_by_stripe.png';

const endpoint = 'https://smartwerker-payment.cuisines.io'
// const endpoint = 'http://localhost:8000'

class PaymentModal extends React.Component {
  state = {
    loading: true,
    closable: true
  }

  updateClient = (values) => {
    const { token } = this.state
    const { plan, companyData, active, edit } = this.props

    if (edit) {
      axios.put(`${endpoint}/payment`, {
        email: companyData.user.email,
        name: `${companyData.user.firstname} ${companyData.user.lastname}`,
        phone: companyData.phone,
        address: {
          line1: companyData.street,
          postal_code: companyData.zip,
          city: companyData.city
        },
        preferred_locales: ['de-DE'],
        plan: plan.id,
        source: token,
        ust: companyData.ust,
        company: companyData.company,
        userAmount: active.length,
        workspace: companyData.id,
      })
      .then((res) => {
        console.log(`Thank you for your purchase!`, res)
        this.setState({saving: false})
        message.info(i18n.t('companyDetailsUpdated'));
        this.props.toogleModal(false)
      })
      .catch(error => {
        console.log(error.response.data)
        this.setState({saving: false})
        message.error(i18n.t('oops'));
      })
    } else {
      axios.post(`${endpoint}/payment`, {
        email: companyData.user.email,
        name: `${companyData.user.firstname} ${companyData.user.lastname}`,
        phone: companyData.phone,
        address: {
          line1: companyData.street,
          postal_code: companyData.zip,
          city: companyData.city
        },
        preferred_locales: ['de-DE'],
        plan: plan.id,
        source: token,
        ust: companyData.ust,
        company: companyData.company,
        userAmount: active.length,
        workspace: companyData.id,
      })
      .then((res) => {
        console.log(`Thank you for your purchase!`, res)
        this.setState({saving: false})
        message.info(i18n.t('companyDetailsUpdated'));
        this.props.toogleModal(false)
      })
      .catch(error => {
        console.log(error.response.data)
        this.setState({saving: false})
        message.error(i18n.t('oops'));
      })
    }

    
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // console.log('Received values of form: ', values);
        this.setState({saving: true, closable: false})
        this.updateClient(values)
      }
    })
  }

  handleCardChange = async (change) => {
    const { companyData } = this.props
    if (change.complete) {
      const { token } = await this.props.stripe.createToken({type: 'card', name: `${companyData.user.firstname} ${companyData.user.lastname}`})
      this.setState({token, cardComplete: change.complete, cardError: change.error})
    }
    this.setState({cardComplete: change.complete, cardError: change.error})
  }

  render = () => {
    const { saving, cardError } = this.state;
    const { edit } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <Modal
        title={i18n.t('payment')}
        visible={this.props.modalVisible}
        onOk={this.handleOk}
        onCancel={() => this.props.toogleModal(false)}
        footer={null}
        closable={this.state.closable}
        maskClosable={this.state.closable}
      >
        <Form onSubmit={this.handleSubmit} className="material-form">
          <Form.Item label={i18n.t('creditCard')}>
            {getFieldDecorator('creditCard', {
              rules: [{ required: true }],
            })(
              <CardElement
                style={{base: {fontSize: '18px'}}}
                onChange={this.handleCardChange}
              />
            )}
          </Form.Item>
          <div style={{marginTop: 5}}>
              <img src={payment_americanexpress} width="40" alt="smartwerker" />
              <img src={payment_discover} width="40" alt="smartwerker" />
              <img src={payment_mastercard} width="40" alt="smartwerker" />
              <img src={payment_visa} width="40" alt="smartwerker" />
              <div style={{marginTop: 5}}>
                <img src={powered_by_stripe} alt="smartwerker" />
              </div>
              {cardError &&
                <Alert message={cardError.message} type="error" />
              }
          </div>
          <br/>
          <br/>
          <Form.Item>
            {getFieldDecorator('remember', {
              valuePropName: 'checked',
              initialValue: true,
            })(<Checkbox><strong><small>Du berechtigst Smartwerker, jeden Monat automatisch Deine Zahlungsmethode zu belasten, bis Du Dein Abo kündigst. Du stimmst zu, dass Du sofortigen Zugriff auf Dein Smartwerker Abonnement erhältst und erkennst an, dass Erstattungen nur innerhalb von 14 Tagen möglich sind und nur unter der Voraussetzung, dass Du Smartwerker noch nicht genutzt hast. Die vollständigen Nutzungsbedingungen und Anweisungen zur Stornierung findest Du hier.</small></strong></Checkbox>)}
          </Form.Item>

          <Form.Item>
            <Button type="primary" loading={saving} htmlType="submit" className="clients-form-button">
              {edit &&
                i18n.t('updatePayment')
              }
              {!edit &&
                i18n.t('buy')
              }
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}

PaymentModal.propTypes = {
  stripe: PropTypes.shape({
    createToken: PropTypes.func.isRequired
  }).isRequired
}

export default Form.create({ name: 'clients_modal' })(injectStripe(PaymentModal));