import React from 'react'
import initials from 'initials'

// from https://flatuicolors.com/
const defaultColors = [
  '#2ecc71', // emerald
  '#3498db', // peter river
  '#8e44ad', // wisteria
  '#e67e22', // carrot
  '#e74c3c', // alizarin
  '#1abc9c', // turquoise
  '#2c3e50', // midnight blue
  '#34495e',
  '#9b59b6',
  '#2980b9',
  '#27ae60',
  '#d35400',
  '#c0392b',
  '#f39c12',
  '#f1c40f',
  '#16a085'
];

function sumChars(str) {
  let sum = 0;
  for (let i = 0; i < str.length; i++) {
    sum += str.charCodeAt(i);
  }

  return sum;
}

class UserAvatar extends React.PureComponent {
  render() {
    let {
      src,
      name,
      color,
      textColor = 'white',
      colors = defaultColors,
      fontDecrease,
      size,
      defaultName,
      radius = 0.5
    } = this.props;

    if (!fontDecrease) fontDecrease = 2.5;

    if (!name) throw new Error('Avatar requires a name');

    if(typeof size !== 'number') size = parseInt(size);

    let abbr = initials(name);
    if(!abbr) abbr = defaultName;

    if(isNaN(radius)) radius = 0.5

    const borderRadius = size * radius;

    const imageLocalStyle = {
      borderRadius
    };

    const innerStyle = {
      borderRadius,
      borderWidth: 1,
      borderColor: 'transparent',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex'
    };

    if (size) {
      imageLocalStyle.width = innerStyle.width = size;
      imageLocalStyle.height = innerStyle.height = size;
    }

    let inner;
    if (src) {

      inner = <img src={src} alt="" style={{width: innerStyle.width, height:innerStyle.height, borderRadius}} />

    } else {
      let background;
      if (color) {
        background = color;
      } else {
        // pick a deterministic color from the list
        let i = sumChars(name) % colors.length;
        background = colors[i];
      }

      innerStyle.backgroundColor = background;

      inner = <span style={{ fontSize: size / fontDecrease, color: textColor }}>{abbr}</span>
    }

    return (
      <div>
        <div style={innerStyle}>
          {inner}
        </div>
      </div>
    )
  }
}

export default UserAvatar;
