import React from 'react';
import { PageHeader } from 'antd';
import i18n from 'i18n-js';
import Chat from '../Chat/Chat';

export default class Comments extends React.Component {
  render() {
    return ([
      <PageHeader 
        key="11" 
        title={i18n.t('projectChat')} 
      />,
      <Chat
        key="22"
        chat={`projects/${this.props.project}/comments`}
        projectId={this.props.project}
      />
    ])
  }
}