import React from 'react';
import firebase from '../../constants/firebase';
import { Comment, Form, Button, List, Input, Spin, Upload, Modal, Icon } from 'antd';
import moment from 'moment';
import GoogleMapReact from 'google-map-react';
import { key } from '../../constants/google';
import mime from 'mime-types';
import UserInformationModal from '../../modal/UserInformationModal';
import UserAvatar from '../UserAvatar';
import 'emoji-mart/css/emoji-mart.css'
import { Picker } from 'emoji-mart'

const Editor = ({ onChange, onSubmit, isSending, value, addEmoji, toggleEmoji, displayEmoji, uploadProps }) => (
  <div>
    <Form.Item>
      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'relative'}}>
        <Input disabled={isSending} onPressEnter={onSubmit} onChange={onChange} value={value} />
        <Upload key="1" {...uploadProps} style={{marginLeft: 10, marginRight: 10}}>
          <Button shape="circle" icon="cloud-upload" />
        </Upload>
        {displayEmoji &&
          <Picker showPreview={false} darkMode={false} onSelect={addEmoji} style={{ position: 'absolute', bottom: '60px', right: '0' }} title="" />
        }
        <Button shape="circle" icon="smile" onClick={toggleEmoji} style={{marginRight: 10}} />
        <Button disabled={!value || isSending} shape="circle" icon="caret-right" htmlType="submit" loading={isSending} onClick={onSubmit} type="primary" />
      </div>
    </Form.Item>
  </div>
);

export default class Chat extends React.Component {
  state = {
    messages: [],
    userData: {},
    isSending: false,
    userModal: false,
    displayEmoji: false
  }

  componentDidMount = async () => {
    this.loadUserData()
    this.loadFirstMessages()
  }

  componentWillUnmount = () => {
    this.unsubscribeFirstMessages()
  }

  loadFirstMessages = () => {
    const tenant = firebase.auth().currentUser.displayName;
    const { chat } = this.props
    const db = firebase.firestore()
    this.unsubscribeFirstMessages = db.collection(`tenants/${tenant}/${chat}`)
      .orderBy('createdAt')
      .onSnapshot((doc) => {
      let m = []
      doc.docs.forEach((message) => {
        m.push({
          _id: Math.round(Math.random() * 1000000),
          text: message.data().text,
          createdAt: message.data().createdAt.toDate(),
          user: message.data().user,
          image: message.data().image,
          location: message.data().location,
          audio: message.data().audio,
          duration: message.data().duration,
          video: message.data().video,
          thumb_128: message.data().thumb_128,
          thumb_500: message.data().thumb_500,
          thumb_1024: message.data().thumb_1024,
        })
      })
      this.setState({messages: m})
      this.scrollToBottom();
    })
  }

  loadUserData = () => {
    const db = firebase.firestore()
    const tenant = firebase.auth().currentUser.displayName;
    const currentUser = firebase.auth().currentUser.uid
    let userData = {}
    db.collection(`tenants/${tenant}/users`)
      .doc(currentUser)
      .onSnapshot((doc) => {
        userData = {
          firstname: doc.data().firstname || "",
          lastname: doc.data().lastname || "",
          avatar: doc.data().avatar || "",
          allowNotifications: doc.data().allowNotifications || false,
        }
        this.setState({userData: userData})
      })
  }

  handleSubmit = (a, b, downloadURL, filename, mimeType, messageRef) => {
    const { value, userData } = this.state
    const db = firebase.firestore()
    const tenant = firebase.auth().currentUser.displayName;

    if (!value && !downloadURL) {
      return false
    }

    this.setState({
      isSending: true,
      displayEmoji: false
    });

    const message = {
      user: {
        avatar: userData.avatar,
        name: `${userData.firstname} ${userData.lastname}`,
        _id: firebase.auth().currentUser.uid
      },
      createdBy: db.doc(`tenants/${tenant}/users/${firebase.auth().currentUser.uid}`),
      createdAt: new Date(),
      _id: Math.round(Math.random() * 1000000),
    }

    if (downloadURL) {
      message.fileName = filename
      message.mimeType = mimeType
      if (mimeType.indexOf('image') >= 0) {
        message.image = downloadURL
      } else if (mimeType.indexOf('video') >= 0) {
        message.video = downloadURL
      }
    } else {
      message.text = value
    }
    this.onSend(message, messageRef)
    this.setState({
      value: '',
      isSending: false
    })
  }

  handleChange = e => {
    this.setState({
      value: e.target.value,
    });
  }

  scrollToBottom = () => {
    this.messagesEnd.scrollIntoView();
  }

  onSend = (message, messageRef) => {
    const { chat } = this.props
    const db = firebase.firestore()
    const tenant = firebase.auth().currentUser.displayName;
    if (messageRef) {
      messageRef.set(message)
    } else {
      return db.collection(`tenants/${tenant}/${chat}`).add(message)
    }
  }
  
  uploadImage = async(uri) => {
    this.setState({
      isSending: true,
    });
    this.scrollToBottom();
    try {
      const { chat, projectId } = this.props
      const tenant = firebase.auth().currentUser.displayName
      const db = firebase.firestore()
      const fileType = uri.originFileObj.name.substr(uri.originFileObj.name.lastIndexOf('.') + 1)
      const mimeType = mime.lookup(fileType)
      this.setState({uploading: true})
      const messageRef = db.collection(`tenants/${tenant}/${chat}`).doc()
      const metadata = {
        contentType: mimeType,
        customMetadata: {
          projectId: projectId,
          messageId: messageRef.id,
          tenant: tenant
        }
      }
      const name = uri.originFileObj.name.replace(/\s/g,'-')
      const ref = firebase.storage().ref().child(`tenants/${tenant}/${chat}/${name}.${fileType}`)
      return ref.put(uri.originFileObj, metadata).then((snapshot) => {
        snapshot.ref.getDownloadURL().then((downloadURL) => {
          this.setState({uploading: false})
          return this.handleSubmit('', '', downloadURL, name, mimeType, messageRef)
        })
      })
    } catch (e) {
      console.log(e)
    }
  }

  toggleUserModal = (val, item) => {
    this.setState({
      userModal: val,
      selectedUserId: (item) ? item._id : ''
    })
  }

  renderCommentList = (messages) => (
    <List
      dataSource={messages}
      itemLayout="horizontal"
      renderItem={item => (
        <List.Item
          key={item._id}
          style={{borderBottomWidth: 0}}
        >
          <List.Item.Meta
            avatar={
              <UserAvatar
                size="30"
                name={`${item.user.name}`}
                src={item.user.avatar}
                onClick={() => this.toggleUserModal(true, item.user)}
              />
            }
            title={<a href="#!" onClick={() => this.toggleUserModal(true, item.user)}>{item.user.name}</a>}
            description={moment(item.createdAt).format('DD.MM.YYYY HH:mm')}
          />
          {this.renderMessage(item)}
        </List.Item>
      )}
    />
  )
  
  renderMessage = (message) => {
    return (
      <Comment
        content={this.renderContent(message)}
      />
    )
  }

  renderContent = (content) => {
    if (content.location) {
      return (
        <div style={{ height: '250px', width: '250px', position: 'relative' }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: key }}
            defaultCenter={{
              lat: content.location.latitude,
              lng: content.location.longitude
            }}
            defaultZoom={15}
          >
            <Icon theme="filled" type="pushpin" style={{fontSize: 55, color: 'red', position: 'absolute', left: '-25px', top: '-25px'}} />
          </GoogleMapReact>
        </div>
      )
    } else if (content.image) {
      return (
        <div>
          <img src={content.thumb_500 ? content.thumb_500 : content.image} onClick={() => this.openModal(content.thumb_1024 ? content.thumb_1024 : content.image)} width="250" alt="" />
        </div>
      )
    } else if (content.audio) {
      return (
        <div>
          <audio controls>
            <source src={content.audio} type="audio/mp3" />
            Your browser does not support the audio element.
          </audio>
        </div>
      )
    } else if (content.video) {
      return (
        <div>
          <video controls>
            <source src={content.video} type="video/mp4" />
            Your browser does not support the audio element.
          </video>
        </div>
      )
    } else {
      return (content.text)
    }
  }

  openModal = (doc) => {
    this.setState({modalVisible: true, modalDoc: doc})
  }

  closeModal = () => {
    this.setState({modalVisible: false})
  }

  addEmoji = (emoji) => {
    this.setState({value: (this.state.value || '') + ' ' + emoji.native})
  }

  toggleEmoji = () => {
    this.setState({displayEmoji: !this.state.displayEmoji})
  }
  
  render() {
    const userId = firebase.auth() && firebase.auth().currentUser ? firebase.auth().currentUser.uid : ''
    const { messages, userData, isSending, value, modalVisible, modalDoc, selectedUserId, userModal, displayEmoji } = this.state

    if (!userId) {
      return (<Spin />)
    }

    const upload = (file) => {
      this.uploadImage(file)
    }
    
    const uploadProps = {
      name: 'file',
      action: 'upload',
      headers: {
        authorization: 'authorization-text',
      },
      onChange(info) {
        if (info.file.status !== 'uploading') {
          // console.log(info.file, info.fileList);
          upload(info.file)
        }
        if (info.file.status === 'done') {
          // console.log(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === 'error') {
          // console.log(`${info.file.name} file upload failed.`);
        }
      },
    };

    return (
      <div>
        <div className="chat_wrapper">
          {messages.length > 0 && this.renderCommentList(messages)}
          {isSending && <Spin />}
          <div 
            style={{ float:"left", clear: "both" }}
            ref={(el) => { this.messagesEnd = el; }}>
          </div>
        </div>
        <Comment
          avatar={
            <UserAvatar
              size="30"
              name={`${userData.firstname} ${userData.lastname}`}
              src={userData.avatar}
            />
          }
          content={
            <Editor
              onSubmit={this.handleSubmit}
              onChange={this.handleChange}
              submitting={isSending}
              value={value}
              addEmoji={this.addEmoji}
              toggleEmoji={this.toggleEmoji}
              displayEmoji={displayEmoji}
              uploadProps={uploadProps}
            />
          }
        />
        <Modal key="33" visible={modalVisible} footer={null} onCancel={this.closeModal}>
          <img alt="" style={{ width: '100%' }} src={modalDoc} />
        </Modal>
        {userModal &&
          <UserInformationModal 
            userId={selectedUserId}
            toggleModal={this.toggleUserModal}
          />
        }
      </div>
    );
  }
}